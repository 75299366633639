export const setAuth = (token, userData) => {
  localStorage.setItem("authToken", token);
  localStorage.setItem("userData", JSON.stringify(userData));
};

export const getAuth = () => {
  const token = localStorage.getItem("authToken");
  const userData = JSON.parse(localStorage.getItem("userData"));
  return { authToken: token, userData };
};

export const clearAuth = () => {
  localStorage.removeItem("authToken");
  localStorage.removeItem("userData");
};

export const isAuthenticated = () => {
  return !!localStorage.getItem("authToken");
};

export const isOnboarded = () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  return userData?.is_onboarded || false;
};

export const logout = (navigate) => {
  clearAuth();
  localStorage.clear();
  navigate("/signin");
};
