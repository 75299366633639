import React from "react";
import { Layout, Space, Button, Tooltip, Divider, theme } from "antd";
import { SwapOutlined, MoreOutlined } from "@ant-design/icons";
import { Chat, Quiz } from "../../book";
import { AvatarWithText } from "../../../components";
import { useNavigate } from "react-router-dom";

const { Content } = Layout;
const { useToken } = theme;

function ContentBox({ bookData, showChat }) {
  const { token } = useToken();
  const navigate = useNavigate();

  return (
    <Layout
      style={{
        marginLeft: "12px",
        borderRadius: "16px",
        height: "85vh",
        display: "flex",
        flexDirection: "column",
        backgroundColor: token.colorWhite,
      }}
    >
      <Content style={{ flex: 1, overflow: "hidden" }}>
        {showChat ? <Chat bookData={bookData} /> : <Quiz bookData={bookData} />}
      </Content>
    </Layout>
  );
}

export default ContentBox;
