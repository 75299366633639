import React from "react";
import { List, Typography, theme } from "antd";
import { RightOutlined, BookOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const { Text } = Typography;
const { useToken } = theme;

function SearchResults({ results, onSelect }) {
  const { token } = useToken();
  const navigate = useNavigate();

  const handleItemClick = (path) => {
    navigate(path);
    onSelect();
  };

  const renderIcon = (Icon) => {
    return Icon ? (
      <Icon style={{ fontSize: "20px", color: token.colorPrimary }} />
    ) : null;
  };

  return (
    <div
      style={{
        position: "absolute",
        top: "100%",
        left: 0,
        right: 0,
        backgroundColor: token.colorWhite,
        borderRadius: "8px",
        boxShadow: token.boxShadowSecondary,
        zIndex: 1000,
        maxHeight: "400px",
        overflowY: "auto",
        marginTop: "8px",
      }}
    >
      {/* Pages Section */}
      {results.pages.length > 0 && (
        <List
          itemLayout="horizontal"
          dataSource={results.pages}
          renderItem={(item) => (
            <List.Item
              onClick={() => handleItemClick(item.path)}
              style={{
                padding: "12px 16px",
                cursor: "pointer",
                transition: "background-color 0.3s",
                "&:hover": {
                  backgroundColor: token.colorBgTextHover,
                },
              }}
            >
              <List.Item.Meta
                avatar={renderIcon(item.icon)}
                title={item.title}
                description={item.subtitle}
                style={{ fontFamily: "Gilroy-Medium" }}
              />
              <RightOutlined style={{ color: token.colorTextSecondary }} />
            </List.Item>
          )}
        />
      )}

      {/* Books Section */}
      {results.books.length > 0 && (
        <>
          {results.pages.length > 0 && (
            <div style={{ borderTop: `1px solid ${token.colorBorder}` }} />
          )}
          <List
            itemLayout="horizontal"
            dataSource={results.books}
            renderItem={(book) => (
              <List.Item
                onClick={() => handleItemClick(`/book/${book.id}`)}
                style={{
                  padding: "12px 16px",
                  cursor: "pointer",
                  transition: "background-color 0.3s",
                  "&:hover": {
                    backgroundColor: token.colorBgTextHover,
                  },
                }}
              >
                <List.Item.Meta
                  avatar={renderIcon(BookOutlined)}
                  title={book.title}
                  description={`${book.subject} Book`}
                />
                <RightOutlined style={{ color: token.colorTextSecondary }} />
              </List.Item>
            )}
          />
        </>
      )}
    </div>
  );
}

export default SearchResults;
