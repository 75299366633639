const TakeATestDrawerIcon = ({ color }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6 12.75H12" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6 9.75H9" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.75 1.875V2.25C9.75 4.37132 9.75 5.43198 10.409 6.09099C11.0681 6.75 12.1287 6.75 14.25 6.75H14.625M15 7.99268V10.5C15 13.3284 15 14.7427 14.1213 15.6213C13.2427 16.5 11.8284 16.5 9 16.5C6.17157 16.5 4.75736 16.5 3.87868 15.6213C3 14.7427 3 13.3284 3 10.5V7.09188C3 4.65812 3 3.44123 3.66455 2.617C3.79881 2.45048 3.95048 2.29881 4.117 2.16455C4.94123 1.5 6.15811 1.5 8.59185 1.5C9.12105 1.5 9.38558 1.5 9.6279 1.58551C9.6783 1.60329 9.72765 1.62375 9.77588 1.64681C10.0077 1.75766 10.1947 1.94475 10.5689 2.31891L14.1213 5.87132C14.5549 6.30487 14.7716 6.52164 14.8858 6.79729C15 7.07295 15 7.37951 15 7.99268Z" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  );
};

export default TakeATestDrawerIcon;
