import React from "react";
import { Modal, Typography, Space, Button, Flex, theme } from "antd";
import { CloseOutlined } from "@ant-design/icons";

const { Text } = Typography;
const { useToken } = theme;

const ConfirmModal = ({
  visible,
  onCancel,
  title,
  subtitle,
  primaryButtonText,
  secondaryButtonText,
  onPrimaryButton,
  onSecondaryButton,
}) => {
  const { token } = useToken();

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footer={null}
      width={"40%"}
      centered
      closable={false}
    >
      <Flex vertical gap={24}>
        {/* Header */}
        <Flex justify="space-between" align="start">
          <Space direction="vertical" size={8}>
            <Text strong style={{ fontSize: "14px", fontWeight: 500 }}>
              {title}
            </Text>
            <Text style={{ fontSize: "13px", color: token.colorGrey }}>
              {subtitle}
            </Text>
          </Space>
          <CloseOutlined onClick={onCancel} style={{ cursor: "pointer" }} />
        </Flex>

        {/* Buttons */}
        <Flex gap={16}>
          <Button
            style={{
              flex: 1,
              backgroundColor: token.colorAccent,
              color: token.colorPrimary,
              border: "none",
            }}
            size="large"
            onClick={onSecondaryButton}
          >
            {secondaryButtonText}
          </Button>
          <Button
            type="primary"
            style={{
              flex: 1,
              backgroundColor: token.colorPrimary,
              color: token.colorWhite,
            }}
            size="large"
            onClick={onPrimaryButton}
          >
            {primaryButtonText}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default ConfirmModal;
