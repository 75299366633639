export const BASE_URL = "https://2t8dyqepfm.us-east-1.awsapprunner.com/api/";
// export const BASE_URL = "http://localhost:8000/api/";

// User
export const LOGIN_URL = `${BASE_URL}user/login/`;
export const REGISTER_URL = `${BASE_URL}user/register/`;
export const REQUEST_OTP_URL = `${BASE_URL}user/request-otp/`;
export const VERIFY_OTP_URL = `${BASE_URL}user/verify-otp/`;
export const ONBOARDING_URL = `${BASE_URL}user/onboarding/`;
export const PROFILE_URL = `${BASE_URL}user/profile/`;

// User Settings
export const ACADEMIC_SETTINGS_URL = `${BASE_URL}user/settings/academic/`;
export const CHANGE_PASSWORD_URL = `${BASE_URL}user/settings/change-password/`;
export const DELETE_ACCOUNT_URL = `${BASE_URL}user/settings/delete-account/`;

// Wordcloud
export const WORDCLOUD_URL = `${BASE_URL}wordcloud/library-objects/`;

// Library
export const DASHBOARD_URL = `${BASE_URL}library/dashboard/`;

// Chatbot
export const CHAT_URL = `${BASE_URL}chatbot/chat/`;

// Quiz
export const GENERATE_QUIZ_URL = `${BASE_URL}quiz/generate-quiz/`;
export const EVALUATE_QUIZ_URL = `${BASE_URL}quiz/evaluate-quiz/`;
export const RECENT_RESULTS_URL = `${BASE_URL}quiz/dashboard/recent-results/`;
export const STATS_URL = `${BASE_URL}quiz/dashboard/stats/`;
export const CURRENT_PROGRESS_URL = `${BASE_URL}quiz/dashboard/current-progress/`;
export const PROGRESS_HISTORY_URL = `${BASE_URL}quiz/dashboard/progress-history/`;
