import React, { useState, useEffect } from "react";
import { Flex } from "antd";
import { ScoreCard } from "../../components";
import { getStats } from "../../services";
import { getAuth } from "../../utils";

const ScoreStatsCards = () => {
  const [statsData, setStatsData] = useState(null);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const { authToken } = getAuth();
        const data = await getStats(authToken);
        setStatsData(data);
      } catch (error) {
        console.error("Error fetching stats:", error);
      }
    };
    fetchStats();
  }, []);

  if (!statsData) return null;

  return (
    <Flex vertical style={{ width: "100%" }}>
      <ScoreCard
        title="Highest Score"
        score={statsData.highest_score}
        subject="Physics"
        date="2025-11-26"
      />
      <ScoreCard
        title="Lowest Score"
        score={statsData.lowest_score}
        subject="Mathematics"
        date="2025-11-25"
      />
    </Flex>
  );
};

export default ScoreStatsCards;
