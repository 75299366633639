import React, { useEffect, useState } from "react";
import { Collapse, Checkbox, theme } from "antd";
import styled from "styled-components";
import { useSelectedChapters } from "../../../contexts";
import { useNavigate, useParams } from "react-router-dom";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { ASSETS } from "../../../constants";

const { Panel } = Collapse;
const { useToken } = theme;

const CustomCollapse = styled(Collapse)`
  background-color: ${({ theme }) => theme.token.colorAccent};
  margin: 0px;
  border: none !important;

  .ant-collapse-item {
    border-bottom: none !important;
    margin-bottom: 4px;
  }

  .ant-collapse-header {
    padding: 8px 16px !important;
    align-items: center !important;
  }

  .ant-collapse-content {
    border-top: none !important;
  }

  .ant-collapse-content-box {
    padding: 0 16px 8px 48px !important;
  }

  .ant-collapse-expand-icon {
    color: #ff6c42 !important;
  }
`;

const BookCheckbox = styled(Checkbox)`
  .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.token.colorWhite};
    border-color: ${({ theme }) => theme.token.colorPrimary};
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.token.colorPrimary};
    border-color: ${({ theme }) => theme.token.colorPrimary};
  }
`;

const ChapterCheckbox = styled(Checkbox)`
  .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.token.colorWhite};
    border-color: ${({ theme }) => theme.token.logoBlue};
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.token.logoBlue};
    border-color: ${({ theme }) => theme.token.logoBlue};
  }

  &:hover .ant-checkbox-checked .ant-checkbox-inner {
    border-color: ${({ theme }) => `${theme.token.logoBlue}CC`} !important;
    background-color: ${({ theme }) => `${theme.token.logoBlue}CC`} !important;
  }

  &:hover .ant-checkbox-inner {
    border-color: ${({ theme }) => `${theme.token.logoBlue}CC`} !important;
  }
`;

const ChapterItem = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0px !important;
  padding: 10px !important;
  border-bottom: 1px solid #f5f7fa;
  gap: 8px;
`;

function BookInformation({ books, selectedBook, onBookSelect, collapsed }) {
  const { selectedChapters, setSelectedChapters } = useSelectedChapters();
  const navigate = useNavigate();
  const { bookId, chapterIds } = useParams();
  const { token } = useToken();
  const [activeKey, setActiveKey] = useState(null);

  useEffect(() => {
    if (chapterIds) {
      const selectedIds = chapterIds.split("&&").map((id) => parseInt(id, 10));
      setSelectedChapters(selectedIds);
    } else {
      setSelectedChapters([]);
    }
  }, [chapterIds, setSelectedChapters]);

  if (collapsed) return null;

  const handleBookSelect = (book) => {
    const currentPath = window.location.pathname;
    const baseRoute = currentPath.startsWith("/test") ? "test" : "book";

    if (selectedBook?.id === book.id) {
      // Unselecting current book
      onBookSelect(null);
      setSelectedChapters([]);
      setActiveKey(null); // Close all panels
      navigate(`/${baseRoute}`);
    } else {
      // Selecting new book
      onBookSelect(book);
      setActiveKey(book.id.toString()); // Open the selected book's panel
      navigate(`/${baseRoute}/${book.id}`);
    }
  };

  const handleChapterSelect = (e, chapterId, book) => {
    e.stopPropagation();
    const currentPath = window.location.pathname;
    const baseRoute = currentPath.startsWith("/test") ? "test" : "book";

    let newSelectedChapters;
    if (selectedChapters.includes(chapterId)) {
      newSelectedChapters = selectedChapters.filter((id) => id !== chapterId);
    } else {
      newSelectedChapters = [...selectedChapters, chapterId];
    }

    setSelectedChapters(newSelectedChapters);

    if (!selectedBook) {
      onBookSelect(book);
      navigate(`/${baseRoute}/${book.id}/${chapterId}`);
    } else {
      const chapterParam =
        newSelectedChapters.length > 0
          ? `/${newSelectedChapters.join("&&")}`
          : "";
      navigate(`/${baseRoute}/${selectedBook.id}${chapterParam}`);
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#FFFFFF",
        borderRadius: "8px",
        maxHeight: "100%",
        overflow: "hidden",
      }}
    >
      <CustomCollapse
        activeKey={activeKey}
        defaultActiveKey={books?.[0]?.id?.toString()}
        onChange={(key) => setActiveKey(key)} // Update active key on user action
        expandIconPosition="end"
        expandIcon={({ isActive }) =>
          isActive ? (
            <UpOutlined
              style={{
                fontSize: "12px",
                strokeWidth: 70,
                color: token.colorBlack,
              }}
            />
          ) : (
            <DownOutlined
              style={{
                fontSize: "12px",
                strokeWidth: 70,
                color: token.colorBlack,
              }}
            />
          )
        }
      >
        {books.map((book) => (
          <Panel
            key={book.id}
            header={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "12px",
                  padding: "8px 0",
                }}
              >
                <div onClick={(e) => e.stopPropagation()}>
                  <BookCheckbox
                    checked={selectedBook?.id === book.id}
                    onChange={() => handleBookSelect(book)}
                  />
                </div>
                <img
                  src={ASSETS.bookIcon}
                  alt="Book Icon"
                  style={{
                    height: "16px",
                    width: "auto",
                  }}
                />
                <span
                  style={{
                    color: token.colorBlack,
                    fontFamily: "Gilroy-Medium",
                    fontSize: "15px",
                    fontWeight: "600",
                    lineHeight: "24px",
                  }}
                >
                  {book.title}
                </span>
              </div>
            }
          >
            {book.chapters.map((chapter) => (
              <ChapterItem key={chapter.id}>
                <ChapterCheckbox
                  checked={selectedChapters.includes(chapter.id)}
                  onChange={(e) => handleChapterSelect(e, chapter.id, book)}
                  style={{ marginRight: "12px" }}
                />
                <img
                  src={ASSETS.chapterIcon}
                  alt="Chapter Icon"
                  style={{
                    height: "16px",
                    width: "auto",
                  }}
                />
                <span
                  style={{
                    color: token.logoBlue,
                    fontFamily: "Gilroy-Regular",
                    fontSize: "14px",
                  }}
                >
                  {chapter.title}
                </span>
              </ChapterItem>
            ))}
          </Panel>
        ))}
      </CustomCollapse>
    </div>
  );
}

export default BookInformation;
