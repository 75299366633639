const ReportsDrawerIcon = ({ color }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.875 13.125V10.875M8.625 13.125V6.375M12.375 13.125V10.125" stroke={color} stroke-linecap="round"/>
<path d="M16.125 4.125C16.125 5.36764 15.1177 6.375 13.875 6.375C12.6323 6.375 11.625 5.36764 11.625 4.125C11.625 2.88236 12.6323 1.875 13.875 1.875C15.1177 1.875 16.125 2.88236 16.125 4.125Z" stroke={color}/>
<path d="M16.1216 8.25C16.1216 8.25 16.125 8.50463 16.125 9C16.125 12.3588 16.125 14.0381 15.0816 15.0816C14.0381 16.125 12.3587 16.125 9 16.125C5.64124 16.125 3.96187 16.125 2.91843 15.0816C1.875 14.0381 1.875 12.3588 1.875 9C1.875 5.64127 1.875 3.96189 2.91843 2.91845C3.96187 1.87502 5.64124 1.87502 9 1.87502L9.75 1.875" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  );
};

export default ReportsDrawerIcon;
