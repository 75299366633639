import React from "react";
import { Button, Typography, Row, Col, Flex } from "antd";
import styled from "styled-components";
import { ASSETS } from "../../constants";
import { useNavigate } from "react-router-dom";

const { Title, Text } = Typography;

const TextColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px; /* Gap between major sections */
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px; /* Gap between TitleText and Text */
`;


const GraphicColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%; /* Ensures the image does not exceed the screen width */
    height: auto; /* Maintains aspect ratio */
  }

  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
`;

const StyledButton = styled(Button)`
  width: 161px;
  height: 43px;
  padding: 16px 20px;
  font-size: 16px;
  font-weight: 400;
  font-family: "Gilroy-SemiBold";
  border-radius: 8px;
  border: none;

  @media (max-width: 768px) {
    margin: 0 auto;
  }
`;

const ListContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ItemContainer = styled(Flex)`
  gap: 14px;
  align-items: flex-start;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Avatar = styled.img`
  height: 11px;
  width: auto;
  padding-top: 10px;
`;

const TitleText = styled(Title)`
  position: relative;
  display: inline-block;
  margin: 0 !important;

  &::before {
    content: "";
    position: absolute;
    top: -25px;
    left: -40px;
    width: 34px;
    height: 34px;
    background-image: url("images/shine-three-lines.svg");
    rotate: 90deg;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 1;
  }
  
  @media (max-width: 768px) {
    &::before {
      left: -10px;
      top: -35px;
    }
`;

const StyledRow = styled(Row)`
    @media (max-width: 768px) {
    flex-direction: column-reverse; /* GraphicColumn appears above TextColumn */
    align-items: center; /* Centers the columns horizontally */
    justify-content: center; /* Centers the columns vertically */
  }
`;

const TalkSection = () => {
  const navigate = useNavigate();
  return (
    <StyledRow gutter={24}>
      <Col xs={24} sm={8} lg={8} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <TextColumn>
            <TitleContainer>
                <TitleText style={{ fontSize: "30px", fontWeight: 400, fontFamily: "Gilroy-Bold" }}>
                Talk to your textbooks
                </TitleText>
                <Text style={{ fontSize: "16px", fontWeight: 400, fontFamily: "Gilroy-Regular" }}>
                Dive deeper into learning with precise AI responses, engaging discussions, and clear explanations of concepts enhanced by examples and diagrams.
                </Text>
            </TitleContainer>
          <ListContainer>
            <ItemContainer>
              <Avatar src={ASSETS.practiceTick} alt="List Item" />
              <TextContainer>
                <Text style={{ fontSize: `16px`, fontFamily: 'Gilroy-Light', fontWeight: '400px' }}>
                Get precise responses to your questions with GradeMakers advanced AI technology.
                </Text>
              </TextContainer>
            </ItemContainer>
            <ItemContainer>
              <Avatar src={ASSETS.practiceTick} alt="List Item" />
              <TextContainer>
                <Text style={{ fontSize: `16px`, fontFamily: 'Gilroy-Light', fontWeight: '400px' }}>
                Begin meaningful discussions about any topic in your textbook with the help of ice breaker questions.
                </Text>
              </TextContainer>
            </ItemContainer>
            <ItemContainer>
              <Avatar src={ASSETS.practiceTick} alt="List Item" />
              <TextContainer>
                <Text style={{ fontSize: `16px`, fontFamily: 'Gilroy-Light', fontWeight: '400px' }}>
                Ask as many questions as you need, across multiple chapters or the entire book.
                </Text>
              </TextContainer>
            </ItemContainer>
            <ItemContainer>
              <Avatar src={ASSETS.practiceTick} alt="List Item" />
              <TextContainer>
                <Text style={{ fontSize: `16px`, fontFamily: 'Gilroy-Light', fontWeight: '400px' }}>
                Easy to understand explanation of fudamentals & difficult concepts with examples and diagrams.
                </Text>
              </TextContainer>
            </ItemContainer>
          </ListContainer>
          <StyledButton type="primary" onClick={() => navigate("/signup")}>
            Sign Up For Free
          </StyledButton>
        </TextColumn>
      </Col>
      <Col xs={24} sm={16} lg={16}>
        <GraphicColumn>
          <img src={ASSETS.talkToTextbooks} alt="Talk Graphic" style={{ width: "auto", maxHeight: "800px" }} />
        </GraphicColumn>
      </Col>
    </StyledRow>
  );
};

export default TalkSection;
