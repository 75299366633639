import React, { useState } from "react";
import { Typography, Flex, Button, theme} from "antd";
import styled from "styled-components";
import { ASSETS } from "../constants";

const { Title, Text } = Typography;
const { useToken } = theme;

const SectionContainer = styled.div`
  padding: 40px 120px;
  margin-top: 40px;
  position: relative;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const HeaderSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 48px;

  @media (max-width: 768px) {
    margin-bottom: 64px;
    align-items: center;
    text-align: center;
  }
`;

const ButtonLeft = styled(Button)`
    height: 49px;
    border-top-left-radius: 200px;
    border-bottom-left-radius: 200px;
    padding: 16px 40px;
    font-size: 14px;
    font-weight: 400;
    font-family: "Gilroy-Regular";
    border: 1px solid ${(props) => props.theme.token.colorPrimary};
`;

const ButtonRight = styled(Button)`
    height: 49px;
    border-top-right-radius: 200px;
    border-bottom-right-radius: 200px;
    padding: 16px 40px;
    font-size: 14px;
    font-weight: 400;
    font-family: "Gilroy-Regular";
    border: 1px solid ${(props) => props.theme.token.colorPrimary};
`;

const PlanContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 32px;
  margin-top: 24px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const PlanCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  background: #fff;
  border-radius: 20px;
  padding: 20px;
  gap: 30px;
  box-shadow: 0px 0px 100px 0px #0000000F;
  position: relative;
  top: ${(props) => (props.featured ? "20px" : "0")};
  height: 100%;

    @media (max-width: 768px) {
        top: 0;
    }
`;

const PlanTitle = styled(Text)`
  font-size: 18px;
  font-weight: 400;
  font-family: "Gilroy-Bold";
  line-height: 22.28px;
  color: ${(props) => props.theme.token.pricingTextColorGrey};
`;

const SubText = styled(Text)`
    font-size: 14px;
    font-weight: 400;
    font-family: "Gilroy-Regular";
    color: ${(props) => props.theme.token.pricingTextColorGrey};
`;

const PriceText = styled(Text)`
  font-size: 24px;
  font-weight: bold;
  margin: 16px 0;
`;

const FeatureList = styled.ul`
  text-align: left; /* Align the list to the left */
  align-self: flex-start;
  margin: 0;
  padding: 0;
  list-style: none; /* Remove default bullet points */
  color: ${(props) => props.theme.token.pricingTextColorGrey};

  li {
    font-family: "Gilroy-Regular";
    font-weight: 400;
    font-size: 14px;
    margin: 8px 0;
    font-size: 16px;
    display: flex;
    align-items: center; /* Align image and text */
    gap: 8px; /* Space between bullet and text */
  }
`;


const PriceContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 9px;
    background-color: #fff9f7;
    padding: 16px 30px;
    border-radius: 14px;
    color: ${(props) => props.theme.token.pricingTextColorGrey};
    font-family: "Gilroy-Bold";
    font-size: 18px;
    font-weight: 400;
`;

const StyledButton = styled(Button)`
    background-color: 'transparent';
    color: ${(props) => props.theme.token.pricingTextColorGrey};
    border: 1px solid ${(props) => props.theme.token.colorBlack};
    font-size: 16px;
    font-weight: 400;
    font-family: "Gilroy-Regular";
    border-radius: 200px;
    padding: 16px 30px;
    height: 52px;

    &:hover {
        background-color: ${(props) => props.theme.token.colorPrimary} !important;
        color: #fff !important;
        border: 1px solid ${(props) => props.theme.token.colorPrimary} !important;
        font-family: "Gilroy-Bold" !important;
    }
`;

const CustomText = styled(Text)`
    font-size: 14px;
    font-weight: 400;
    font-family: "Gilroy-Regular";
    color: ${(props) => props.theme.token.pricingTextColorGrey};
`;

const PlanCard = ({ title, subtitle, price, features, featured }) => (
  <PlanCardContainer featured={featured}>
    <div style={{ textAlign: "center", gap: "4px", display: "flex", flexDirection: "column" }}>
        <PlanTitle>{title}</PlanTitle>
        <SubText>{subtitle}</SubText>
    </div>
    <img src={ASSETS.pricingArrow} alt="pricing-arrow" style={{ width: "29px", height: "auto" }} />
    <PriceContainer>
        <div style={{
            gap: "0px"
        }}>
        <span style={{
            fontSize: "14px",
            fontWeight: "400px",
            fontFamily: "Gilroy-Bold",
        }}>$ </span>
        <span style={{
            fontSize: "32px",
            fontWeight: "400px",
            fontFamily: "Gilroy-Bold",
        }}>{price}</span>
        </div>
        <div style={{
            display: "flex",
            flexDirection: "column",
            }}>
            <span style={{
                fontSize: "20px",
                fontWeight: "400px",
                fontFamily: "Gilroy-Medium",
                lineHeight: "24px",
            }}>Per Team</span>
            <span style={{
                fontSize: "10px",
                fontWeight: "400px",
                fontFamily: "Gilroy-Regular",
                lineHeight: "12px",
            }}>With Unlimited</span>
        </div>

    </PriceContainer>
    <FeatureList>
    {features.map((feature, index) => (
        <li key={index}>
        <img
            src={index % 2 === 0 ? ASSETS.thinStar : ASSETS.thickStar}
            alt="bullet"
            style={{ width: "30px", height: "30px" }}
        />
        {feature}
        </li>
    ))}
    </FeatureList>

    <StyledButton >Start 30 Days Trial</StyledButton>
    <CustomText>
        No cards or credential required
    </CustomText>
  </PlanCardContainer>
);

const PricingSection = () => {
    const { token } = useToken();
  const [billingType, setBillingType] = useState("Yearly");

  const plansData = {
    Monthly: [
      { title: "Basic Plan", subtitle: "Lorem Ipsum is simply dummy text", price: "25", features: ["Feature A", "Feature B", "Feature C", "Feature D", "Feature E", "Feature F"] },
      { title: "Favorite Plan", subtitle: "Lorem Ipsum is simply dummy text", price: "30", features: ["Feature A", "Feature B", "Feature C", "Feature D", "Feature E", "Feature F"], featured: true },
      { title: "Standard Plan", subtitle: "Lorem Ipsum is simply dummy text", price: "35", features: ["Feature A", "Feature B", "Feature C", "Feature D", "Feature E", "Feature F"] },
    ],
    Yearly: [
      { title: "Basic Plan", subtitle: "Lorem Ipsum is simply dummy text", price: "20", features: ["Feature A", "Feature B", "Feature C", "Feature D", "Feature E", "Feature F"] },
      { title: "Favorite Plan", subtitle: "Lorem Ipsum is simply dummy text", price: "25", features: ["Feature A", "Feature B", "Feature C", "Feature D", "Feature E", "Feature F"], featured: true },
      { title: "Standard Plan", subtitle: "Lorem Ipsum is simply dummy text", price: "30", features: ["Feature A", "Feature B", "Feature C", "Feature D", "Feature E", "Feature F"] },
    ],
  };

  return (
    <SectionContainer>
      <HeaderSection>
        <Title
          style={{
            fontSize: "48px",
            fontWeight: 400,
            fontFamily: "Gilroy-Bold",
            marginBottom: "16px",
            ...(window.innerWidth <= 768 && { fontSize: "36px" }),
          }}
        >
          Which one's suitable for you?
        </Title>
        <Text
          style={{
            fontSize: "18px",
            fontFamily: "Gilroy-Regular",
            fontWeight: 400,
            color: "#7d7d7d",
            maxWidth: "600px",
          }}
        >
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took
        </Text>
        <div style={{ marginTop: "24px" }}>
          <ButtonLeft
            onClick={() => setBillingType("Monthly")}
            style={{
                backgroundColor: billingType === "Monthly" ? token.colorPrimary : 'transparent',
                color: billingType === "Monthly" ? "#FFFFFF" : token.pricingTextColorGrey,
            }}>
            Monthly
          </ButtonLeft>
          <ButtonRight
            onClick={() => setBillingType("Yearly")}
            style={{
                backgroundColor: billingType === "Yearly" ? token.colorPrimary : 'transparent',
                color: billingType === "Yearly" ? "#FFFFFF" : token.pricingTextColorGrey,
            }}
          >
            Yearly
          </ButtonRight>
        </div>
      </HeaderSection>
      <PlanContainer>
        {plansData[billingType].map((plan, index) => (
          <PlanCard
            key={index}
            title={plan.title}
            subtitle={plan.subtitle}
            price={plan.price}
            features={plan.features}
            featured={plan.featured}
          />
        ))}
      </PlanContainer>
    </SectionContainer>
  );
};

export default PricingSection;
