import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Typography,
  Layout,
  Row,
  Col,
  message,
  theme,
  Modal,
} from "antd";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ASSETS } from "../../../constants";
import { AppLogo } from "../../../components";
import { registerUser, requestOTP, verifyOTP } from "../../../services";
import { setAuth } from "../../../utils";
import { WelcomeScreen } from "../../../pages"; // Import the WelcomeScreen component
import styled from "styled-components";
import lottie from "lottie-web";
import VerifyPhone from "../verify-phone/verify-phone";

const CustomCheckbox = styled(Checkbox)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: white !important;
    border-color: #757f8e !important;
  }

  .ant-checkbox-checked:hover .ant-checkbox-inner {
    background-color: white !important;
    border-color: #757f8e !important;
  }

  .ant-checkbox-inner {
    border-color: #d9d9d9 !important; /* Keep the default border color when unchecked */
  }

  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: #757f8e !important; /* Keep the default border color on hover when unchecked */
  }

  /* Change tick color */
  .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: #757f8e !important; /* Set tick color */
  }

  /* Change text color */
  .ant-checkbox + span {
    color: #757f8e !important; /* Change the text color */
  }
`;

const TermsModal = styled(Modal)`
  .ant-modal-body {
    font-size: 14px;
    font-weight: 400;
    color: ${(props) => props.theme.token.textColorGrey};
    font-family: "Gilroy-Regular";
    overflow-y: auto;
    max-height: 60vh;
  }

  .ant-modal-close-x {
    color: black;
  }

  .ant-modal-title {
    font-size: 24px;
    font-weight: 400;
    color: ${(props) => props.theme.token.colorBlack};
    font-family: "Gilroy-Bold";
  }

  .ant-btn {
    height: 40px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 400;
    font-family: "Gilroy-SemiBold";
  }

  .ant-btn-primary {
    background-color: #ff6c42;
    border-color: #ff6c42;
  }
`;

const { Content } = Layout;
const { useToken } = theme;

const FormStyles = styled.div`
  .custom-form-item {
    .ant-form-item-explain-error {
      text-align: left;
      padding-left: 4px;
    }
  }
`;

const CustomLayout = styled(Layout)`
  height: calc(100vh - 72px); /* Full height minus top and bottom margins */
  margin: 36px 0px 36px 36px; /* Margin around the layout */
  background-color: #ffffff;
  overflow: hidden; /* Prevent page scroll */

  /* Responsive styles */
  @media (max-width: 768px) {
    margin: 0px; /* Remove margin on small screens */
  }
`;

export default function Signup() {
  const animationContainer = useRef(null);
  const animationInstance = useRef(null);
  const { token } = useToken();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [terms, setTerms] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [showVerifyPhone, setShowVerifyPhone] = useState(false);
  const [verificationData, setVerificationData] = useState(null);
  const [showWelcome, setShowWelcome] = useState(false);

  // Check if the user is an admin
  const isAdmin = location.pathname.match("signup/admin");

  useEffect(() => {
    const fetchTerms = async () => {
      try {
        const response = await fetch(ASSETS.termsAndConditions);
        if (response.ok) {
          const html = await response.text();
          setTerms(html);
        } else {
          console.error(
            "Failed to fetch terms and conditions file:",
            response.statusText
          );
        }
      } catch (error) {
        console.error("Error fetching terms and conditions file:", error);
      }
    };

    fetchTerms();
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleAgree = () => {
    setIsChecked(true);
    form.setFieldsValue({ terms: true });
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsChecked(false);
    form.setFieldsValue({ terms: false });
    setIsModalOpen(false);
  };

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const phoneNumber = values.phone.trim();
      // const phoneNumber = values.phone.replace(/^\+91/, "").trim();
      // const formattedPhone = `91${phoneNumber}`;

      // Send verification code to phone number
      const otpData = {
        phone: phoneNumber,
      };

      const response = await requestOTP(otpData);
      if (response.error) {
        throw new Error(response.error);
      }

      if (response.message) {
        // Store the phone and received code in state
        setVerificationData({
          phone: phoneNumber,
          userData: {
            first_name: values.firstName,
            last_name: values.lastName,
            phone: phoneNumber,
            email: values.email,
            password: values.password,
            confirm_password: values.confirmPassword,
            role: isAdmin ? "admin" : "student",
          },
        });
        setShowVerifyPhone(true);
        message.success(response.message);
      }
    } catch (error) {
      message.error("Failed to send verification code: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleVerification = async (otp) => {
    try {
      setLoading(true);

      // Verify the OTP with backend
      const { phone, userData } = verificationData;

      const otpData = {
        phone: phone,
        otp: otp,
      };

      const verifyOTPResponse = await verifyOTP(otpData);
      if (verifyOTPResponse.error) {
        throw new Error(verifyOTPResponse.error);
      }

      if (verifyOTPResponse.message) {
        message.success(verifyOTPResponse.message);
        const registerUserResponse = await registerUser(userData);

        // Proceed with user registration
        if (registerUserResponse.token) {
          setAuth(registerUserResponse.token, registerUserResponse.user);
          message.success("Registration successful!");
          setShowWelcome(true);
        } else if (registerUserResponse.error) {
          throw new Error(registerUserResponse.error);
        }
      }
    } catch (error) {
      message.error("Verification failed: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleComplete = () => {
    navigate("/onboarding");
  };

  const formRules = {
    firstName: [
      { required: true, message: "Please input your first name!" },
      { min: 2, message: "First name must be at least 2 characters!" },
    ],
    lastName: [
      { required: true, message: "Please input your last name!" },
      { min: 2, message: "Last name must be at least 2 characters!" },
    ],
    phone: [
      { required: true, message: "Please input your phone number!" },
      {
        pattern: /^(\+91|0)?[1-9]\d{9}$/,
        message: "Please enter a valid phone number!",
      },
    ],
    email: [
      { required: true, message: "Please input your email!" },
      { type: "email", message: "Please enter a valid email address!" },
    ],
    password: [
      { required: true, message: "Please input your password!" },
      { min: 8, message: "Password must be at least 8 characters!" },
    ],
    confirmPassword: [
      { required: true, message: "Please confirm your password!" },
    ],
  };

  const leftSectionRef = useRef(null);
  const [containerHeight, setContainerHeight] = useState(0);

  useEffect(() => {
    const updateHeight = () => {
      if (leftSectionRef.current) {
        setContainerHeight(leftSectionRef.current.offsetHeight);
      }
    };
    updateHeight();

    window.addEventListener("resize", updateHeight);
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  useEffect(() => {
    // Load the animation
    animationInstance.current = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg", // Choose 'svg', 'canvas', or 'html'
      loop: false, // Play the full animation once
      autoplay: true, // Start playing immediately
      path: ASSETS.loginPageAnimation, // Animation JSON file path
    });

    // Add event listener for when the animation completes
    animationInstance.current.addEventListener("complete", () => {
      const totalFrames = animationInstance.current.getDuration(true); // Total number of frames
      const startFrame = totalFrames - 133; // Start frame (last 30 frames)
      const endFrame = totalFrames; // End frame (last frame)

      // Play and loop the last few frames
      animationInstance.current.loop = true; // Enable looping for the segment
      animationInstance.current.playSegments([startFrame, endFrame], true);
    });

    return () => {
      animationInstance.current.destroy(); // Cleanup on unmount
    };
  }, []);

  if (showWelcome) {
    return <WelcomeScreen onComplete={handleComplete} />;
  }

  return (
    <>
      {showVerifyPhone ? (
        <VerifyPhone
          phone={verificationData.phone}
          onVerify={handleVerification}
        />
      ) : (
        <CustomLayout>
          <Row gutter={0} style={{ height: "100%" }}>
            {/* Left Section */}
            <Col xs={0} sm={0} md={13} lg={13} xl={13}>
              <div
                style={{
                  backgroundColor: token.colorPrimary,
                  borderRadius: "36px",
                  position: "sticky",
                  top: "36px",
                  height: "calc(100vh - 72px)",
                  overflow: "hidden",
                }}
              >
                <img
                  src={ASSETS.authScreenBackground}
                  alt="Auth Screen Background"
                  style={{
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    height: "auto",
                    width: "100%",
                    opacity: 0.1,
                  }}
                />
                <div
                  ref={animationContainer}
                  style={{
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    height: "100%",
                    width: "100%",
                  }}
                />
              </div>
            </Col>

            {/* Right Section */}
            <Col xs={24} sm={24} md={11} lg={11} xl={11}>
              <Content
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start", // Align content to the top
                  alignItems: "center",
                  padding: "24px",
                  height: "calc(100vh - 72px)", // Adjusted for the page margin
                  overflowY: "auto", // Make scrollable if content exceeds height
                }}
              >
                {/* Logo Section */}
                <div
                  style={{
                    textAlign: "center",
                    marginBottom: "30px",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/")}
                >
                  <AppLogo height="70px" />
                </div>

                {/* Card Container */}
                <div
                  style={{
                    backgroundColor: "#fff",
                    padding: "20px",
                    borderRadius: "20px",
                    boxShadow: "0px 0px 100px 0px #0000000F",
                    maxWidth: "400px",
                    width: "100%",
                  }}
                >
                  {/* Middle Section */}
                  <div style={{ textAlign: "center", marginBottom: "30px" }}>
                    <div
                      style={{
                        fontSize: "30px",
                        fontWeight: "400",
                        fontFamily: "Gilroy-Bold",
                        lineHeight: "37.14px",
                      }}
                    >
                      Hello, Friend!
                    </div>
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        fontFamily: "Gilroy-Medium",
                        color: "#757F8E",
                        lineHeight: "16.98px",
                      }}
                    >
                      Enter your details and start journey with us
                    </div>
                  </div>

                  <FormStyles>
                    <Form
                      form={form}
                      name="signup"
                      onFinish={onFinish}
                      layout="vertical"
                      style={{ maxWidth: 350, margin: "0 auto" }}
                      requiredMark={false}
                    >
                      <Row gutter={14}>
                        {/* First Name */}
                        <Col span={12}>
                          <Form.Item
                            label="First Name"
                            name="firstName"
                            rules={formRules.firstName}
                            style={{ marginBottom: 12 }}
                            validateStatus="error"
                            className="custom-form-item"
                          >
                            <Input
                              placeholder="eg: Divya"
                              style={{
                                backgroundColor: "#F5F7FA",
                                border: "none",
                                padding: "12px 16px",
                                borderRadius: "10px",
                                fontFamily: "Gilroy-Regular",
                                fontSize: "14px",
                                fontWeight: "400",
                              }}
                            />
                          </Form.Item>
                        </Col>

                        {/* Last Name */}
                        <Col span={12}>
                          <Form.Item
                            label="Last Name"
                            name="lastName"
                            rules={formRules.lastName}
                            style={{ marginBottom: 12 }}
                            validateStatus="error"
                            className="custom-form-item"
                          >
                            <Input
                              placeholder="eg: Agarwal"
                              style={{
                                backgroundColor: "#F5F7FA",
                                border: "none",
                                padding: "12px 16px",
                                borderRadius: "10px",
                                fontFamily: "Gilroy-Regular",
                                fontSize: "14px",
                                fontWeight: "400",
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      {/* Phone */}
                      <Form.Item
                        label="Phone Number"
                        name="phone"
                        rules={formRules.phone}
                        style={{ marginBottom: 12 }}
                        className="custom-form-item"
                      >
                        <Input
                          prefix={
                            <img
                              alt="United States"
                              src="http://purecatamphetamine.github.io/country-flag-icons/3x2/IN.svg"
                              style={{
                                width: "30px",
                                height: "auto",
                              }}
                            />
                          }
                          placeholder="123 123 1234"
                          style={{
                            backgroundColor: "#F5F7FA",
                            border: "none",
                            padding: "14px 16px",
                            borderRadius: "10px",
                            fontFamily: "Gilroy-Regular",
                            fontSize: "14px",
                            fontWeight: "400",
                          }}
                          className="custom-input"
                        />
                      </Form.Item>

                      {/* Email */}
                      <Form.Item
                        label="Email Address"
                        name="email"
                        rules={formRules.email}
                        style={{ marginBottom: 12 }}
                        validateStatus="error"
                        className="custom-form-item"
                      >
                        <Input
                          type="email"
                          placeholder="eg: divya.agarwal@gmail.com"
                          style={{
                            backgroundColor: "#F5F7FA",
                            border: "none",
                            padding: "12px 16px",
                            borderRadius: "10px",
                            fontFamily: "Gilroy-Regular",
                            fontSize: "14px",
                            fontWeight: "400",
                          }}
                        />
                      </Form.Item>

                      {/* Password */}
                      <Form.Item
                        label="Password"
                        name="password"
                        rules={formRules.password}
                        style={{ marginBottom: 12 }}
                        validateStatus="error"
                        className="custom-form-item"
                      >
                        <Input.Password
                          placeholder="Type your Password here"
                          iconRender={(visible) =>
                            visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                          }
                          style={{
                            backgroundColor: "#F5F7FA",
                            border: "none",
                            padding: "12px 16px",
                            borderRadius: "10px",
                            fontFamily: "Gilroy-Regular",
                            fontSize: "14px",
                            fontWeight: "400",
                          }}
                        />
                      </Form.Item>

                      {/* Confirm Password */}
                      <Form.Item
                        label="Confirm Password"
                        name="confirmPassword"
                        dependencies={["password"]}
                        validateStatus="error"
                        className="custom-form-item"
                        rules={[
                          ...formRules.confirmPassword,
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                getFieldValue("password") === value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error("Passwords do not match!")
                              );
                            },
                          }),
                        ]}
                        style={{ marginBottom: 12 }}
                      >
                        <Input.Password
                          placeholder="Type your Password here"
                          iconRender={(visible) =>
                            visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                          }
                          style={{
                            backgroundColor: "#F5F7FA",
                            border: "none",
                            padding: "12px 16px",
                            borderRadius: "10px",
                            fontFamily: "Gilroy-Regular",
                            fontSize: "14px",
                            fontWeight: "400",
                          }}
                        />
                      </Form.Item>

                      {/* Terms and Conditions */}
                      <Form.Item
                        name="terms"
                        valuePropName="checked"
                        validateStatus="error"
                        className="custom-form-item"
                        rules={[
                          {
                            validator: (_, value) =>
                              value
                                ? Promise.resolve()
                                : Promise.reject(
                                    new Error(
                                      "Please accept the terms and conditions"
                                    )
                                  ),
                          },
                        ]}
                        style={{ marginBottom: 20 }}
                      >
                        <div style={{ textAlign: "left" }}>
                          <CustomCheckbox
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                            style={{
                              color: "#000",
                              fontFamily: "Gilroy-Regular",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            I accept the{" "}
                            <Link
                              style={{
                                textDecoration: "underline",
                                color: token.colorPrimary,
                              }}
                              onClick={showModal}
                            >
                              Terms & Conditions.
                            </Link>
                            <TermsModal
                              title="Terms & Conditions"
                              visible={isModalOpen}
                              onOk={handleAgree}
                              onCancel={handleCancel}
                              okText="I Agree"
                              cancelText="Cancel"
                            >
                              <div
                                dangerouslySetInnerHTML={{ __html: terms }}
                              />
                            </TermsModal>
                          </CustomCheckbox>
                        </div>
                      </Form.Item>

                      {/* Sign-Up Button */}
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{
                          width: "100%",
                          backgroundColor: "#FF6C42",
                          borderColor: "#FF6C42",
                          height: 52,
                          borderRadius: 10,
                          fontSize: 16,
                          fontFamily: "Gilroy-SemiBold",
                          fontWeight: 400,
                          padding: "16px",
                          marginBottom: "20px",
                        }}
                        loading={loading}
                      >
                        Register
                      </Button>
                      <Button
                        style={{
                          width: "100%",
                          backgroundColor: "transparent",
                          borderColor: token.homeButtonBorderColor,
                          color: token.logoBlue,
                          height: 52,
                          borderRadius: 10,
                          fontSize: 16,
                          fontFamily: "Gilroy-SemiBold",
                          fontWeight: 400,
                          padding: "11px 23px",
                        }}
                        loading={loading}
                        onClick={() => navigate("/")}
                      >
                        Back to Home
                      </Button>

                      {/* <div
                      style={{
                        textAlign: "center",
                        marginTop: "20px",
                        marginBottom: "10px",
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#6B7B82",
                        fontFamily: "Gilroy-Regular",
                      }}
                    >
                      Or Continue With
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "10px",
                      }}
                    >
                      <Button
                        style={{
                          backgroundColor: "#fff",
                          color: "#000",
                          border: "1px solid #ccc",
                          height: 52,
                          borderRadius: 10,
                          fontSize: 12,
                          fontWeight: 400,
                          flex: 1,
                        }}
                      >
                        <img
                          src={ASSETS.googleLogo}
                          alt="Google"
                          style={{ marginRight: 8, height: 20 }}
                        />
                      </Button>
                      <Button
                        style={{
                          backgroundColor: "#fff",
                          color: "#000",
                          border: "1px solid #ccc",
                          height: 52,
                          borderRadius: 10,
                          fontSize: 12,
                          fontWeight: 400,
                          flex: 1,
                        }}
                      >
                        <img
                          src={ASSETS.facebookLogo}
                          alt="Facebook"
                          style={{ marginRight: 8, height: 20 }}
                        />
                      </Button>
                    </div> */}
                    </Form>
                  </FormStyles>
                </div>

                {/* Sign-in Link */}
                <div style={{ textAlign: "center" }}>
                  <Typography.Text
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#757F8E",
                      fontFamily: "Gilroy-Regular",
                    }}
                  >
                    Already have an account?{" "}
                    <Link to="/signin">
                      <Typography.Text
                        strong
                        style={{
                          color: token.colorPrimary,
                          fontFamily: "Gilroy-Regular",
                          fontWeight: 400,
                        }}
                      >
                        Log In
                      </Typography.Text>
                    </Link>
                  </Typography.Text>
                </div>
              </Content>
            </Col>
          </Row>
        </CustomLayout>
      )}
      ;
    </>
  );
}
