import { Navigate, Outlet } from "react-router-dom";
import { isAuthenticated, isOnboarded } from "../../utils";

function PublicRoute() {
  const authenticated = isAuthenticated();
  const onboarded = isOnboarded();

  if (authenticated || onboarded) {
    return <Navigate to="/home" replace />;
  }

  return <Outlet />;
}

export default PublicRoute;
