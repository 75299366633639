import React from "react";
import { Typography, Divider, theme, Form, Flex } from "antd";
import { AppTextfield } from "../../../components";

const { Text } = Typography;
const { useToken } = theme;

const ChangePassword = ({ form }) => {
  const { token } = useToken();

  const validatePassword = (_, value) => {
    const pattern = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,20}$/;
    if (!value) {
      return Promise.reject("Please input your password!");
    }
    if (!pattern.test(value)) {
      return Promise.reject(
        "Password must be 8 to 20 characters with a combination of numbers and letters"
      );
    }
    return Promise.resolve();
  };

  return (
    <div id="change-password">
      <Text
        style={{
          fontSize: "15px",
          fontWeight: "500",
          marginBottom: "16px",
          display: "block",
        }}
      >
        Change Password
      </Text>

      <Form form={form} layout="vertical" style={{ maxWidth: "100%" }}>
        <Flex vertical style={{ width: "50%" }}>
          <Form.Item
            label={<Text>Current Password</Text>}
            name="old_password"
            rules={[
              {
                required: true,
                message: "Please input your current password!",
              },
            ]}
          >
            <AppTextfield isPassword placeholder="Enter current password" />
          </Form.Item>
        </Flex>

        <Flex gap={16} style={{ marginBottom: "24px" }}>
          <Flex vertical style={{ flex: 1 }}>
            <Form.Item
              label={<Text>Password</Text>}
              name="new_password"
              rules={[
                { required: true, message: "Please input your password!" },
                { validator: validatePassword },
              ]}
              help="Password must be 8 to 20 characters with a combination of numbers and letters"
            >
              <AppTextfield isPassword placeholder="Enter new password" />
            </Form.Item>
          </Flex>

          <Flex vertical style={{ flex: 1 }}>
            <Form.Item
              label={<Text>Confirm Password</Text>}
              name="confirm_password"
              dependencies={["new_password"]}
              rules={[
                { required: true, message: "Please confirm your password!" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("new_password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject("The two passwords do not match!");
                  },
                }),
              ]}
              help="Password must be 8 to 20 characters with a combination of numbers and letters"
            >
              <AppTextfield isPassword placeholder="Confirm new password" />
            </Form.Item>
          </Flex>
        </Flex>
      </Form>

      <Divider
        style={{
          borderColor: token.colorPrimary,
          marginTop: "0px",
          marginBottom: "24px",
        }}
      />
    </div>
  );
};

export default ChangePassword;
