import React from "react";
import { Typography } from "antd";

const { Text } = Typography;

function ColoredNumber({ number, color, size = 48, opacity = 40 }) {
  // Format number to have one decimal place
  const formattedNumber = Number(number).toFixed(1);
  const [wholePart, decimalPart] = formattedNumber.split(".");

  return (
    <Text
      style={{
        fontSize: size,
        fontWeight: 400,
        fontFamily: "Inter-Tight",
      }}
    >
      <span style={{ color }}>{wholePart}</span>
      <span style={{ color }}>.</span>
      <span style={{ color: `${color}${opacity}` }}>{decimalPart}</span>
    </Text>
  );
}

export default ColoredNumber;
