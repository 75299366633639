import React, { useState, useEffect } from "react";
import {
  Modal,
  Typography,
  Space,
  Checkbox,
  AutoComplete,
  Input,
  Button,
  Card,
  Form,
  Row,
  Col,
  Flex,
  message,
} from "antd";
import {
  CloseOutlined,
  SearchOutlined,
  BookOutlined,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";

const { Text } = Typography;

const AddBookModal = ({
  visible,
  onCancel,
  onSave,
  books,
  publisherOptions,
  ebookOptions,
}) => {
  const [samePublisher, setSamePublisher] = useState(false);
  const [commonPublisher, setCommonPublisher] = useState({
    id: "",
    display: "",
  });
  const [currentBooks, setCurrentBooks] = useState([
    { id: "", name: "", publisher: { id: "", display: "" } },
  ]);
  const [addedBooks, setAddedBooks] = useState(books || []);

  // Update addedBooks when books prop changes
  useEffect(() => {
    setAddedBooks(books || []);
  }, [books]);

  const handleCommonPublisher = (value, option) => {
    setCommonPublisher({ id: option.value, display: option.label });
    const newBooks = currentBooks.map((book) => ({
      ...book,
      publisher: { id: option.value, display: option.label },
    }));
    setCurrentBooks(newBooks);
  };

  const handleBookChange = (index, field, value, option) => {
    const newBooks = [...currentBooks];
    if (field === "name") {
      newBooks[index] = {
        ...newBooks[index],
        id: option.value,
        name: option.label,
      };
    } else if (field === "publisher") {
      newBooks[index] = {
        ...newBooks[index],
        publisher: { id: option.value, display: option.label },
      };
    }
    setCurrentBooks(newBooks);
  };

  const handleAddBookToList = (index) => {
    const bookToAdd = currentBooks[index];
    if (
      bookToAdd.id &&
      (samePublisher ? commonPublisher.id : bookToAdd.publisher.id)
    ) {
      setAddedBooks([
        ...addedBooks,
        {
          ...bookToAdd,
          publisher: samePublisher ? commonPublisher : bookToAdd.publisher,
        },
      ]);
      // Clear the current book input
      const newBooks = [...currentBooks];
      newBooks[index] = {
        id: "",
        name: "",
        publisher: { id: "", display: "" },
      };
      setCurrentBooks(newBooks);
    } else {
      message.error("Please select both book and publisher");
    }
  };

  const handleRemoveFromList = (index) => {
    const newAddedBooks = addedBooks.filter((_, i) => i !== index);
    setAddedBooks(newAddedBooks);
  };

  const handleClearInput = (index) => {
    if (currentBooks.length === 1) {
      // If only one book input, just clear the fields
      const newBooks = [...currentBooks];
      newBooks[index] = {
        id: "",
        name: "",
        publisher: { id: "", display: "" },
      };
      setCurrentBooks(newBooks);
    } else {
      // If multiple book inputs, remove the card
      const newBooks = currentBooks.filter((_, i) => i !== index);
      setCurrentBooks(newBooks);
    }
  };

  const handleSave = () => {
    onSave(addedBooks);
  };

  const handleCancel = () => {
    setCurrentBooks([{ id: "", name: "", publisher: { id: "", display: "" } }]);
    setSamePublisher(false);
    setCommonPublisher({ id: "", display: "" });
    onCancel();
  };

  return (
    <Modal
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      width={800}
      closable={false}
    >
      {/* Row 1 - Header */}
      <Flex justify="space-between" align="center" style={{ marginBottom: 24 }}>
        <Space direction="vertical" size={4}>
          <Text strong style={{ fontSize: "16px" }}>
            Select books and their publisher
          </Text>
          <Text type="secondary" style={{ fontSize: "14px" }}>
            Choose your school books and publishers to help us match your
            resources!
          </Text>
        </Space>
        <CloseOutlined onClick={onCancel} style={{ cursor: "pointer" }} />
      </Flex>

      {/* Row 2 - Common Publisher Checkbox */}
      <Space direction="vertical" style={{ width: "100%", marginBottom: 24 }}>
        <Checkbox
          checked={samePublisher}
          onChange={(e) => setSamePublisher(e.target.checked)}
        >
          All the books have same publisher
        </Checkbox>
        {samePublisher && (
          <Form.Item label="Publisher" wrapperCol={{ span: 24 }}>
            <AutoComplete
              options={publisherOptions}
              value={commonPublisher.display}
              onChange={handleCommonPublisher}
              style={{ width: "100%" }}
              dropdownStyle={{ backgroundColor: "#fff" }}
            >
              <Input
                prefix={<SearchOutlined style={{ paddingRight: "6px" }} />}
                placeholder="Search or Select Publisher"
                style={{ width: "100%", backgroundColor: "#F5F7FA" }}
                dropdownStyle={{ backgroundColor: "#fff" }}
                size="large"
                variant="borderless"
              />
            </AutoComplete>
          </Form.Item>
        )}
      </Space>

      {/* Row 3 - Book Input Section */}
      <Space direction="vertical" style={{ width: "100%" }}>
        {currentBooks.map((book, index) => (
          <Card
            key={index}
            style={{ backgroundColor: "#F5F7FA", marginBottom: 16 }}
            bodyStyle={{ padding: 16 }}
          >
            <Space direction="vertical" style={{ width: "100%" }}>
              <Form.Item label="Book" wrapperCol={{ span: 24 }}>
                <AutoComplete
                  style={{ flex: 1 }}
                  options={ebookOptions}
                  value={book.name}
                  onChange={(value, option) =>
                    handleBookChange(index, "name", value, option)
                  }
                  dropdownStyle={{ backgroundColor: "#fff" }}
                >
                  <Input
                    prefix={<BookOutlined style={{ paddingRight: "6px" }} />}
                    placeholder="Search or Select Book"
                    style={{ width: "100%", backgroundColor: "#F5F7FA" }}
                    size="large"
                  />
                </AutoComplete>
              </Form.Item>
              {!samePublisher && (
                <Form.Item label="Publisher" wrapperCol={{ span: 24 }}>
                  <AutoComplete
                    style={{ flex: 1 }}
                    options={publisherOptions}
                    value={book.publisher.display}
                    onChange={(value, option) =>
                      handleBookChange(index, "publisher", value, option)
                    }
                    dropdownStyle={{ backgroundColor: "#fff" }}
                  >
                    <Input
                      prefix={
                        <SearchOutlined style={{ paddingRight: "6px" }} />
                      }
                      placeholder="Search or Select Publisher"
                      style={{ width: "100%", backgroundColor: "#F5F7FA" }}
                      size="large"
                    />
                  </AutoComplete>
                </Form.Item>
              )}
              <Flex>
                <Button
                  type="text"
                  onClick={() => handleAddBookToList(index)}
                  style={{ padding: "0 16px" }}
                >
                  <Space align="center">
                    <div
                      style={{
                        width: "14px",
                        height: "14px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "50%",
                        backgroundColor: "#8BC34A",
                        color: "#ffffff",
                        fontSize: "7px",
                      }}
                    >
                      <PlusOutlined />
                    </div>
                    <span style={{ fontSize: "14px", color: "#000000" }}>
                      Add
                    </span>
                  </Space>
                </Button>
                <Button
                  type="text"
                  onClick={() => handleClearInput(index)}
                  style={{ padding: "0 16px" }}
                >
                  <Space align="center">
                    <div
                      style={{
                        width: "14px",
                        height: "14px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "50%",
                        backgroundColor: "#C34244",
                        color: "#ffffff",
                        fontSize: "7px",
                      }}
                    >
                      <MinusOutlined />
                    </div>
                    <span style={{ fontSize: "14px", color: "#000000" }}>
                      Clear
                    </span>
                  </Space>
                </Button>
              </Flex>
            </Space>
          </Card>
        ))}
      </Space>

      {/* Added Books Section */}
      {addedBooks.length > 0 && (
        <Space
          direction="vertical"
          style={{ width: "100%", marginTop: 0, marginBottom: 24 }}
        >
          <Text strong>Books Added</Text>
          <Row gutter={[16, 16]}>
            {addedBooks.map((book, index) => (
              <Col xs={24} sm={12} key={index}>
                <Card
                  style={{ backgroundColor: "#F5F7FA", padding: "8px" }}
                  bodyStyle={{ padding: 0 }}
                >
                  <Flex
                    align="center"
                    justify="space-between"
                    style={{ width: "100%" }}
                  >
                    <div
                      style={{
                        width: "50px",
                        height: "50px",
                        backgroundColor: "#FF8968",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "8px",
                        flexShrink: 0,
                      }}
                    >
                      <BookOutlined
                        style={{ fontSize: "24px", color: "#FFFFFF" }}
                      />
                    </div>

                    <div style={{ flex: 1, marginLeft: "16px" }}>
                      <Typography.Text
                        strong
                        style={{ display: "block", fontSize: "16px" }}
                      >
                        {book.name}
                      </Typography.Text>
                      <Typography.Text
                        type="secondary"
                        style={{ fontSize: "12px" }}
                      >
                        {book.publisher.display}
                      </Typography.Text>
                    </div>

                    <div
                      style={{
                        width: "16px",
                        height: "16px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: "8px",
                        borderRadius: "50%",
                        backgroundColor: "#C34244",
                        color: "#ffffff",
                        cursor: "pointer",
                        fontSize: "8px",
                      }}
                      onClick={() => handleRemoveFromList(index)}
                    >
                      <MinusOutlined />
                    </div>
                  </Flex>
                </Card>
              </Col>
            ))}
          </Row>
        </Space>
      )}

      {/* Save Button */}
      <Button
        type="primary"
        size="large"
        onClick={handleSave}
        style={{ width: "100%" }}
      >
        Save
      </Button>
    </Modal>
  );
};

export default AddBookModal;
