import React from "react";
import { Drawer, Button, Space, theme } from "antd";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const NavLinksMobile = styled(Space)`
  width: 100%;
  flex-direction: column;
  align-items: flex-start !important;

  a {
    display: block;
    font-family: "Gilroy-Medium", sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: ${(props) => props.theme.token.logoBlue};
    text-decoration: none;
    cursor: pointer;
    padding: 12px 0;

    &:hover {
      color: ${(props) => props.theme.token.colorPrimary};
    }

    &.active {
      font-family: "Gilroy-Bold", sans-serif;
      color: ${(props) => props.theme.token.colorPrimary};
    }
  }
`;

const StyledDrawer = styled(Drawer)`
.ant-drawer-close {
  position: absolute;
  top: 20px;
  right: 0;
  color: black;
}

.ant-drawer-header {
  border: none;
}
`;

const MobileDrawer = ({ visible, onClose, sections, activeSection, handleNavigation }) => {
  const navigate = useNavigate();
  const { token } = theme.useToken();

  return (
    <StyledDrawer
      placement="right"
      onClose={onClose}
      open={visible}
      width={300}
      styles={{
        body: {
          padding: 0,
        },
        wrapper: {
          backgroundColor: token.colorWhite,
        },
        content: {
          backgroundColor: token.colorWhite,
        },
      }}
    >
      <div style={{ padding: "24px" }}>
        <NavLinksMobile>
          {sections.map(({ id }) => (
            <a
              key={id}
              onClick={() => {
                handleNavigation(id);
                onClose(); // Close the drawer after navigating
              }}
              className={activeSection === id ? "active" : ""}
            >
              {id}
            </a>
          ))}
        </NavLinksMobile>
        <Space direction="vertical" size={16} style={{ width: "100%", marginTop: "24px" }}>
          <Button
            onClick={() => {
              onClose();
              navigate("/signup");
            }}
            type="primary"
            style={{
              borderRadius: "8px",
              backgroundColor: token.colorPrimary,
              color: token.colorWhite,
              padding: "16px 20px",
              fontWeight: "400",
              fontSize: "14px",
              boxShadow: "none",
              fontFamily: "Gilroy-Semibold",
            }}
          >
            Sign Up
          </Button>
          <Button
            onClick={() => {
              onClose();
              navigate("/signin");
            }}
            type="primary"
            style={{
              backgroundColor: "transparent",
              color: token.logoBlue,
              border: `1px solid ${token.logoBlue}`,
              borderRadius: "8px",
              padding: "16px 20px",
              fontWeight: "400",
              fontSize: "14px",
              boxShadow: "none",
              fontFamily: "Gilroy-Semibold",
            }}
          >
            Log In
          </Button>
        </Space>
      </div>
    </StyledDrawer>
  );
};

export default MobileDrawer;
