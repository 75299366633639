import React, { useState, useEffect } from "react";
import { theme } from "antd";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { getProgressHistory } from "../../services";
import { getAuth, formatDate } from "../../utils";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler
);

const OverallProgressAreaChart = ({ timeframe = "weekly" }) => {
  const { token } = theme.useToken();
  const [progressData, setProgressData] = useState(null);

  useEffect(() => {
    const fetchProgressHistory = async () => {
      try {
        const { authToken } = getAuth();
        const data = await getProgressHistory(
          {
            period: timeframe,
            days: timeframe === "weekly" ? 7 : timeframe === "monthly" ? 30 : 1,
          },
          authToken
        );
        setProgressData(data);
      } catch (error) {
        console.error("Error fetching progress history:", error);
      }
    };

    fetchProgressHistory();
  }, [timeframe]);

  const processChartData = () => {
    if (!progressData?.history) return { labels: [], data: [] };

    const sortedHistory = [...progressData.history].sort(
      (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
    );

    return {
      labels: sortedHistory.map((item) => formatDate(item.timestamp)),
      data: sortedHistory.map((item) => item.progress),
    };
  };

  const { labels, data: chartData } = processChartData();

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        max: 100,
        ticks: {
          callback: (value) => `${value}%`,
          stepSize: 20,
          color: token.colorTextSecondary,
        },
        grid: {
          color: token.colorBorder,
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: token.colorTextSecondary,
        },
        title: {
          display: true,
          text: "Time",
          color: token.colorTextSecondary,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: (context) =>
            formatDate(progressData.history[context[0].dataIndex].timestamp),
          label: (context) => `Progress: ${context.parsed.y}%`,
        },
      },
    },
  };

  const chartDataConfig = {
    labels,
    datasets: [
      {
        fill: true,
        data: chartData,
        borderColor: token.colorPrimary,
        backgroundColor: `${token.colorPrimary}20`,
        tension: 0.4,
      },
    ],
  };

  return (
    <div
      style={{
        position: "relative",
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Line options={options} data={chartDataConfig} />
    </div>
  );
};

export default OverallProgressAreaChart;
