import React, { useState, useEffect, useRef } from "react";
import { Layout, Button, Space, theme } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { AppLogo } from "../components";
import MobileDrawer from "./mobile-drawer";
import { useNavigate } from "react-router-dom";

const { useToken } = theme;

const StickyHeader = styled(Layout.Header)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  height: 80px;
  background-color: ${(props) => props.theme.token.colorBgBase};
  border-bottom: 1px solid ${(props) => props.theme.token.colorBgLayout};
  transition: box-shadow 0.3s ease;
  box-shadow: ${(props) =>
    props.scrolled ? "0 2px 8px rgba(0, 0, 0, 0.06)" : "none"};

  @media (min-width: 768px) {
    padding: 0 120px;
  }
`;

const NavLinks = styled(Space)`
  display: flex;
  align-items: center;
  margin: 0 auto;

  a {
    font-family: "Gilroy", sans-serif;
    font-size: 14px;
    font-weight: 400;
    margin: 0 16px;
    color: ${(props) => props.theme.token.logoBlue};
    text-decoration: none;
    cursor: pointer;
    position: relative; // Position relative to allow positioning of the border

    &.active {
      font-family: "Gilroy-Bold", sans-serif;
      color: ${(props) => props.theme.token.logoBlue};
      // Move the border bottom further down by adjusting the position
      &::after {
        content: "";
        position: absolute;
        bottom: -8px; // Adjust this value to position the border lower
        left: 0;
        width: 100%;
        height: 2px;
        background-color: ${(props) => props.theme.token.colorPrimary};
      }
    }

    &:hover {
      color: ${(props) => props.theme.token.logoBlue};
    }
  }

  // Hide nav links on mobile
  @media (max-width: 767px) {
    display: none;
  }
`;

const DesktopButtons = styled(Space)`
  display: none;
  @media (min-width: 768px) {
    display: flex;
  }
`;

const MobileMenuButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 768px) {
    display: none;
  }
`;

const LandingHeader = ({ sections }) => {
  const { token } = useToken();
  const navigate = useNavigate();
  const [isScrolled, setIsScrolled] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [activeSection, setActiveSection] = useState("Home");

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition > 0);

      // Update active section based on scroll position
      sections.forEach(({ id, ref }) => {
        if (
          ref.current &&
          ref.current.offsetTop <= scrollPosition + 80 &&
          ref.current.offsetTop + ref.current.offsetHeight > scrollPosition + 80
        ) {
          setActiveSection(id);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [sections]);

  const handleNavigation = (id) => {
    const section = sections.find((section) => section.id === id);
    if (section?.ref.current) {
      section.ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <StickyHeader theme={{ token }} scrolled={isScrolled}>
        <AppLogo />
        <NavLinks>
          {sections.map(({ id }) => (
            <a
              key={id}
              onClick={() => handleNavigation(id)}
              className={activeSection === id ? "active" : ""}
            >
              {id}
            </a>
          ))}
        </NavLinks>
        <DesktopButtons>
        <Button
            onClick={() => navigate("/signup")}
            type="primary"
            style={{
              borderRadius: "8px",
              backgroundColor: token.colorPrimary,
              color: token.colorWhite,
              padding: "16px 20px",
              fontWeight: "400",
              fontSize: "16px",
              height: "43px",
              boxShadow: "none",
              fontFamily: "Gilroy-Semibold",
            }}
          >
            Sign Up
          </Button>
          <Button
            onClick={() => navigate("/signin")}
            type="primary"
            style={{
              backgroundColor: 'transparent',
              color: token.logoBlue,
              border: `1px solid ${token.logoBlue}`,
              borderRadius: "8px",
              padding: "16px 20px",
              fontWeight: "400",
              fontSize: "16px",
              height: "43px",
              boxShadow: "none",
              fontFamily: "Gilroy-Semibold",
            }}
          >
            Log In
          </Button>
        </DesktopButtons>
        <MobileMenuButton
          type="text"
          icon={<MenuOutlined style={{ fontSize: "24px" }} />}
          onClick={() => setDrawerVisible(true)}
        />
      </StickyHeader>

      <MobileDrawer
        visible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        sections={sections}
        activeSection={activeSection}
        handleNavigation={handleNavigation}
      />

      <div style={{ height: "80px" }} />
    </>
  );
};

export default LandingHeader;
