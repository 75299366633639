import React from "react";
import { Flex, Switch, Typography, Space, Divider, theme } from "antd";

const { Text } = Typography;
const { useToken } = theme;

const NotificationSettings = () => {
  const { token } = useToken();

  const SwitchItem = ({ title, subtitle, defaultChecked }) => (
    <Flex style={{ marginBottom: "10px" }}>
      <Switch defaultChecked={defaultChecked} style={{ marginRight: "10px" }} />
      <Space direction="vertical">
        <Text style={{ fontWeight: "500" }}>{title}</Text>
        <Text style={{ fontWeight: "400", color: token.colorGrey }}>
          {subtitle}
        </Text>
      </Space>
    </Flex>
  );

  const NotificationItem = ({ title, subtitle, switchItems }) => (
    <Flex style={{ marginBottom: "24px" }}>
      {/* Left Column */}
      <div style={{ flex: "1 1 50%" }}>
        <Text style={{ fontWeight: "500", display: "block" }}>{title}</Text>
        <Text style={{ fontWeight: "400", color: token.colorGrey }}>
          {subtitle}
        </Text>
      </div>

      {/* Right Column */}
      <div style={{ flex: "1 1 50%" }}>
        {switchItems.map((item, index) => (
          <SwitchItem
            key={index}
            title={item.title}
            subtitle={item.subtitle}
            defaultChecked={item.defaultChecked}
          />
        ))}
      </div>
    </Flex>
  );

  const notificationsList = [
    {
      title: "Email notifications",
      subtitle:
        "Get emails to find out what's going on when you're not online. You can turn these off",
      switchItems: [
        {
          title: "News & updates",
          subtitle: "News about product & feature updates",
          defaultChecked: true,
        },
        {
          title: "Tips and tutorials",
          subtitle: "Tips on getting more out of Dash",
          defaultChecked: false,
        },
        {
          title: "Reminders",
          subtitle:
            "These are notifications to remind you of updates you might have missed",
          defaultChecked: false,
        },
      ],
    },
    {
      title: "WhatsApp notifications",
      subtitle:
        "Get messages to find out what's going on when you're not online. You can turn these off",
      switchItems: [
        {
          title: "News & updates",
          subtitle: "News about product & feature updates",
          defaultChecked: true,
        },
        {
          title: "Tips and tutorials",
          subtitle: "Tips on getting more out of Dash",
          defaultChecked: true,
        },
        {
          title: "Reminders",
          subtitle:
            "These are notifications to remind you of updates you might have missed",
          defaultChecked: false,
        },
      ],
    },
  ];

  return (
    <div id="notifications">
      <Text
        style={{
          fontSize: "15px",
          fontWeight: "500",
          marginBottom: "16px",
          display: "block",
        }}
      >
        Notification Settings
      </Text>

      {notificationsList.map((item, index) => (
        <React.Fragment key={index}>
          <NotificationItem
            title={item.title}
            subtitle={item.subtitle}
            switchItems={item.switchItems}
          />
        </React.Fragment>
      ))}
      <Divider style={{ borderColor: token.colorPrimary }} />
    </div>
  );
};

export default NotificationSettings;
