import React, { useState, useEffect, useRef } from "react";
import { Button, Space, Typography, Input, theme } from "antd";
import { SearchOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { AvatarWithText, SearchResults } from "../../components";
import { performSearch } from "../../utils/helpers/search-helper";
import { useBooks } from "../../contexts"; // Assuming you have this context
import { ASSETS } from "../../constants";

const { Text } = Typography;
const { useToken } = theme;

const Header = () => {
  const { token } = useToken();
  const navigate = useNavigate();
  const { bookList } = useBooks();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState({ pages: [], books: [] });
  const [showResults, setShowResults] = useState(false);
  const searchRef = useRef(null);

  const userData = JSON.parse(localStorage.getItem("userData"));
  const firstName = userData?.first_name;
  const lastName = userData?.last_name;
  const userName = userData ? `${firstName} ${lastName}` : "";

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowResults(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleSearch = (value) => {
    setSearchQuery(value);
    const results = performSearch(value, bookList);
    setSearchResults(results);
    setShowResults(true);
  };

  const handleSearchComplete = () => {
    setShowResults(false);
    setSearchQuery("");
  };

  return (
    <header
      style={{
        width: "100%",
        height: "80px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0 24px",
        borderBottom: token.border,
        backgroundColor: token.colorWhite,
      }}
    >
      <Space>
        <Text
          style={{
            fontSize: "20px",
            fontWeight: "400",
            fontFamily: "Gilroy-Regular",
          }}
        >
          Welcome Back {firstName}!
          <img
            src={ASSETS.waveHandAuthScreens}
            style={{
              verticalAlign: "middle",
              marginLeft: "12px",
              width: "36px",
              height: "36px",
            }}
            alt="wave"
          />
        </Text>
      </Space>
      <Space>
        <div ref={searchRef} style={{ position: "relative" }}>
          <Input
            className="header-search-input"
            placeholder="Search"
            size="large"
            value={searchQuery}
            onChange={(e) => handleSearch(e.target.value)}
            prefix={
              <SearchOutlined style={{ color: token.colorTextSecondary }} />
            }
            suffix={
              <Button
                shape="circle"
                style={{
                  border: "none",
                }}
              >
                <img src={ASSETS.searchRightArrow} alt="search" />
              </Button>
            }
            style={{
              borderRadius: "50px",
              width: "300px",
              border: "none",
              backgroundColor: "#f7f7f8",
            }}
          />
          {showResults &&
            (searchResults.pages.length > 0 ||
              searchResults.books.length > 0) && (
              <SearchResults
                results={searchResults}
                onSelect={handleSearchComplete}
              />
            )}
        </div>
        <Button shape="circle" style={{ border: "none" }}>
          <img src={ASSETS.notificationsHeaderIcon} alt="notifications" />
        </Button>
        <AvatarWithText
          text=""
          initials={userName.split(" ")[0][0] + userName.split(" ")[1][0]}
          isBadge={true}
          onClick={() => navigate("/profile")}
        />
      </Space>
    </header>
  );
};

export default Header;
