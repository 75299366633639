import React from "react";
import { Layout, Typography, Space, Row, Col, Flex } from "antd";
import { useSelectedChapters } from "../../contexts";
import { theme } from "antd";
import { ASSETS } from "../../constants";

const { useToken } = theme;
const { Content } = Layout;
const { Title, Text, Paragraph } = Typography;

function QuizInfoDisplay({ book }) {
  const { token } = useToken();
  const { selectedChapters } = useSelectedChapters();

  // Join selected chapters into a comma-separated string
  // Map selected chapter IDs to their titles
  const chapterTitles = selectedChapters
    .map((id) => {
      const chapter = book?.chapters?.find((ch) => ch.id === id);
      return chapter?.title;
    })
    .filter(Boolean) // Remove any undefined values
    .join(", ");

  return (
    <Layout
      style={{
        width: "100%",
        backgroundColor: token.colorWhite,
        borderRadius: "16px",
      }}
    >
      <Content>
        <Space direction="vertical" style={{ width: "100%" }} size="middle">
          {/* Chapter Titles */}
          <Title
            level={4}
            style={{
              margin: "0px",
              color: token.logoBlue,
              fontFamily: "Gilroy-Medium",
            }}
          >
            {book?.title ? `${book.title}: ` : ""}
            {chapterTitles || "No chapters selected"}
          </Title>

          {/* Quiz Description */}
          <Paragraph
            style={{
              color: token.logoBlue,
              fontFamily: "Gilroy-Regular",
              fontSize: "14px",
            }}
          >
            This quiz will test your knowledge on various topics from the
            selected chapters. It is designed to challenge your understanding
            and improve your retention. Expect a mix of multiple-choice
            questions and short answers, with a focus on key concepts and
            critical thinking.
          </Paragraph>

          {/* Category */}
          <Row>
            <Col>
              <Flex align="center" justify="center">
                <img
                  src={ASSETS.categoryQuizSettings}
                  alt="Category Quiz Settings"
                  style={{ marginRight: "8px" }}
                />
                <Text
                  style={{
                    color: token.logoBlue,
                    fontFamily: "Gilroy-Medium",
                  }}
                  strong
                >
                  Category:
                </Text>
                <Text
                  style={{
                    color: token.logoBlue,
                    fontFamily: "Gilroy-Regular",
                    marginLeft: "4px",
                  }}
                >
                  General Knowledge
                </Text>
              </Flex>
            </Col>
          </Row>

          {/* Estimated Duration */}
          <Row>
            <Col>
              <Flex align="center" justify="center">
                <img
                  src={ASSETS.durationQuizSettings}
                  alt="Duration Quiz Settings"
                  style={{ marginRight: "8px" }}
                />
                <Text
                  style={{
                    color: token.logoBlue,
                    fontFamily: "Gilroy-Medium",
                  }}
                  strong
                >
                  Estimated Duration:
                </Text>
                <Text
                  style={{
                    color: token.logoBlue,
                    fontFamily: "Gilroy-Regular",
                    marginLeft: "4px",
                  }}
                >
                  15 minutes
                </Text>
              </Flex>
            </Col>
          </Row>

          {/* Instructions */}
          <div style={{ marginTop: "8px" }}>
            <Text
              style={{
                color: token.logoBlue,
                fontFamily: "Gilroy-Medium",
              }}
              strong
            >
              Instructions:
            </Text>
            <ul>
              <li
                style={{ color: token.logoBlue, fontFamily: "Gilroy-Regular" }}
              >
                Read each question carefully before answering.
              </li>
              <li
                style={{ color: token.logoBlue, fontFamily: "Gilroy-Regular" }}
              >
                You have one attempt for each question.
              </li>
              <li
                style={{ color: token.logoBlue, fontFamily: "Gilroy-Regular" }}
              >
                Use the "Next" button to move to the next question.
              </li>
              <li
                style={{ color: token.logoBlue, fontFamily: "Gilroy-Regular" }}
              >
                Submit your quiz once you have answered all questions.
              </li>
            </ul>
          </div>
        </Space>
      </Content>
    </Layout>
  );
}

export default QuizInfoDisplay;
