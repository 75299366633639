import React, { useEffect, useState } from "react";
import {
  Layout,
  Typography,
  Flex,
  Form,
  Row,
  Col,
  DatePicker,
  Button,
  Input,
  message,
} from "antd";
import { AvatarWithText, AppTextfield, AppDropdown } from "../../components";
import dayjs from "dayjs";
import { getProfile, updateProfile } from "../../services";
import { getAuth } from "../../utils";

const { Content } = Layout;
const { Text } = Typography;

function Profile() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [profileData, setProfileData] = useState(null);
  const { authToken } = getAuth();

  const genderMapping = {
    male: "M",
    female: "F",
    other: "O",
  };

  const reverseGenderMapping = {
    M: "male",
    F: "female",
    O: "other",
  };

  // Fetch profile data
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const data = await getProfile(authToken);
        setProfileData(data);

        // Set form values
        form.setFieldsValue({
          firstName: data.first_name,
          lastName: data.last_name,
          phone: data.phone?.replace("+91", ""),
          gender: reverseGenderMapping[data.gender] || "male",
          dob: data.date_of_birth ? dayjs(data.date_of_birth) : undefined,
          address: data.permanent_address,
          cityState: data.city_state,
          country: data.country,
          postalCode: data.post_code,
        });
      } catch (error) {
        message.error("Failed to fetch profile data");
      }
    };

    fetchProfile();
  }, [authToken, form]);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const profileData = {
        first_name: values.firstName,
        last_name: values.lastName,
        phone: values.phone ? `+91${values.phone}` : "",
        gender: genderMapping[values.gender] || null,
        date_of_birth: values.dob ? values.dob.format("YYYY-MM-DD") : null,
        permanent_address: values.address || null,
        city_state: values.cityState || null,
        country: values.country || null,
        post_code: values.postalCode || null,
      };
      console.log(profileData);

      await updateProfile(profileData, authToken);
      message.success("Profile updated successfully");
    } catch (error) {
      message.error("Failed to update profile");
    } finally {
      setLoading(false);
    }
  };

  const fullName = profileData
    ? `${profileData.first_name} ${profileData.last_name}`
    : "";

  const genderOptions = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "other", label: "Other" },
  ];

  return (
    <Layout style={{ padding: "16px" }}>
      <Content>
        <Text strong style={{ fontSize: "16px", marginBottom: "24px" }}>
          Profile
        </Text>

        <Flex gap={32} style={{ marginTop: "24px" }}>
          {/* Left Column - Avatar */}
          <div style={{ flex: "0 0 200px" }}>
            <AvatarWithText
              text=""
              avatarSize={160}
              initials={
                fullName
                  ? fullName.split(" ")[0][0] + fullName.split(" ")[1][0]
                  : ""
              }
              isEditable={true}
              onEditClick={() => {
                console.log("Edit clicked");
              }}
            />
          </div>

          {/* Right Column - Form */}
          <div style={{ flex: 1 }}>
            <Form
              form={form}
              layout="vertical"
              style={{ maxWidth: "100%" }}
              requiredMark={false}
              onFinish={onFinish}
            >
              {/* Row 1 - Name Fields */}
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="First Name" name="firstName">
                    <AppTextfield placeholder="Enter first name" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Last Name" name="lastName">
                    <AppTextfield placeholder="Enter last name" />
                  </Form.Item>
                </Col>
              </Row>

              {/* Row 2 - Phone Number */}
              <Form.Item
                label="Phone Number"
                name="phone"
                className="custom-form-item"
              >
                <Input
                  addonBefore="+91"
                  placeholder="1231231231"
                  style={{
                    backgroundColor: "#F5F7FA",
                    padding: "12px 0",
                    borderRadius: "8px",
                    border: "1px solid #d9d9d9",
                  }}
                  className="custom-input"
                />
              </Form.Item>

              {/* Row 3 - Gender and DOB */}
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Gender" name="gender">
                    <AppDropdown
                      size="large"
                      width={"100%"}
                      options={genderOptions}
                      placeholder="Select gender"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Date of Birth" name="dob">
                    <DatePicker
                      style={{ width: "100%", backgroundColor: "#F5F7FA" }}
                      size="large"
                      format="DD/MM/YYYY"
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/* Row 4 - Address Fields */}
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Permanent Address (Optional)"
                    name="address"
                  >
                    <AppTextfield placeholder="Enter address" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="City/State (Optional)" name="cityState">
                    <AppTextfield placeholder="Enter city and state" />
                  </Form.Item>
                </Col>
              </Row>

              {/* Row 5 - Country and Postal */}
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Country (Optional)" name="country">
                    <AppTextfield placeholder="Enter country" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Postal Code (Optional)" name="postalCode">
                    <AppTextfield placeholder="Enter postal code" />
                  </Form.Item>
                </Col>
              </Row>

              {/* Row 6 - Submit Button */}
              <Form.Item style={{ width: "100%" }}>
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  style={{ width: "100%", marginBottom: "12px" }}
                  loading={loading}
                >
                  Update Profile
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Flex>
      </Content>
    </Layout>
  );
}

export default Profile;
