const DashboardDrawerIcon = ({ color }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill={color} xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_874_9609)">
      <path fill="none" d="M1.5 13.5C1.5 12.3447 1.5 11.767 1.76005 11.3426C1.90555 11.1052 2.1052 10.9055 2.34265 10.76C2.767 10.5 3.34467 10.5 4.5 10.5C5.65533 10.5 6.23299 10.5 6.65735 10.76C6.8948 10.9055 7.09444 11.1052 7.23995 11.3426C7.5 11.767 7.5 12.3447 7.5 13.5C7.5 14.6553 7.5 15.233 7.23995 15.6574C7.09444 15.8948 6.8948 16.0945 6.65735 16.24C6.23299 16.5 5.65533 16.5 4.5 16.5C3.34467 16.5 2.767 16.5 2.34265 16.24C2.1052 16.0945 1.90555 15.8948 1.76005 15.6574C1.5 15.233 1.5 14.6553 1.5 13.5Z" stroke={color} stroke-width="1.3"/>
      <path fill="none" d="M10.5 13.5C10.5 12.3447 10.5 11.767 10.76 11.3426C10.9055 11.1052 11.1052 10.9055 11.3426 10.76C11.767 10.5 12.3447 10.5 13.5 10.5C14.6553 10.5 15.233 10.5 15.6574 10.76C15.8948 10.9055 16.0945 11.1052 16.24 11.3426C16.5 11.767 16.5 12.3447 16.5 13.5C16.5 14.6553 16.5 15.233 16.24 15.6574C16.0945 15.8948 15.8948 16.0945 15.6574 16.24C15.233 16.5 14.6553 16.5 13.5 16.5C12.3447 16.5 11.767 16.5 11.3426 16.24C11.1052 16.0945 10.9055 15.8948 10.76 15.6574C10.5 15.233 10.5 14.6553 10.5 13.5Z" stroke={color} stroke-width="1.3"/>
      <path fill="none" d="M1.5 4.5C1.5 3.34467 1.5 2.767 1.76005 2.34265C1.90555 2.1052 2.1052 1.90555 2.34265 1.76005C2.767 1.5 3.34467 1.5 4.5 1.5C5.65533 1.5 6.23299 1.5 6.65735 1.76005C6.8948 1.90555 7.09444 2.1052 7.23995 2.34265C7.5 2.767 7.5 3.34467 7.5 4.5C7.5 5.65533 7.5 6.23299 7.23995 6.65735C7.09444 6.8948 6.8948 7.09444 6.65735 7.23995C6.23299 7.5 5.65533 7.5 4.5 7.5C3.34467 7.5 2.767 7.5 2.34265 7.23995C2.1052 7.09444 1.90555 6.8948 1.76005 6.65735C1.5 6.23299 1.5 5.65533 1.5 4.5Z" stroke={color} stroke-width="1.3"/>
      <path fill="none" d="M10.5 4.5C10.5 3.34467 10.5 2.767 10.76 2.34265C10.9055 2.1052 11.1052 1.90555 11.3426 1.76005C11.767 1.5 12.3447 1.5 13.5 1.5C14.6553 1.5 15.233 1.5 15.6574 1.76005C15.8948 1.90555 16.0945 2.1052 16.24 2.34265C16.5 2.767 16.5 3.34467 16.5 4.5C16.5 5.65533 16.5 6.23299 16.24 6.65735C16.0945 6.8948 15.8948 7.09444 15.6574 7.23995C15.233 7.5 14.6553 7.5 13.5 7.5C12.3447 7.5 11.767 7.5 11.3426 7.23995C11.1052 7.09444 10.9055 6.8948 10.76 6.65735C10.5 6.23299 10.5 5.65533 10.5 4.5Z" stroke={color} stroke-width="1.3"/>
      </g>
      <defs>
      <clipPath id="clip0_874_9609">
      <rect width="18" height="18" fill="none"/>
      </clipPath>
      </defs>
      </svg>

        );
};

export default DashboardDrawerIcon;
