import React, { useState } from "react";
import {
  Card,
  Flex,
  Typography,
  Switch,
  theme,
  Divider,
  Input,
  Radio,
  Space,
  InputNumber,
} from "antd";
import { motion, AnimatePresence } from "framer-motion";

const { Text } = Typography;
const { useToken } = theme;

function SwitchCard({
  icon,
  title,
  description,
  defaultChecked,
  onChange,
  isPassMark,
  isWeightage,
  quizTypes,
}) {
  const { token } = useToken();
  const [isChecked, setIsChecked] = useState(defaultChecked);
  const [weightageType, setWeightageType] = useState("uniform");
  const [unit, setUnit] = useState("point");

  // For uniform weightage
  const [uniformValue, setUniformValue] = useState(1);

  // For individual weightage
  const [individualWeightages, setIndividualWeightages] = useState(
    quizTypes?.reduce(
      (acc, type) => ({
        ...acc,
        [type]: { value: 1, unit: "point" },
      }),
      {}
    ) || {}
  );

  const handleSwitchChange = (checked) => {
    setIsChecked(checked);
    onChange(checked);
  };

  const handleWeightageTypeChange = (e) => {
    setWeightageType(e.target.value);
  };

  const handleUniformValueChange = (value) => {
    setUniformValue(value);
    const newWeightages = {};
    Object.keys(individualWeightages).forEach((type) => {
      newWeightages[type] = { value, unit };
    });
    setIndividualWeightages(newWeightages);
  };

  const handleIndividualWeightageChange = (type, value, field) => {
    setIndividualWeightages((prev) => ({
      ...prev,
      [type]: {
        ...prev[type],
        [field]: value,
      },
    }));
  };

  return (
    <Card
      style={{
        border: "1px solid #E2E2E2",
        borderRadius: "12px",
        marginBottom: "12px",
        backgroundColor: token.colorFooterCard,
      }}
      bodyStyle={{ padding: "12px" }}
    >
      <Flex align="center" justify="space-between">
        <Flex align="center">
          {icon && (
            <div style={{ marginRight: "12px", fontSize: "24px" }}>{icon}</div>
          )}
          <Flex vertical>
            <Text
              style={{
                fontFamily: "Gilroy-Medium",
                color: token.logoBlue,
              }}
              strong
            >
              {title}
            </Text>
            <Text
              style={{
                fontFamily: "Gilroy-Regular",
                color: token.logoBlue,
              }}
              type="secondary"
            >
              {description}
            </Text>
          </Flex>
        </Flex>
        <Switch
          checked={isChecked}
          onChange={handleSwitchChange}
          style={{ marginLeft: "12px" }}
        />
      </Flex>

      <AnimatePresence>
        {isWeightage && isChecked && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
          >
            <Divider style={{ margin: "12px 0" }} />
            <Space direction="vertical" style={{ width: "100%" }}>
              <Radio.Group
                value={weightageType}
                onChange={handleWeightageTypeChange}
                style={{ marginBottom: "12px" }}
              >
                <Radio value="uniform">Uniform Weightage</Radio>
                <Radio value="individual">Individual Weightage</Radio>
              </Radio.Group>

              {weightageType === "uniform" ? (
                <Flex align="center" justify="space-evenly">
                  <Space direction="vertical" size="small">
                    <Text>Value</Text>
                    <InputNumber
                      min={1}
                      value={uniformValue}
                      onChange={handleUniformValueChange}
                      style={{ width: 120 }}
                    />
                  </Space>
                  <Space direction="vertical" size="small">
                    <Text>Unit</Text>
                    <Radio.Group
                      value={unit}
                      onChange={(e) => setUnit(e.target.value)}
                    >
                      <Radio.Button value="point">Point</Radio.Button>
                      <Radio.Button value="%">%</Radio.Button>
                    </Radio.Group>
                  </Space>
                </Flex>
              ) : (
                <Space direction="vertical" style={{ width: "100%" }}>
                  {quizTypes?.map((type) => (
                    <Card
                      key={type}
                      size="small"
                      style={{ marginBottom: "8px" }}
                    >
                      <Flex justify="space-between" align="center">
                        <Text strong>{type}</Text>
                        <Space>
                          <InputNumber
                            min={1}
                            value={individualWeightages[type]?.value}
                            onChange={(value) =>
                              handleIndividualWeightageChange(
                                type,
                                value,
                                "value"
                              )
                            }
                            style={{ width: 120 }}
                          />
                          <Radio.Group
                            value={individualWeightages[type]?.unit}
                            onChange={(e) =>
                              handleIndividualWeightageChange(
                                type,
                                e.target.value,
                                "unit"
                              )
                            }
                          >
                            <Radio.Button value="point">Point</Radio.Button>
                            <Radio.Button value="%">%</Radio.Button>
                          </Radio.Group>
                        </Space>
                      </Flex>
                    </Card>
                  ))}
                </Space>
              )}
            </Space>
          </motion.div>
        )}

        {isPassMark && isChecked && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
          >
            <Divider style={{ margin: "12px 0" }} />
            <Flex align="center" justify="space-evenly">
              <Space direction="vertical" size="small">
                <Text>Value</Text>
                <Input style={{ width: 120 }} />
              </Space>
              <Space direction="vertical" size="small">
                <Text>Unit</Text>
                <Radio.Group
                  value={unit}
                  onChange={(e) => setUnit(e.target.value)}
                >
                  <Radio.Button value="%">%</Radio.Button>
                  <Radio.Button value="point">Point</Radio.Button>
                </Radio.Group>
              </Space>
            </Flex>
          </motion.div>
        )}
      </AnimatePresence>
    </Card>
  );
}

export default SwitchCard;
