import React from "react";
import { Flex, Button, Form, Input, Typography, theme } from "antd";
import { useNavigate } from "react-router-dom";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { AppLogo } from "../../../components";
import styled from "styled-components";

const { Text } = Typography;
const { useToken } = theme;

const TitleText = styled(Text)`
  font-size: 30px;
  font-weight: 700;
  font-family: "DM-Sans", sans-serif;
  margin: 0;
`;

const SubText = styled(Text)`
  font-size: 14px;
  font-weight: 400;
  font-family: "DM-Sans", sans-serif;
  margin: 0;
  width: 80%;
  color: #757F8E;
`;

const TitleContainer = styled.div`
  text-align: center;
  gap: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CardContainer = styled.div`
  background-color: #fff;
  width: 100%;
  max-width: 567px;
  border-radius: 16px;
  padding: 20px;
  gap: 32px;
  box-shadow: 0px 25px 49.7px 0px #0000000D;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 20px;
  }
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const StyledInput = styled(Input.Password)`
  border-radius: 8px;
  height: 48px;
  width: 100%;
  padding: 12px 16px;
  background-color: ${(props) => props.theme.token.colorBgLayout} !important;
  border: none !important;
  box-shadow: none !important;

  &:hover {
    background-color: ${(props) => props.theme.token.colorBgLayout} !important;
    border: none !important;
    box-shadow: none; /* Remove hover effect */
  }

  &:focus {
    background-color: ${(props) => props.theme.token.colorBgLayout} !important;
    border: none !important;
    box-shadow: none; /* Remove focus outline */
  }

  &:active {
    background-color: ${(props) => props.theme.token.colorBgLayout} !important;
    border: none !important;
    box-shadow: none; /* Remove active
  }
`;


const StyledButton = styled(Button)`
  background-color: ${(props) => props.theme.token.colorPrimary};
  color: ${(props) => props.theme.token.colorWhite};
  border: none;
  font-weight: 600;
  font-size: 14px;
  font-family: "DM-Sans", sans-serif;
  border-radius: 10px;
  padding: 16px;
  width: 100%;
  gap: 10px;
  height: 50px;
`;

const LinkText = styled(Text)`
  color: black;
  font-weight: 400;
  font-size: 14px;
  font-family: "DM-Sans", sans-serif;
  cursor: pointer;
  text-decoration: underline;
`;

const CustomFormItem = styled(Form.Item)`
  margin-bottom: 0;
`;

const InfoText = styled(Text)`
  font-size: 10px;
  font-weight: 400;
  font-family: "DM-Sans", sans-serif;
  color: black;
`;

const FormItemContainer = styled.div`
  margin-bottom: 24px;
`;

export default function ResetPassword() {
  const token = useToken();
  const [form] = Form.useForm();

  const navigate = useNavigate();

  const onFinish = (values) => {
    console.log("Received values:", values);
    // Handle password reset logic here
  };

  return (
    <SectionContainer>
          <LogoContainer
          style={{cursor: 'pointer'}}
          onClick={() => navigate("/")}>
            <AppLogo height="70px" />
          </LogoContainer>
          <CardContainer>
          <TitleContainer>
                <TitleText>
                Create a New Password
            </TitleText>
            <SubText>
            Set a secure new password to regain access to your account.
            </SubText>
            </TitleContainer>

          <Form
            form={form}
            name="reset_password"
            onFinish={onFinish}
            layout="vertical"
            style={{ width: "100%" }}
            requiredMark={false}
          >
            <FormItemContainer>
            <CustomFormItem
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please input your new password!" },
                {
                  min: 8,
                  message: "Password must be at least 8 characters long!",
                },
              ]}
            >
              <StyledInput
                placeholder="Type your Password here"
                iconRender={(visible) =>
                  visible ? (
                    <EyeOutlined style={{ color: "black" }} />
                  ) : (
                    <EyeInvisibleOutlined style={{ color: "black" }} />
                  )
                }
              />
            </CustomFormItem>
            <InfoText>
            password must be 8 to 20 characters with a combination of numbers and letters
            </InfoText>
            </FormItemContainer>
            
            <FormItemContainer>
            <CustomFormItem
              name="confirmPassword"
              label="Confirm Password"
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                  message: "Please confirm your new password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("The two passwords do not match!")
                    );
                  },
                }),
              ]}
            >
              <StyledInput
                placeholder="Type your Password here"
                iconRender={(visible) =>
                  visible ? (
                    <EyeOutlined style={{ color: 'black' }} />
                  ) : (
                    <EyeInvisibleOutlined style={{ color: "black" }} />
                  )
                }
              />
            </CustomFormItem>
            <InfoText>
              password must be 8 to 20 characters with a combination of numbers and letters
            </InfoText>
            </FormItemContainer>

            <Form.Item>
              <StyledButton
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
              >
                Continue
              </StyledButton>
            </Form.Item>
          </Form>
          </CardContainer>
          </SectionContainer>
  );
}
