import { getAuth } from "../utils";
import { WORDCLOUD_URL } from "./api-urls";

export const getOnboardingData = async () => {
  const { authToken } = getAuth();

  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Token ${authToken}`,
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await fetch(WORDCLOUD_URL, requestOptions);
    if (!response.ok) {
      throw new Error("Failed to fetch books list");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching books list:", error);
    throw error;
  }
};
