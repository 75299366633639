import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { isAuthenticated, isOnboarded } from "../../utils";

const OnboardingRoute = () => {
  if (!isAuthenticated()) {
    return <Navigate to="/signin" replace />;
  }

  if (isOnboarded()) {
    return <Navigate to="/home" replace />;
  }

  return <Outlet />;
};

export default OnboardingRoute;
