import { Card, Flex, Typography, Avatar, theme } from "antd";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import React, { useState } from "react";

const { Paragraph } = Typography;
const { useToken } = theme;

const QuizOptionCard = ({
  optionNumber,
  text,
  isCorrect,
  onClick,
  highlighted,
  showCorrect,
}) => {
  const [hovered, setHovered] = useState(false);
  const { token } = useToken();

  const iconStyle = {
    marginLeft: "auto",
    display: "flex",
    transition: "opacity 0.3s ease, visibility 0.3s ease",
    fontSize: "24px",
  };

  // Determine card styling based on state
  const getCardStyle = () => {
    if (!showCorrect) {
      // During quiz
      return {
        borderColor: highlighted ? token.colorWhite : token.borderColor,
        backgroundColor: highlighted ? token.colorPrimary : "inherit",
      };
    } else {
      // After submission
      if (isCorrect) {
        // Correct answer
        return {
          borderColor: token.colorSuccess,
          backgroundColor: token.colorSuccessBg,
        };
      } else if (highlighted && !isCorrect) {
        // Wrong selected answer
        return {
          borderColor: token.colorError,
          backgroundColor: token.colorErrorBg,
        };
      }
      // Unselected wrong answers
      return {
        borderColor: token.borderColor,
        backgroundColor: "inherit",
      };
    }
  };

  const styles = getCardStyle();

  return (
    <Card
      style={{
        borderRadius: "8px",
        padding: "12px",
        marginBottom: "8px",
        border: token.border,
        transition: "all 0.3s ease",
        cursor: "pointer",
        ...styles,
      }}
      bodyStyle={{ padding: 0 }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={onClick}
    >
      <Flex
        align="center"
        style={{
          width: "100%",
          paddingInline: "8px",
          whiteSpace: "nowrap",
        }}
      >
        <Paragraph
          style={{
            margin: 0,
            fontWeight: 500,
            fontFamily: "Gilroy-Bold",
            fontSize: "14px",
            color: highlighted ? token.colorWhite : token.logoBlue,
          }}
        >
          {optionNumber}
        </Paragraph>
        <Paragraph
          style={{
            margin: 0,
            fontWeight: 500,
            fontFamily: "Gilroy-Medium",
            fontSize: "14px",
            color: highlighted ? token.colorWhite : token.logoBlue,
            marginLeft: "18px",
          }}
        >
          {text}
        </Paragraph>
        {showCorrect && (
          <>
            {isCorrect && (
              <CheckCircleTwoTone
                twoToneColor={token.colorSuccess}
                style={iconStyle}
              />
            )}
            {highlighted && !isCorrect && (
              <CloseCircleTwoTone
                twoToneColor={token.colorError}
                style={iconStyle}
              />
            )}
          </>
        )}
      </Flex>
    </Card>
  );
};

export default QuizOptionCard;
