import { Flex, Typography, theme } from "antd";
import { ASSETS } from "../../constants";
import styled from "styled-components";
const { useToken } = theme;
const { Text } = Typography;

const ArrowTextCard = ({
  text,
  isMirrored,
  isFlipped,
  rotation,
  arrowMargin,
}) => {
  const { token } = useToken();

  const getCircleWidth = (text) => {
    return `${Math.max(130, text.length * 10)}px`;
  };

  const getRandomRotation = () => {
    return Math.random() * 10 - 5;
  };

  return (
    <Flex
      vertical
      justify="center"
      align="center"
      style={{ width: "100%", margin: "10px 0" }}
    >
      {isFlipped && (
        <>
          <div style={{ 
            position: "relative", 
            display: "inline-block",
            width: getCircleWidth(text)
          }}>
            <img
              src={ASSETS.coveredCircleTextGraphic}
              alt="Circle Text Graphic"
              style={{ display: "block", width: "100%" }}
            />
            <Text
              style={{
                fontSize: "1rem",
                lineHeight: "1.2",
                position: "absolute",
                width: "70%",
                fontFamily: "Gilroy-Regular",
                fontWeight: 400,
                top: "50%",
                left: "50%",
                transform: `translate(-50%, -50%) rotate(${getRandomRotation()}deg)`,
                color: token.textColorGreen,
                textAlign: "center",
                wordWrap: "break-word",
                whiteSpace: "normal",
                overflowWrap: "break-word",
              }}
            >
              {text}
            </Text>
          </div>
          <div style={{ height: "10px" }}></div>
        </>
      )}
      <img
        src={ASSETS.coveredArrow}
        alt="Arrow"
        style={{
          marginRight: isMirrored ? arrowMargin : 0,
          marginLeft: isMirrored ? 0 : arrowMargin,
          height: "50px",
          transform: `
      ${isMirrored ? "scaleX(-1)" : ""}
      rotate(${isFlipped ? 180 + rotation : rotation}deg)
    `,
        }}
      />
      {!isFlipped && (
        <>
          <div style={{ height: "10px" }}></div>
          <div style={{ 
            position: "relative", 
            display: "inline-block",
            width: getCircleWidth(text)
          }}>
            <img
              src={ASSETS.coveredCircleTextGraphic}
              alt="Circle Text Graphic"
              style={{ display: "block", width: "100%" }}
            />
            <Text
              style={{
                fontSize: "1rem",
                fontFamily: "Gilroy-Regular",
                fontWeight: 400,
                lineHeight: "1.2",
                width: "70%",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: `translate(-50%, -50%) rotate(${getRandomRotation()}deg)`,
                color: token.textColorGreen,
                textAlign: "center",
                wordWrap: "break-word",
                whiteSpace: "normal",
                overflowWrap: "break-word",
              }}
            >
              {text}
            </Text>
          </div>
        </>
      )}
    </Flex>
  );
};

const ArrowText = styled.span`
  font-size: 1.125rem;
  
  @media (max-width: 768px) {
    font-size: 0.875rem;
  }
`;

export default ArrowTextCard;
