import styled from "styled-components";
import { Menu } from "antd";

// Styled components for the drawer menu(a menu which displays all the items of a section for the drawer) and section label.
export const DrawerMenu = styled(Menu)`
  // Remove the border of the menu
  border: none !important;

  // Set the background color of the menu
  background-color: ${({ theme }) => theme.token.colorBgLayout};

  .ant-menu-item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
    transition: background-color 0.2s ease;

    /* On hover or selected, set the background color of the menu item to white */
    &:hover,
    &.ant-menu-item-selected,
    &.ant-menu-item-active {
      background-color: ${({ theme }) => theme.token.colorWhite} !important;
    }

    /* Force background color on the item itself */
    &.ant-menu-item-selected {
      background-color: ${({ theme }) => theme.token.colorPrimary} !important;
    }
  }
`;

export const SectionLabel = styled.div`
  text-transform: uppercase;
  color: ${({ theme }) => theme.token.logoBlue};
  font-size: 10px;
  font-family: "Gilroy-Medium";
  font-weight: 400;
  padding: 0 24px;
  margin-top: 16px;
  margin-bottom: 8px;
`;
