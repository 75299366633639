import {
  GENERATE_QUIZ_URL,
  EVALUATE_QUIZ_URL,
  RECENT_RESULTS_URL,
  CURRENT_PROGRESS_URL,
  STATS_URL,
  PROGRESS_HISTORY_URL,
} from "./api-urls";

export const generateQuiz = async (quizData, token) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
    body: JSON.stringify(quizData),
  };

  try {
    const response = await fetch(GENERATE_QUIZ_URL, requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const evaluateQuiz = async (quizData, token) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
    body: JSON.stringify(quizData),
  };

  try {
    const response = await fetch(EVALUATE_QUIZ_URL, requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getRecentResults = async (token) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  };

  try {
    const response = await fetch(RECENT_RESULTS_URL, requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getStats = async (token) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  };

  try {
    const response = await fetch(STATS_URL, requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getCurrentProgress = async (token) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  };

  try {
    const response = await fetch(CURRENT_PROGRESS_URL, requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

//GET PROGRESS HISTORY -> takes in two params : period : daily, weekly, monthly & days : 7, 30 etc.
export const getProgressHistory = async (progressHistoryData, token) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  };

  const period = progressHistoryData.period;
  const days = progressHistoryData.days;

  const endpoint = `${PROGRESS_HISTORY_URL}?period=${period}&days=${days}`;

  try {
    const response = await fetch(endpoint, requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};
