import React from "react";
import { Input, Typography, theme } from "antd";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";

const { Text } = Typography;
const { useToken } = theme;

const AppTextfield = ({
  isPassword = false,
  value,
  onChange,
  placeholder,
  message,
  error,
  ...props
}) => {
  const InputComponent = isPassword ? Input.Password : Input;
  const { token } = useToken();

  return (
    <>
      <InputComponent
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        size="large"
        style={{
          backgroundColor: token.colorBgLayout,
          padding: "12px 16px",
          borderRadius: "8px",
        }}
        iconRender={
          isPassword
            ? (visible) =>
                visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
            : null
        }
        {...props}
      />
      {message && (
        <Text
          style={{
            fontSize: 10,
            color: error ? "#ff4d4f" : token.colorTextSecondary,
            marginTop: 4,
            display: "block",
          }}
        >
          {message}
        </Text>
      )}
    </>
  );
};

export default AppTextfield;
