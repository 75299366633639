import React from "react";
import { Typography, theme } from "antd";
import styled from "styled-components";
import CountUp from "react-countup";

const { Text } = Typography;
const { useToken } = theme;

const CardContainer = styled.div`
  width: 60px;
  height: 57px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.backgroundColor};
  margin-bottom: 10px; /* Add space between icon and count for mobile */
`;

const IconImage = styled.img`
  width: 25px;
  height: 25px;
  filter: brightness(0) invert(1);
`;

const DescriptionText = styled.div`
  font-size: 16px;
  font-weight: 400;
  font-family: "Gilroy-Medium";
  color: ${(props) => props.color};
  line-height: 1.2;
  text-align: center; /* Center align description for mobile */
`;

const CounterContainer = styled.div`
  display: flex;
  flex-direction: row; /* Default horizontal layout for web */
  align-items: center;
  gap: 10px;

  @media (max-width: 768px) {
    flex-direction: column; /* Stack elements vertically on mobile */
    text-align: center;
  }
`;

const Counter = ({
  iconSrc,
  count,
  description,
  backgroundColor,
  startCounting,
}) => {
  const { token } = useToken();

  // Parse the count string to get the numeric value and suffix
  const match = count.match(/^([\d,]+)(.*)$/);
  const numericValue = parseFloat(match[1].replace(/,/g, ""));
  const suffix = match[2];

  return (
    <CounterContainer>
      <CardContainer backgroundColor={backgroundColor}>
        <IconImage src={iconSrc} alt="Counter Icon" />
      </CardContainer>
      <div>
        <Text
          style={{
            fontSize: "32px",
            color: token.logoBlue,
            fontFamily: "Gilroy-Bold",
            fontWeight: 400,
            lineHeight: '39.62px',
          }}
        >
          <CountUp
            start={0}
            end={numericValue}
            duration={2}
            separator=","
            decimals={0}
            suffix={suffix}
            preserveValue={true}
            key={startCounting ? "counting" : "idle"}
          />
        </Text>
        <DescriptionText color={token.colorLightGrey}>
          {description}
        </DescriptionText>
      </div>
    </CounterContainer>
  );
};

export default Counter;
