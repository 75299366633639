import React, { useRef, useState, useEffect } from "react";
import { theme } from "antd";
import styled from "styled-components";
import Counter from "./counter";

const { useToken } = theme;

const SectionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 120px;
  padding: 40px 120px;

  @media (max-width: 768px) {
    flex-direction: column; /* Stack counters vertically */
    gap: 40px; /* Add space between counters */
    padding: 20px;
  }
`;

const CounterSection = () => {
  const { token } = useToken();
  const [startCounting, setStartCounting] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setStartCounting((prev) => !prev); // Toggle to trigger recount
        }
      },
      {
        threshold: 0.2, // Trigger when 20% of the section is visible
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <SectionContainer ref={sectionRef}>
      <Counter
        iconSrc="images/school-icon.svg"
        count="31,441"
        description="Total Schools"
        backgroundColor={token.colorPrimary}
        startCounting={startCounting}
      />
      <Counter
        iconSrc="images/student-icon.svg"
        count="285.2M"
        description="Total Students"
        backgroundColor={token.colorDarkPurple}
        startCounting={startCounting}
      />
      <Counter
        iconSrc="images/two-books-icon.svg"
        count="50+"
        description="Books"
        backgroundColor={token.colorForestGreen}
        startCounting={startCounting}
      />
    </SectionContainer>
  );
};

export default CounterSection;
