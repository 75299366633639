import React, { useState, useEffect, useRef } from "react";
import { Flex, Button, Form, Input, Typography, theme } from "antd";
import { Link } from "react-router-dom";
import { AppLogo } from "../../../components";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const { Title, Text } = Typography;
const { useToken } = theme;

const TitleText = styled(Text)`
  font-size: 30px;
  font-weight: 700;
  font-family: "DM-Sans", sans-serif;
  margin: 0;
`;

const SubText = styled(Text)`
  font-size: 14px;
  font-weight: 400;
  font-family: "DM-Sans", sans-serif;
  margin: 0;
  width: 80%;
  color: #757F8E;
  text-align: center;
`;

const TitleContainer = styled.div`
  text-align: center;
  gap: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CardContainer = styled.div`
  background-color: #fff;
  width: 100%;
  max-width: 567px;
  border-radius: 16px;
  padding: 20px;
  gap: 24px;
  box-shadow: 0px 25px 49.7px 0px #0000000D;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 20px;
  }
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const StyledButton = styled(Button)`
  background-color: ${(props) => props.theme.token.colorPrimary};
  color: ${(props) => props.theme.token.colorWhite};
  border: none;
  font-weight: 600;
  font-size: 14px;
  font-family: "DM-Sans", sans-serif;
  border-radius: 10px;
  padding: 16px;
  width: 100%;
  gap: 10px;
  height: 50px;

  &:hover {
    background-color: ${(props) => props.theme.token.colorPrimary} !important;
    color: ${(props) => props.theme.token.colorWhite} !important;
  }
`;

const LinkText = styled(Text)`
  font-weight: 400;
  font-size: 14px;
  font-family: "DM-Sans", sans-serif;
  cursor: pointer;
  text-decoration: none;
  color: ${(props) => props.theme.token.colorPrimary};
`;

export default function VerifyEmail( { email } ) {
  const { token } = useToken();
  const  navigate = useNavigate();

  return (
    <SectionContainer>
      <LogoContainer
        style={{cursor: 'pointer'}}
        onClick={() => navigate("/")}>
        <AppLogo height="70px" />
      </LogoContainer>
      <CardContainer>
              <TitleContainer>
                <TitleText>
                  Please verify your email
                </TitleText>
                <SubText>
                  You're almost there! We sent an email to <span style={{ color: token.colorPrimary }}> {email} </span>
                </SubText>
              </TitleContainer>
              <SubText>
                Just click on the link in that email to complete your signup. If you don't see it, you may need to check your spam folder.
              </SubText>
              <SubText>
                Still can't find the email?
              </SubText>
              <StyledButton>
                Resend Email
              </StyledButton>

              <SubText>
                Need help? <LinkText onClick={() => navigate("/contactus")}>Contact Support</LinkText>
              </SubText>
      </CardContainer>
    </SectionContainer>
  );
}
