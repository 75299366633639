import React from "react";
import { Flex, Typography, Anchor, Button, theme, Form, message } from "antd";
import styled from "styled-components";
import AcademicSettings from "./sections/academic-settings";
import NotificationSettings from "./sections/notification-settings";
import PaymentOptions from "./sections/payment-options";
import PreferLanguage from "./sections/prefer-language";
import ChangePassword from "./sections/change-password";
import DeleteAccount from "./sections/delete-account";
import { updateAcademicSettings, changePassword } from "../../services";
import { getAuth } from "../../utils";

const { Text } = Typography;
const { useToken } = theme;

const StyledAnchor = styled(Anchor)`
  .ant-anchor {
    padding-left: 0;
  }

  .ant-anchor-ink {
    display: none;
  }

  .ant-anchor-link {
    padding: 8px 0;
  }

  .ant-anchor-link-title {
    display: inline-block;
    padding: 12px 24px;
    background-color: ${(props) => props.theme.token.colorBgLayout};
    color: ${(props) => props.theme.token.colorGrey};
    border-radius: 24px;
    font-size: 14px;
    transition: all 0.3s;
    width: 100%;

    &:hover {
      color: ${(props) => props.theme.token.colorLightGrey} !important;
    }
  }

  .ant-anchor-link-active > .ant-anchor-link-title {
    background-color: ${(props) => props.theme.token.colorPrimary};
    color: ${(props) => props.theme.token.colorWhite} !important;
  }
`;

const Settings = () => {
  const { token } = useToken();
  const [academicSettingsForm] = Form.useForm();
  const [changePasswordForm] = Form.useForm();

  const handleAnchorClick = (e, link) => {
    e.preventDefault();
    console.log(link);
  };

  const handleSave = async () => {
    try {
      const { authToken } = getAuth();

      // Get values from both forms
      const academicValues = await academicSettingsForm.validateFields();
      const passwordValues = await changePasswordForm.validateFields();

      // Make API calls in parallel if both forms are valid
      const promises = [];

      if (Object.keys(academicValues).length > 0) {
        console.log("academicValues", academicValues);
        promises.push(
          updateAcademicSettings(
            {
              grade_id: academicValues.grade,
              school_id: academicValues.school,
              board_id: academicValues.board,
              book_ids: academicValues.books,
              exam_date: academicValues.examDate,
            },
            authToken
          )
        );
      }

      if (Object.keys(passwordValues).length > 0) {
        promises.push(
          changePassword(
            {
              old_password: passwordValues.old_password,
              new_password: passwordValues.new_password,
              confirm_password: passwordValues.confirm_password,
            },
            authToken
          )
        );
      }

      await Promise.all(promises);
      message.success("Settings updated successfully");
    } catch (error) {
      message.error("Failed to update settings");
    }
  };

  const handleCancel = () => {
    academicSettingsForm.resetFields();
    changePasswordForm.resetFields();
  };

  return (
    <Flex>
      {/* Left Side - Navigation */}
      <Flex
        vertical
        style={{
          width: "240px",
          padding: "16px",
          position: "sticky",
          top: 0,
          height: "100vh",
          borderRight: `1px solid ${token.borderColor}`,
        }}
      >
        <Text strong style={{ fontSize: "16px", marginBottom: "24px" }}>
          Settings
        </Text>

        <StyledAnchor
          affix={false}
          onClick={handleAnchorClick}
          items={[
            {
              key: "academic",
              href: "#academic-settings",
              title: "Academic Settings",
            },
            {
              key: "notifications",
              href: "#notifications",
              title: "Notification Settings",
            },
            {
              key: "payment",
              href: "#payment-options",
              title: "Payment Options",
            },
            {
              key: "language",
              href: "#prefer-language",
              title: "Prefer Language",
            },
            {
              key: "password",
              href: "#change-password",
              title: "Change Password",
            },
            {
              key: "delete",
              href: "#delete-account",
              title: "Delete Account",
            },
          ]}
        />
      </Flex>

      {/* Right Side - Content */}
      <Flex vertical style={{ flex: 1, position: "relative" }}>
        {/* Sticky Div which will include two buttons at the end of the div, Cancel (secondary button ) and Save Changers (primary button) */}
        <Flex
          justify="flex-end"
          style={{
            position: "sticky",
            top: 0,
            right: 0,
            zIndex: 1000,
            padding: 16,
            backgroundColor: token.colorBgLayout,
          }}
        >
          <Button
            type="secondary"
            size="large"
            onClick={handleCancel}
            style={{
              marginRight: "16px",
              border: `1px solid ${token.colorPrimary}`,
              color: token.colorPrimary,
            }}
          >
            Cancel
          </Button>
          <Button type="primary" size="large" onClick={handleSave}>
            Save Changes
          </Button>
        </Flex>
        <Flex vertical style={{ padding: "16px" }}>
          <AcademicSettings form={academicSettingsForm} />
          <NotificationSettings />
          <PaymentOptions />
          <PreferLanguage />
          <ChangePassword form={changePasswordForm} />
          <DeleteAccount />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Settings;
