import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { theme } from "antd";
import styled from "styled-components";

const { useToken } = theme;

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const HoursActivityBarChart = () => {
  const { token } = useToken();

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Prevents automatic aspect ratio resizing
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: () => "Time",
          label: (context) => `${context.raw} Hrs`,
        },
        backgroundColor: token.colorWhite,
        titleColor: token.colorText,
        bodyColor: token.colorText,
        borderColor: token.colorBorder,
        borderWidth: 1,
        padding: 10,
        displayColors: false,
        cornerRadius: 12,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: token.colorGrey,
        },
        title: {
          display: true,
          text: "Subjects",
          color: token.colorGrey,
        },
      },
      y: {
        grid: {
          color: token.colorBorderSecondary,
          drawBorder: false,
        },
        ticks: {
          color: token.colorGrey,
          callback: (value) => `${value}h`,
          stepSize: 3,
          max: 18,
        },
      },
    },
  };

  const data = {
    labels: ["Math", "Phy", "Chem", "IT", "Bio", "Hindi", "Eng"],
    datasets: [
      {
        data: [12, 8, 15, 4, 9, 10, 7],
        backgroundColor: (context) => {
          const value = context.raw;
          return value === Math.min(...data.datasets[0].data)
            ? token.colorPrimary
            : token.lightGreen;
        },
        borderRadius: 12,
        barThickness: 6,
      },
    ],
  };

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        width: "100%",  // Ensure it takes up the full width of its parent
        overflow: "hidden", // Prevent content overflow
        height: "100%",
      }}
    >
      <Bar options={options} data={data} />
    </div>
  );
};

export default HoursActivityBarChart;
