import {
  DashboardOutlined,
  SettingOutlined,
  ReadOutlined,
  QuestionCircleOutlined,
  BellOutlined,
  BarChartOutlined,
  BookOutlined,
} from "@ant-design/icons";

// Define searchable pages and their related terms
export const searchablePages = [
  {
    title: "Dashboard Page",
    subtitle: "Go to main dashboard",
    path: "/home",
    icon: DashboardOutlined,
    keywords: [
      "dashboard",
      "overall progress",
      "highest score",
      "lowest score",
      "recent results",
      "progress",
    ],
  },
  {
    title: "Reports Page",
    subtitle: "View your performance reports",
    path: "/reports",
    icon: BarChartOutlined,
    keywords: [
      "reports",
      "overall progress",
      "highest score",
      "lowest score",
      "recent results",
      "progress",
      "areas of strengths",
      "areas of weaknesses",
    ],
  },
  {
    title: "Take a Test",
    subtitle: "Start a new quiz",
    path: "/test",
    icon: ReadOutlined,
    keywords: [
      "test",
      "quiz",
      "mcq",
      "multiple choice",
      "fill in the blanks",
      "subjective",
      "true or false",
    ],
  },
  {
    title: "Ask Your Book",
    subtitle: "Get answers from your book",
    path: "/book",
    icon: QuestionCircleOutlined,
    keywords: ["ask", "question", "book", "answers"],
  },
  {
    title: "Notifications",
    subtitle: "View your notifications",
    path: "/notifications",
    icon: BellOutlined,
    keywords: ["notifications", "alerts", "messages"],
  },
  {
    title: "Settings",
    subtitle: "Manage your preferences",
    path: "/settings",
    icon: SettingOutlined,
    keywords: ["settings", "preferences", "account"],
  },
];

// Search function that returns matching pages and books
export const performSearch = (query, bookList) => {
  if (!query) return { pages: [], books: [] };

  const normalizedQuery = query.toLowerCase();

  // Search pages
  const matchingPages = searchablePages.filter((page) => {
    return (
      page.keywords.some((keyword) =>
        keyword.toLowerCase().includes(normalizedQuery)
      ) || page.title.toLowerCase().includes(normalizedQuery)
    );
  });

  // Search books
  const matchingBooks = bookList.filter(
    (book) =>
      book.title.toLowerCase().includes(normalizedQuery) ||
      book.subject?.toLowerCase().includes(normalizedQuery)
  );

  return {
    pages: matchingPages,
    books: matchingBooks,
  };
};
