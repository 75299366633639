import React from "react";
import { Avatar, Space, Badge, Typography, Button, theme } from "antd";
import { EditFilled } from "@ant-design/icons";

const { Text } = Typography;
const { useToken } = theme;

function AvatarWithText({
  text,
  subtitle,
  isBadge,
  initials,
  onClick,
  image,
  avatarSize = 42,
  isEditable = false,
  onEditClick,
}) {
  const { token } = useToken();

  // Calculate font size based on avatar size
  const fontSize = Math.floor(avatarSize * 0.4); // 40% of avatar size
  const editButtonSize = Math.floor(avatarSize * 0.2); // 30% of avatar size

  return (
    <Space onClick={onClick} style={{ cursor: "pointer" }}>
      <div style={{ position: "relative" }}>
        <Badge dot={isBadge} offset={[-4, 10]} color="green">
          <Avatar
            size={avatarSize}
            src={image}
            style={{
              backgroundColor: image ? "transparent" : token.colorAccent,
              color: token.colorPrimary,
              fontSize: `${fontSize}px`,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {!image && initials}
          </Avatar>
        </Badge>
        {isEditable && (
          <Button
            type="primary"
            icon={<EditFilled />}
            size="large"
            onClick={(e) => {
              e.stopPropagation();
              onEditClick?.();
            }}
            style={{
              position: "absolute",
              bottom: 10,
              right: 10,
              width: `${editButtonSize}px`,
              height: `${editButtonSize}px`,
              minWidth: `${editButtonSize}px`,
              borderRadius: "50%",
              padding: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: token.colorPrimary,
              color: token.colorWhite,
              border: "none",
            }}
          />
        )}
      </div>
      <div>
        <Text type="primary">{text}</Text>
        <Text type="secondary">{subtitle}</Text>
      </div>
    </Space>
  );
}

export default AvatarWithText;
