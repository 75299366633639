import React, { useState } from "react";
import PropTypes from "prop-types";
import { Layout, Flex, Space, Button, theme } from "antd";
import {
  LeftOutlined,
  RightOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import {
  AppLogo,
  DrawerSection,
  navigationItems,
  mainItems,
  settingsItems,
  logoutItem,
  ConfirmModal,
} from "../../components";
import { logout } from "../../utils";
import styled from "styled-components";
import { ASSETS } from "../../constants";

const { Sider } = Layout;
const { useToken } = theme;

const Drawer = ({ collapsed, setCollapsed, isMobileView }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { token } = useToken();
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const handleMenuClick = ({ key }) => {
    if (key === "/logout") {
      setShowLogoutModal(true);
    } else {
      navigate(key);
    }
  };

  const handleLogoutConfirm = () => {
    setShowLogoutModal(false);
    logout(navigate);
  };

  const handleLogoutCancel = () => {
    setShowLogoutModal(false);
  };

  const renderLogo = () => (
    <Space>
      {collapsed ? <img style={{width: '50px'}} src={ASSETS.shortLogo} /> : <AppLogo height="45px" />}
    </Space>
  );

  // A toggle button to collapse or expand the drawer
  const renderMenuToggleButton = () => (
    <Button
      type="text"
      icon={collapsed ? <RightOutlined /> : <LeftOutlined />}
      onClick={() => setCollapsed(!collapsed)}
      style={{
        fontSize: "16px",
        width: "24px",
        height: "24px",
        borderRadius: "1000px",
        position: "absolute",
        left: collapsed ? "calc(100% - 16px)" : "calc(100% - 16px)",
        padding: 16,
        backgroundColor: token.colorWhite,
        color: token.colorPrimary,
        zIndex: 2000,
      }}
    />
  );

  const renderCloseButton = () =>
    isMobileView && (
      <Space>
        <Button
          shape="circle"
          type="ghost"
          icon={
            <CloseOutlined
              style={{
                fontSize: 16,
                color: token.colorPrimary,
                cursor: "pointer",
              }}
            />
          }
          onClick={() => setCollapsed(!collapsed)}
        />
      </Space>
    );

  const siderStyle = {
    borderRight: token.border,
    backgroundColor: token.colorBgLayout,
    height: "100vh",
    overflow: "visible", // Allow elements to overflow outside the drawer
    position: "relative", // Required for absolute positioning of the button
    ...(isMobileView
      ? {
          position: "fixed",
          left: 0,
          top: 0,
        }
      : {
          position: "sticky",
          left: 0,
          top: 0,
        }),
    zIndex: 1000,
  };

  const getSiderWidth = () => {
    if (isMobileView) {
      return 300; // Fixed width for mobile
    }
    const viewportWidth = window.innerWidth;
    const desiredWidth = Math.floor(viewportWidth * 0.18);
    return Math.min(Math.max(desiredWidth, 250), 400);
  };

  return (
    <>
      <Sider
        trigger={null}
        breakpoint="lg"
        collapsedWidth={116}
        collapsible
        collapsed={collapsed}
        onCollapse={setCollapsed}
        theme="light"
        width={getSiderWidth()}
        style={siderStyle}
      >
        <Flex vertical style={{ height: "100vh" }}>
          <Flex
            style={{ padding: "24px 14px 24px 8px", margin: 0 }}
            justify={collapsed ? "center" : "space-between"}
            align="center"
          >
            {renderLogo()}{" "}
            {/* Only show logo when not collapsed */}
            {isMobileView ? renderCloseButton() : renderMenuToggleButton()}
          </Flex>
          {/* Only show label when not collapsed */}
          <Flex vertical style={{ flex: 1, overflowY: "auto" }}>
            <DrawerSection
              label="Navigation"
              items={navigationItems}
              selectedKeys={[location.pathname]}
              onItemClick={handleMenuClick}
              collapsed={collapsed}
            />
            <DrawerSection
              label="Main"
              items={mainItems}
              selectedKeys={[location.pathname]}
              onItemClick={handleMenuClick}
              collapsed={collapsed}
            />
            <DrawerSection
              label="Settings"
              items={settingsItems}
              selectedKeys={[location.pathname]}
              onItemClick={handleMenuClick}
              collapsed={collapsed}
            />
            <div style={{ marginTop: "auto", marginBottom: "16px", height: "68px" }}>
              <DrawerSection
                items={logoutItem}
                selectedKeys={[location.pathname]}
                onItemClick={handleMenuClick}
                collapsed={collapsed}
              />
            </div>
          </Flex>
        </Flex>
      </Sider>

      <ConfirmModal
        visible={showLogoutModal}
        onCancel={handleLogoutCancel}
        title="Are You Sure You Want to Logout?"
        subtitle="By Logging out, you will have to signin again. Please confirm if you wish to proceed."
        secondaryButtonText="Yes, Logout"
        primaryButtonText="No, Go Back"
        onSecondaryButton={handleLogoutConfirm}
        onPrimaryButton={handleLogoutCancel}
      />
    </>
  );
};

Drawer.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  setCollapsed: PropTypes.func.isRequired,
  isMobileView: PropTypes.bool.isRequired,
};

export default Drawer;
