const AskYourBookDrawerIcon = ({ color }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.375 12.6965V7.5C15.375 4.67157 15.375 3.25736 14.4963 2.37868C13.6177 1.5 12.2034 1.5 9.375 1.5H8.625C5.79657 1.5 4.38236 1.5 3.50368 2.37868C2.625 3.25736 2.625 4.67157 2.625 7.5V14.625" stroke={color} stroke-linecap="round"/>
<path d="M15.375 12.75H4.5C3.46447 12.75 2.625 13.5895 2.625 14.625C2.625 15.6605 3.46447 16.5 4.5 16.5H15.375" stroke={color} stroke-linecap="round"/>
<path d="M15.375 16.5C14.3395 16.5 13.5 15.6605 13.5 14.625C13.5 13.5895 14.3395 12.75 15.375 12.75" stroke={color} stroke-linecap="round"/>
<path d="M9.23078 9L8.13525 5.60918C8.0664 5.39599 7.85505 5.25 7.61535 5.25C7.37569 5.25 7.16437 5.39599 7.09549 5.60918L6 9M11.25 5.25V9M6.40384 7.875H8.8269" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
</svg>

  );
};

export default AskYourBookDrawerIcon;
