import React, { useState, useEffect } from "react";
import {
  Layout,
  Card,
  Typography,
  AutoComplete,
  Button,
  theme,
  Checkbox,
  Input,
  Space,
  Form,
  Flex,
  Row,
  Col,
  message,
  Spin,
} from "antd";
import Steps from "./steps";
import {
  PlusOutlined,
  SearchOutlined,
  MinusOutlined,
  BookOutlined,
} from "@ant-design/icons";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { updateOnboarding, getOnboardingData } from "../../services";
import { getAuth } from "../../utils";
import { ASSETS } from "../../constants";
import { clipPath } from "framer-motion/client";

const { Content } = Layout;
const { Title, Text } = Typography;
const { useToken } = theme;

const Logo = styled.img`
  width: 250px; /* Adjust size as needed */
  height: auto;
  margin: 20px auto; /* Center the logo horizontally */
  display: block; /* Ensure the logo is displayed as a block-level element */
`;

const CustomCheckbox = styled(Checkbox)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${(props) => props.theme.token.colorPrimary} !important;
    border-color: ${(props) => props.theme.token.colorPrimary} !important;
  }

  .ant-checkbox-checked:hover .ant-checkbox-inner {
    background-color: ${(props) => props.theme.token.colorPrimary} !important;
    border-color: ${(props) => props.theme.token.colorPrimary} !important;
  }

  .ant-checkbox-inner {
    border-color: #d9d9d9 !important; // Keep the default border color when unchecked
  }

  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: #d9d9d9 !important; // Keep the default border color on hover when unchecked
  }
`;

const StyledCard = styled(Card)`
  width: 500px;
  margin: 0 auto;
  position: relative;
  border: None;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 16px;

  .ant-card-body {
    padding: 24px;
  }

  margin-bottom: 24px;

  &:hover {
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
    transition: box-shadow 0.3s ease;
  }
`;

const StyledContent = styled(Content)`
  position: relative;
  display: flex;
  flex-direction: column; /* Stacks children vertically */
  align-items: center; /* Centers content horizontally */
  justify-content: flex-start; /* Aligns items at the top */
  padding: 20px 0;
  background-color: white;
`;

function Onboarding() {
  const { token } = useToken();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const [grade, setGrade] = useState({ id: "", display: "" });
  const [school, setSchool] = useState({ id: "", display: "" });
  const [examBoard, setExamBoard] = useState({ id: "", display: "" });
  const [books, setBooks] = useState([]);
  const [currentBook, setCurrentBook] = useState({
    id: "",
    display: "",
    publisher: { id: "", display: "" },
  });
  const [samePublisher, setSamePublisher] = useState(false);
  const [commonPublisher, setCommonPublisher] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // State for API data
  const [grades, setGrades] = useState([]);
  const [schools, setSchools] = useState([]);
  const [boards, setBoards] = useState([]);
  const [ebooks, setEbooks] = useState([]);
  const [publishers, setPublishers] = useState([]);

  useEffect(() => {
    const fetchOnboardingData = async () => {
      try {
        const { authToken } = getAuth();
        const response = await getOnboardingData(authToken);
        console.log(response);

        setGrades(response.grades);
        setSchools(response.schools);
        setBoards(response.boards);
        setEbooks(response.ebooks);
        setPublishers(response.publishers);

        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching onboarding data:", error);
        message.error("Failed to fetch onboarding data");
        setIsLoading(false);
      }
    };

    fetchOnboardingData();
  }, []);

  // Convert API data to options format
  const gradeOptions = grades.map((grade) => ({
    value: grade.id,
    label: `Grade ${grade.name}`,
  }));
  const schoolOptions = schools.map((school) => ({
    value: school.id,
    label: school.name,
  }));
  const examBoardOptions = boards.map((board) => ({
    value: board.id,
    label: board.name,
  }));
  const publisherOptions = publishers.map((publisher) => ({
    value: publisher.id,
    label: publisher.name,
  }));

  const handleCurrentBookChange = (field, value, option) => {
    setCurrentBook((prev) => {
      if (field === "name") {
        return {
          ...prev,
          id: option.value,
          display: option.label,
        };
      } else if (field === "publisher") {
        return {
          ...prev,
          publisher: {
            id: option.value,
            display: option.label,
          },
        };
      }
      return prev;
    });
  };

  const handleAddBook = () => {
    if (currentBook.id && currentBook.publisher.id) {
      setBooks([...books, currentBook]);
      setCurrentBook({
        id: "",
        display: "",
        publisher: { id: "", display: "" },
      }); // Reset current book
    } else {
      message.error("Please select both book and publisher");
    }
  };

  const handleCommonPublisher = (value, option) => {
    setCommonPublisher({ id: option.value, display: option.label });
    const newBooks = books.map((book) => ({
      ...book,
      publisher: { id: option.value, display: option.label },
    }));
    setBooks(newBooks);
  };

  const handleGradeChange = (value, option) => {
    setGrade({ id: option.value, display: option.label });
  };

  const handleSchoolChange = (value, option) => {
    setSchool({ id: option.value, display: option.label });
  };

  const handleExamBoardChange = (value, option) => {
    setExamBoard({ id: option.value, display: option.label });
  };

  const handleRemoveBook = (index) => {
    const newBooks = books.filter((_, i) => i !== index);
    setBooks(newBooks);
  };

  const handleSubmit = async () => {
    if (
      !grade.id ||
      !school.id ||
      !examBoard.id ||
      (samePublisher && !commonPublisher.id)
    ) {
      console.log("Validation values:", {
        grade,
        school,
        examBoard,
        samePublisher,
        commonPublisher,
        books,
      });
      message.error("Please fill in all required fields.");
      return;
    }

    const payload = {
      exam_board: examBoard.id,
      grade: grade.id,
      school: school.id,
      same_book_publisher: samePublisher,
      ebook: books.map((book) => book.id),
      subject_book_publisher: samePublisher
        ? [commonPublisher.id]
        : books.map((book) => book.publisher.id),
      progress: 0,
      subscription_purchased: "trial",
      last_paid_date: "2024-11-04T00:00:00Z",
      trial_start_date: "2024-11-04T00:00:00Z",
      trial_end_date: "2024-12-04T00:00:00Z",
    };

    setIsSubmitting(true);

    try {
      const { authToken } = getAuth();
      const response = await updateOnboarding(payload, authToken);

      if (response.error) {
        throw new Error(response.error);
      }

      const userData = JSON.parse(localStorage.getItem("userData"));
      userData.is_onboarded = true;
      localStorage.setItem("userData", JSON.stringify(userData));

      message.success("You're all set! 🎉");
      navigate("/home");
    } catch (error) {
      console.error(error);
      message.error("Failed to submit onboarding information.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const steps = [
    {
      title: "School",
      content: (
        <Space direction="vertical" style={{ width: "100%" }}>
          <Text strong>Help Us Understand Your Learning Needs!</Text>
          <Text style={{ color: token.colorGrey }}>
            Answer a few quick questions to help us create a learning experience
            just right for you!
          </Text>
          <Form.Item
            label="Select Grade"
            wrapperCol={{ span: 24 }}
            style={{ marginBottom: 0 }}
          >
            <AutoComplete
              options={gradeOptions}
              value={grade.display}
              onChange={handleGradeChange}
              dropdownStyle={{ backgroundColor: "#fff" }}
              placeholder="Select Grade"
            >
              <Input
                prefix={<SearchOutlined style={{ paddingRight: "6px" }} />}
                style={{ width: "100%", backgroundColor: "#F5F7FA" }}
                size="large"
                variant="borderless"
              />
            </AutoComplete>
          </Form.Item>

          <Form.Item
            label="Select School"
            wrapperCol={{ span: 24 }}
            style={{ marginBottom: 0 }}
          >
            <AutoComplete
              options={schoolOptions}
              value={school.display}
              onChange={handleSchoolChange}
              dropdownStyle={{ backgroundColor: "#fff" }}
              placeholder="Select School"
            >
              <Input
                prefix={<SearchOutlined style={{ paddingRight: "6px" }} />}
                style={{ width: "100%", backgroundColor: "#F5F7FA" }}
                size="large"
                variant="borderless"
              />
            </AutoComplete>
          </Form.Item>

          <Form.Item
            label="Select Board"
            wrapperCol={{ span: 24 }}
            style={{ marginBottom: 0 }}
          >
            <AutoComplete
              options={examBoardOptions}
              value={examBoard.display}
              onChange={handleExamBoardChange}
              dropdownStyle={{ backgroundColor: "#fff" }}
              placeholder="Select Board"
            >
              <Input
                prefix={<SearchOutlined style={{ paddingRight: "6px" }} />}
                style={{ width: "100%", backgroundColor: "#F5F7FA" }}
                size="large"
                variant="borderless"
              />
            </AutoComplete>
          </Form.Item>
        </Space>
      ),
    },
    {
      title: "Books",
      content: (
        <Space direction="vertical" style={{ width: "100%" }}>
          <Text strong>Select books and their publisher.</Text>
          <Text>
            Choose your school books and publishers to help us match your
            resources!
          </Text>
          <Flex justify="space-between" align="center">
            <CustomCheckbox
              checked={samePublisher}
              onChange={(e) => {
                const isChecked = e.target.checked;
                setSamePublisher(isChecked);
                if (!isChecked) {
                  setCommonPublisher("");
                }
              }}
            >
              All the books have same publisher
            </CustomCheckbox>
          </Flex>
          {samePublisher ? (
            <Form.Item label="Publisher" wrapperCol={{ span: 24 }}>
              <AutoComplete
                options={publisherOptions}
                value={commonPublisher.display}
                onChange={(value, option) =>
                  handleCommonPublisher(value, option)
                }
                dropdownStyle={{ backgroundColor: "#fff" }}
              >
                <Input
                  prefix={<SearchOutlined style={{ paddingRight: "6px" }} />}
                  style={{ width: "100%", backgroundColor: "#F5F7FA" }}
                  dropdownStyle={{ backgroundColor: "#fff" }}
                  size="large"
                  placeholder="Search or Select"
                  value={commonPublisher}
                  options={publisherOptions}
                  variant="borderless"
                />
              </AutoComplete>
            </Form.Item>
          ) : null}
          <Card
            style={{
              width: "100%",
              borderStyle: "dashed",
              borderWidth: "2px",
            }}
          >
            <Space direction="vertical" size="small" style={{ width: "100%" }}>
              <Form.Item
                label="Book"
                wrapperCol={{ span: 24 }}
                style={{ marginBottom: 0 }}
              >
                <AutoComplete
                  options={ebooks.map((book) => ({
                    value: book.id,
                    label: book.name,
                  }))}
                  value={currentBook.display}
                  onChange={(value, option) =>
                    handleCurrentBookChange("name", value, option)
                  }
                  dropdownStyle={{ backgroundColor: "#fff" }}
                  placeholder="Select Book"
                >
                  <Input
                    prefix={<BookOutlined style={{ paddingRight: "6px" }} />}
                    style={{ width: "100%", backgroundColor: "#F5F7FA" }}
                    size="large"
                    variant="borderless"
                  />
                </AutoComplete>
              </Form.Item>
              {!samePublisher && (
                <Form.Item
                  label="Publisher"
                  wrapperCol={{ span: 24 }}
                  style={{ marginBottom: 0 }}
                >
                  <AutoComplete
                    options={publisherOptions}
                    value={currentBook.publisher.display}
                    dropdownStyle={{ backgroundColor: "#fff" }}
                    onChange={(value, option) =>
                      handleCurrentBookChange("publisher", value, option)
                    }
                  >
                    <Input
                      prefix={
                        <SearchOutlined style={{ paddingRight: "6px" }} />
                      }
                      style={{ width: "100%", backgroundColor: "#F5F7FA" }}
                      dropdownStyle={{ backgroundColor: "#fff" }}
                      size="large"
                      placeholder="Search or Select"
                      value={currentBook.publisher.display}
                      variant="borderless"
                    />
                  </AutoComplete>
                </Form.Item>
              )}
              <Space direction="horizontal" style={{ marginTop: "8px" }}>
                {/* Add Book Button */}
                <Button
                  type="text"
                  onClick={handleAddBook}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    backgroundColor: "transparent",
                    border: "none",
                    padding: 0,
                    cursor: "pointer",
                  }}
                >
                  <div
                    style={{
                      width: "14px", // Smaller button size
                      height: "14px", // Smaller button size
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "50%",
                      backgroundColor: "#8BC34A",
                      color: "#ffffff",
                      fontSize: "7px", // Smaller icon size
                    }}
                  >
                    <PlusOutlined />
                  </div>
                  <span
                    style={{
                      fontSize: "14px",
                      color: "#000000",
                      marginRight: 12,
                    }}
                  >
                    Add Book
                  </span>
                </Button>
              </Space>
            </Space>
          </Card>

          {/* Books Added Section */}
          {books.length > 0 && (
            <div style={{ marginTop: "4px" }}>
              <Space direction="vertical" style={{ width: "100%" }}>
                <Text>Books Added</Text>
                <Row gutter={[16, 16]}>
                  {books.map((book, index) => (
                    <Col xs={24} sm={12} key={index}>
                      <Card
                        style={{
                          backgroundColor: "#F5F7FA",
                          padding: "8px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          height: "100%",
                        }}
                        bodyStyle={{ padding: 0 }}
                      >
                        <Flex
                          align="center"
                          justify="space-between"
                          style={{ width: "100%", height: "100%" }}
                        >
                          {/* Icon Section */}
                          <div
                            style={{
                              width: "50px",
                              height: "50px",
                              backgroundColor: "#FF8968",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "8px",
                              flexShrink: 0,
                            }}
                          >
                            <BookOutlined
                              style={{ fontSize: "24px", color: "#FFFFFF" }}
                            />
                          </div>

                          {/* Text Section */}
                          <div style={{ flex: 1, marginLeft: "16px" }}>
                            <Typography.Text
                              strong
                              style={{ display: "block", fontSize: "16px" }}
                            >
                              {book.display}
                            </Typography.Text>
                            <Typography.Text
                              type="secondary"
                              style={{ fontSize: "12px" }}
                            >
                              {samePublisher
                                ? commonPublisher.display
                                : book.publisher.display}
                            </Typography.Text>
                          </div>

                          {/* Remove Button */}
                          <div
                            style={{
                              width: "16px",
                              height: "16px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              margin: "8px",
                              borderRadius: "50%",
                              backgroundColor: "#C34244",
                              color: "#ffffff",
                              cursor: "pointer",
                              fontSize: "8px",
                            }}
                            onClick={() => handleRemoveBook(index)}
                          >
                            <MinusOutlined />
                          </div>
                        </Flex>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Space>
            </div>
          )}
        </Space>
      ),
    },
  ];

  if (isLoading) {
    return (
      <Layout style={{ height: "100vh", backgroundColor: "white" }}>
        <StyledContent>
          <Flex
            vertical
            align="center"
            justify="center"
            style={{ height: "100%" }}
          >
            <Spin size="large" />
            <Text style={{ marginTop: 16 }}>Loading...</Text>
          </Flex>
        </StyledContent>
      </Layout>
    );
  }

  return (
    <Layout
      xs={24}
      sm={24}
      style={{ minHeight: "100vh", backgroundColor: token.colorBgContainer }}
    >
      <StyledContent>
        <Logo src={ASSETS.logo} alt="Logo" />
        <StyledCard theme={{ token }}>
          <Steps steps={steps} currentStep={currentStep} />

          {steps[currentStep].content}

          <Flex justify="space-between" style={{ marginTop: 24 }}>
            {currentStep > 0 && (
              <Button
                size="large"
                onClick={() => setCurrentStep(currentStep - 1)}
                style={{
                  width: "120px",
                  backgroundColor: "#F0F2F2",
                  color: "#FF6C42",
                  border: "none",
                  outline: "none",
                  fontSize: "12px",
                }}
                onMouseOver={(e) => (e.target.style.border = "none")}
                onFocus={(e) => (e.target.style.border = "none")}
              >
                Back
              </Button>
            )}
            <Button
              type="primary"
              size="large"
              onClick={() => {
                if (currentStep < steps.length - 1) {
                  setCurrentStep(currentStep + 1);
                } else {
                  handleSubmit();
                }
              }}
              loading={currentStep === steps.length - 1 && isSubmitting}
              style={{
                marginLeft: "auto",
                width: currentStep === 0 ? "100%" : "45%",
                backgroundColor: "#FF6C42",
                color: "#ffffff",
                border: "none",
                outline: "none",
                fontSize: "12px",
              }}
              onMouseOver={(e) => (e.target.style.border = "none")}
              onFocus={(e) => (e.target.style.border = "none")}
            >
              {currentStep < steps.length - 1
                ? "Next"
                : "Continue to Dashboard"}
            </Button>
          </Flex>
        </StyledCard>
      </StyledContent>
    </Layout>
  );
}

export default Onboarding;
