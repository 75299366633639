import React from "react";
import { Layout, Grid } from "antd";
import { BooksList, DashboardSider, DashboardReports } from "../dashboard";

const { Content } = Layout;
const { useBreakpoint } = Grid;

function Dashboard() {
  const screens = useBreakpoint();

  return (
    <Layout
      style={{
        padding: "24px",
        height: "100%",
        width: "100%",
      }}
    >
      <Content>
        <BooksList />
        <DashboardReports />
      </Content>

      {screens.lg && <DashboardSider />}
    </Layout>
  );
}

export default Dashboard;
