import React from "react";
import { Button, Row, Col, theme } from "antd";
import styled from "styled-components";

const StyledSection = styled.section`
  background-color: ${(props) => props.theme.token.colorPrimary};
  position: relative;
  text-align: center;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 286px;
  !important;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("images/card-section-background.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.1;
    z-index: 1;
  }

  @media (max-width: 768px) {
    height: auto;
    padding: 40px 10px;
    flex-direction: column; /* Stack items vertically */
  }
`;

const ContentWrapper = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content horizontally */
  padding: 48px;

  @media (max-width: 768px) {
    text-align: center;
    padding: 24px;
  }
`;

const Title = styled.h1`
  color: white;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 16px;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 28px;
    margin-bottom: 12px;
    text-align: center; /* Center text */
  }
`;

const Subtitle = styled.p`
  color: white;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 0;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 16px;
    text-align: center; /* Center text */
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;

  @media (min-width: 768px) {
    justify-content: center;
  }

  @media (max-width: 768px) {
    justify-content: center; /* Center the buttons */
    flex-direction: row; /* Arrange buttons side by side */
    gap: 15px;
    width: 100%;
    margin-top: 20px;
  }
`;

const StyledButton = styled(Button)`
  background-color: ${(props) => props.theme.token.borderColor};
  color: ${(props) => props.theme.token.colorPrimary};
  border: 2px solid ${(props) => props.theme.token.borderColor};
  font-weight: 400;
  font-size: 18px;
  font-family: "Gilroy-Bold";
  height: 50px;
  padding: 30px 50px;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-radius: 12px;

  &:hover {
    background-color: ${(props) => props.theme.token.borderColor};
    color: white;
  }

  @media (max-width: 768px) {
    padding: 15px 30px;
    font-size: 16px;
    width: 100%; /* Full width on smaller screens */
  }
`;

const TransparentButton = styled(Button)`
  background-color: transparent;
  border: 2px solid ${(props) => props.theme.token.borderColor};
  color: white;
  font-weight: 400;
  font-size: 18px;
  font-family: "Gilroy-Bold";
  height: 50px;
  padding: 30px 50px;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-radius: 12px;

  &:hover {
    background-color: ${(props) => props.theme.token.borderColor} !important;
    color: white;
  }

  @media (max-width: 768px) {
    padding: 15px 30px;
    font-size: 16px;
    width: 100%; /* Full width on smaller screens */
  }
`;

const StyledDiv = styled.div`
  display: 'flex'; 
  flexDirection: 'column'; 
  justifyContent: 'center'; 
  alignItems: 'flex-end';
`;

const LandingCardSection = () => {

  return (
    <StyledSection>
      <ContentWrapper>
        <Row justify="center" align="middle" gutter={[16, 16]}>
          <Col xs={24} md={12}>
            <StyledDiv>
            <Title style={{fontSize: '48px', fontFamily: 'Gilroy-Bold', fontWeight: '400px', margin: 0, marginBottom: 8}}>Start your journey today and see the difference!</Title>
            <Subtitle style={{fontSize: '18px', fontFamily: 'Gilroy-Medium', fontWeight: '400px', margin: 0}}>
              The first step to unlocking your potential is right here.
            </Subtitle>
            </StyledDiv>
          </Col>
          <Col xs={24} md={12}>
            <ButtonContainer>
              <StyledButton type="default" href="/signup">
                Sign up for free
              </StyledButton>
              <TransparentButton type="default">
                Let&#39;s Talk
              </TransparentButton>
            </ButtonContainer>
          </Col>
        </Row>
      </ContentWrapper>
    </StyledSection>
  );
};

export default LandingCardSection;
