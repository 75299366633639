import React, { useState } from "react";
import { Typography, Space, Divider, theme } from "antd";
import { AppDropdown } from "../../../components";
const { Text } = Typography;
const { useToken } = theme;

const PreferLanguage = () => {
  const { token } = useToken();
  const languageOptions = [
    { label: "English", value: "English" },
    { label: "Hindi", value: "Hindi" },
    { label: "Marathi", value: "Marathi" },
  ];
  const [selectedLanguage, setSelectedLanguage] = useState(
    languageOptions[0].value
  );

  const handleLanguageChange = (value) => {
    setSelectedLanguage(value);
  };

  return (
    <div id="prefer-language">
      <Text
        style={{
          fontSize: "15px",
          fontWeight: "500",
          marginBottom: "16px",
          display: "block",
        }}
      >
        Prefer Language
      </Text>
      <Space direction="vertical" style={{ width: "30%" }}>
        <Text>Select Language</Text>
        <AppDropdown
          width={"100%"}
          size="large"
          backgroundColor={token.colorBgLayout}
          options={languageOptions}
          value={selectedLanguage}
          onChange={handleLanguageChange}
          placeholder="Select Language"
          prefix={null}
        />
      </Space>
      <Divider
        style={{
          borderColor: token.colorPrimary,
          marginTop: "36px",
          marginBottom: "24px",
        }}
      />
    </div>
  );
};

export default PreferLanguage;
