import React, { useState, useEffect, useRef } from "react";
import { Flex, Button, Form, Input, Typography, theme } from "antd";
import { Link } from "react-router-dom";
import { AppLogo } from "../../../components";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const { Title, Text } = Typography;
const { useToken } = theme;

const TitleText = styled(Text)`
  font-size: 30px;
  font-weight: 700;
  font-family: "DM-Sans", sans-serif;
  margin: 0;
`;

const SubText = styled(Text)`
  font-size: 14px;
  font-weight: 400;
  font-family: "DM-Sans", sans-serif;
  margin: 0;
  width: 80%;
  color: #757F8E;
`;

const TitleContainer = styled.div`
  text-align: center;
  gap: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CardContainer = styled.div`
  background-color: #fff;
  width: 100%;
  max-width: 567px;
  border-radius: 16px;
  padding: 20px;
  gap: 32px;
  box-shadow: 0px 25px 49.7px 0px #0000000D;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 20px;
  }
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const StyledInput = styled(Input)`
  border-radius: 8px;
  height: 48px;
  width: 100%;
  padding: 12px 16px;
  background-color: ${(props) => props.theme.token.colorBgLayout};
  border: none !important;

  &:hover {
    background-color: ${(props) => props.theme.token.colorBgLayout} !important;
    border: none !important;
  }

  &:focus {
    background-color: ${(props) => props.theme.token.colorBgLayout} !important;
    border: none !important;
    box-shadow: none; /* Remove focus outline */
  }

  &:active {
    background-color: ${(props) => props.theme.token.colorBgLayout} !important;
    border: none !important;
  }
`;


const StyledButton = styled(Button)`
  background-color: ${(props) => props.theme.token.colorPrimary};
  color: ${(props) => props.theme.token.colorWhite};
  border: none;
  font-weight: 600;
  font-size: 14px;
  font-family: "DM-Sans", sans-serif;
  border-radius: 10px;
  padding: 16px;
  width: 100%;
  gap: 10px;
  height: 50px;
`;

const LinkText = styled(Text)`
  color: black;
  font-weight: 400;
  font-size: 14px;
  font-family: "DM-Sans", sans-serif;
  cursor: pointer;
  text-decoration: underline;
`;

export default function ForgotPassword() {
  const [form] = Form.useForm();
  const [step, setStep] = useState("email"); // "email" or "otp" step
  const [timer, setTimer] = useState(120); // Countdown timer starting from 120 seconds
  const [timerActive, setTimerActive] = useState(false); // To control the timer state
  const otpInputRef = useRef(null); // Reference to the OTP input field

  const { token } = useToken();
  const  navigate = useNavigate();

  useEffect(() => {
    let interval;
    if (timerActive && timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      setTimerActive(false); // Stop the timer when it reaches 0
    }
    return () => clearInterval(interval); // Clean up interval on component unmount
  }, [timerActive, timer]);

  useEffect(() => {
    if (step === "otp") {
      otpInputRef.current?.focus(); // Focus OTP input field when we switch to OTP step
    }
  }, [step]);

  const onFinishEmail = (values) => {
    console.log("Received email values:", values);
    setStep("otp"); // Move to the OTP step
    setTimer(10); // Reset the timer to 2 minutes
    setTimerActive(true); // Start the countdown
  };

  const onFinishOTP = (values) => {
    console.log("Received OTP values:", values);
    // Handle OTP verification logic here

    // Redirect to the new password page
    navigate("/reset-password");
  };

  const handleResendOTP = () => {
    setTimer(10); // Reset the timer to 2 minutes
    setTimerActive(true); // Start the countdown
    setStep("otp"); // Ensure OTP step is displayed
    // Reset form validation state if any errors are present
    form.resetFields(["otp"]); // Reset OTP field to clear any validation error
  };

  const handleGoBack = () => {
    form.resetFields();
    setStep("email"); // Reset to email step
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };

  return (
    <SectionContainer>
      <LogoContainer
      style={{cursor: 'pointer'}}
      onClick={() => navigate("/")}>
        <AppLogo height="70px" />
      </LogoContainer>
      <CardContainer>
          {step === "email" ? (
            <>
              <TitleContainer>
                <TitleText>
                  Forgot Your Password?
                </TitleText>
                <SubText>
                  Please enter your email address or phone number to receive a password reset link.
                </SubText>
              </TitleContainer>

              <Form
                form={form}
                name="reset_password"
                onFinish={onFinishEmail}
                layout="vertical"
                style={{ width: "100%" }}
                requiredMark={false}
              >
                <Form.Item
                  name="email"
                  label="Email Address or Phone Number"
                  rules={[
                    { required: true, message: "Please input your email!" },
                    {
                      type: "email",
                      message: "Please enter a valid email address!",
                    },
                  ]}
                >
                  <StyledInput placeholder="eg: email@dummy.com"/>
                </Form.Item>

                <Form.Item>
                  <StyledButton htmlType="submit" type="primary">
                    Open Inbox
                  </StyledButton>
                </Form.Item>
              </Form>
            </>
          ) : (
            <>
              <TitleContainer>
                <TitleText>
                  Verify Reset Code
                </TitleText>
                <SubText>
                  Enter the code sent to your email or phone number to proceed with resetting your password.
                </SubText>
              </TitleContainer>

              <Form
                form={form}
                name="otp_form"
                onFinish={onFinishOTP}
                style={{ width: "100%"}}
              >
                <Form.Item
                  name="otp"
                  rules={[
                    { required: true, message: "Please input your OTP!" },
                    {
                      len: 5,
                      message: "OTP must be 5 digits!",
                    },
                  ]}
                  style={{ width: "100%", marginBottom: 0 }}
                >
                  <Input.OTP
                    ref={otpInputRef}
                    length={5}
                    style={{
                      width: "100%",
                    }}
                    size="large"
                    variant="filled"
                    formatter={(str) => str.toUpperCase()}
                  />
                </Form.Item>

                <Flex justify="flex-end" style={{ marginBottom: "24px", marginTop: "8px" }}>
                {timer > 0 ? (
                  <Text style={{ fontSize: "14px", color: "#5A5A5A" }}>
                    Resend code in {formatTime(timer)}
                  </Text>
                ) : (
                    <Link onClick={handleResendOTP}>
                      <LinkText>
                        Resend Code
                      </LinkText>
                    </Link>
                )}
              </Flex>

                <Form.Item>
                  <StyledButton
                    type="primary"
                    htmlType="submit"
                  >
                    Add New Password
                  </StyledButton>
                </Form.Item>
              </Form>
            </>
          )}
      </CardContainer>
    </SectionContainer>
  );
}
