import React from "react";
import { AutoComplete, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

const AppAutoComplete = ({
  options,
  value,
  onChange,
  placeholder = "Search or Select",
  prefix = <SearchOutlined style={{ paddingRight: "6px" }} />,
}) => {
  // Find the matching option's label for display
  const displayValue =
    options.find((opt) => opt.value === value)?.label || value || "";

  return (
    <AutoComplete
      options={options}
      value={displayValue}
      onChange={(value, option) => onChange(value, option)}
      dropdownStyle={{ backgroundColor: "#fff" }}
      style={{ width: "100%" }}
    >
      <Input
        prefix={prefix}
        style={{
          width: "100%",
          backgroundColor: "#F5F7FA",
        }}
        size="large"
        placeholder={placeholder}
      />
    </AutoComplete>
  );
};

export default AppAutoComplete;
