import React, { useRef } from "react";
import styled from "styled-components";
import BlueShadow from "../../components/blue-shadow";
import LandingHeader from "../../components/landing-header";
import LandingContent from "../../components/landing-content";
import LandingCardSection from "../../components/landing-card-section";
import LandingFooter from "../../components/landing-footer";
import CoveredSection from "../../components/covered-section/covered-section";
import CounterSection from "../../components/counter-section";
import BenefitsSection from "../../components/benefits-section";
import AiWorksSection from "../../components/ai-works-section";
import RecommendedSection from "../../components/recommended-section";
import TestimonialSection from "../../components/testimonial-section";
import PricingSection from "../../components/pricing-section";

const PageContainer = styled.div`
  position: relative;
  overflow: hidden;
`;

const LandingPage = () => {
  const landingContentRef = useRef(null);
  const aiWorkSectionRef = useRef(null);
  const testimonialSectionRef = useRef(null);

  const sections = [
    { id: "Home", ref: landingContentRef },
    { id: "Features", ref: aiWorkSectionRef },
    { id: "Testimonials", ref: testimonialSectionRef },
  ];

  return (
    <PageContainer>
      <BlueShadow left="20%" bottom="35%" />
      <BlueShadow left="5%" bottom="1%" />
      <BlueShadow left="5%" bottom="5%" />
      <LandingHeader sections={sections} />
      <div ref={landingContentRef}>
        <LandingContent />
      </div>
      <CoveredSection />
      <CounterSection />
      <div ref={aiWorkSectionRef}>
        <AiWorksSection />
      </div>
      <BenefitsSection />
      {/* <PricingSection /> */}
      <div ref={testimonialSectionRef}>
        <TestimonialSection />
      </div>
      {/* <RecommendedSection /> */}
      <LandingCardSection />
      <LandingFooter />
    </PageContainer>
  );
};

export default LandingPage;
