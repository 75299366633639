import React, { useState, useRef } from "react";
import { Typography, Button, Collapse, theme } from "antd";
import styled from "styled-components";
import { ASSETS } from "../constants";
import AnalyticSection from "./ai-works-sections/analytics-section";
import LanguageSection from "./ai-works-sections/language-section";
import PracticeSection from "./ai-works-sections/practice-section";
import TalkSection from "./ai-works-sections/talk-section";

const { Title, Text } = Typography;
const { useToken } = theme;

const SectionContainer = styled.div`
  padding: 40px 120px;
  margin-top: 40px;
  
  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const HeaderSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 48px;

  @media (max-width: 768px) {
    margin-bottom: 24px;
  }
`;

const WorksText = styled.span`
  position: relative;
  display: inline-block;
  margin-left: 8px;

  &::before {
    content: "";
    position: absolute;
    top: -20px;
    left: -85px;
    width: 34px;
    height: 34px;
    background-image: url("images/two-stars.svg");
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 1;
  }

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 55%;
    transform: translate(-50%, -50%);
    width: 130%;
    height: 130%;
    background-image: url("images/works-circle.svg");
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -1;
  }
`;

const TabCard = styled.div`
  position: relative;
  background: transparent !important;
  border: none;
  padding: 8px;
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 24px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  cursor: grab;

  /* Hide scrollbar */
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &.dragging {
    cursor: grabbing;
  }
`;

const TabButton = styled(Button)`
  padding: 20px 30px;
  gap: 10px;
  border-radius: 38px;
  border: 1px solid ${(props) => props.theme.token.borderColorGrey};
  font-size: 20px;
  font-weight: 400;
  font-family: "Gilroy-Regular";
  flex: 1;
  line-height: 24.5px;
  white-space: nowrap;
  transition: none;
  background: transparent !important;
  background-color: transparent !important;

  &.active {
    background-color: ${(props) => props.theme.token.colorPrimary} !important;
    color: ${(props) => props.theme.token.colorWhite} !important;
    font-family: "Gilroy-SemiBold";
  }

  &:not(.active) {
    background: transparent !important;
    background-color: transparent !important;
    border: 1px solid ${(props) => props.theme.token.colorLightGrey2};
    color: ${(props) => props.theme.token.logoBlue};

    &:hover {
      outline: 2px solid ${(props) => props.theme.token.colorPrimary};
      outline-offset: -2px;
    }
  }
`;

const AIWorksSection = () => {
  const { token } = useToken();
  const [activeTab, setActiveTab] = useState("talk");
  const tabCardRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  // Handle mouse down event to start dragging
  const handleMouseDown = (e) => {
    const card = tabCardRef.current;
    setIsDragging(true);
    setStartX(e.pageX - card.offsetLeft);
    setScrollLeft(card.scrollLeft);
    card.classList.add("dragging");
  };

  // Handle mouse move event to perform the drag
  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const card = tabCardRef.current;
    const x = e.pageX - card.offsetLeft;
    const walk = (x - startX) * 1; // Adjust the multiplier to change scroll speed
    card.scrollLeft = scrollLeft - walk;
  };

  // Handle mouse up event to stop dragging
  const handleMouseUp = () => {
    setIsDragging(false);
    tabCardRef.current.classList.remove("dragging");
  };

  // Handle mouse leave event if the mouse leaves the card while dragging
  const handleMouseLeave = () => {
    if (isDragging) handleMouseUp();
  };

  const tabs = [
    { key: "talk", label: "Talk to your textbooks", graphic: ASSETS.talkToTextbooks },
    { key: "practice", label: "Practice makes you Performer", graphic: ASSETS.practiceMakesPerformer },
    { key: "analytics", label: "Real time analytics", graphic: ASSETS.realTimeAnalytics },
    { key: "language", label: "Practice a foreign language", graphic: ASSETS.practiceForeignLanguage },
  ];

  return (
    <SectionContainer>
      <HeaderSection>
        <Title
          style={{
            fontSize: "48px",
            fontWeight: 400,
            fontFamily: "Gilroy-Bold",
            marginBottom: "16px",
            ...(window.innerWidth <= 768 && { fontSize: "36px" }),
          }}
        >
          AI <WorksText>Magic</WorksText>
        </Title>
        <Text
          style={{
            fontSize: "18px",
            fontWeight: 400,
            fontFamily: "Gilroy-Regular",
            color: token.colorDarkGrey,
          }}
        >
          Stay ahead this session
        </Text>
      </HeaderSection>

      <TabCard
        theme={{ token }}
        ref={tabCardRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseLeave}
      >
        {tabs.map((tab) => (
          <TabButton
            key={tab.key}
            className={activeTab === tab.key ? "active" : ""}
            onClick={() => setActiveTab(tab.key)}
            theme={{ token }}
          >
            {tab.label}
          </TabButton>
        ))}
      </TabCard>

      {activeTab === "talk" && <TalkSection />}
      {activeTab === "practice" && <PracticeSection />}
      {activeTab === "analytics" && <AnalyticSection />}
      {activeTab === "language" && <LanguageSection />}
    </SectionContainer>
  );
};

export default AIWorksSection;
