import React from "react";
import { Flex, Typography, theme } from "antd";
import {
  AppDropdown,
  StatTextRow,
  HoursActivityBarChart,
} from "../../components";

const { Text } = Typography;
const { useToken } = theme;

const HoursActivityChartCard = ({ timeframeOptions, subjectOptions }) => {
  const { token } = useToken();
  return (
    <Flex
      vertical
      style={{
        flex: "0 0 50%",
        backgroundColor: token.colorWhite,
        padding: 16,
        borderRadius: 20,
        marginRight: 8,
        maxWidth: "50%",
        height: '343px',
      }}
    >
      <Flex justify="space-between" align="center" style={{ marginBottom: 4, gap: 0 }}>
        <Text strong style={{ fontSize: "16px", fontFamily: "Inter-Tight", fontWeight: 600 }}>
          Hours Activity
        </Text>
        <Flex gap={8}>
          <AppDropdown
            defaultValue="weekly"
            options={timeframeOptions}
            width={120}
          />
          <AppDropdown
            defaultValue="all"
            options={subjectOptions}
            width={140}
          />
        </Flex>
      </Flex>

      <Flex style={{ marginBottom: 10 }}>
        <StatTextRow
          value="5"
          description="Increase since last week"
          isIncrease={true}
        />
      </Flex>

      <div
        style={{
          flex: 1,
          backgroundColor: token.colorWhite,
        }}
      >
        <HoursActivityBarChart />
      </div>
    </Flex>
  );
};

export default HoursActivityChartCard;
