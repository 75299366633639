import { Flex, Typography, Divider, theme } from "antd";
import QuizSettingsIcon from "./quiz-settings-icon";
import { PlayCircleOutlined } from "@ant-design/icons";
import { ASSETS } from "../../constants";
const { Text } = Typography;
const { useToken } = theme;

function QuizInfoStickyBar({
  questionsAnswered,
  totalQuestions,
  timeLeft,
  onPauseResume,
  onRestart,
  onStop,
  isPaused,
}) {
  const { token } = useToken();

  return (
    <Flex
      align="center"
      justify="space-between"
      style={{
        padding: "12px 24px",
        backgroundColor: token.colorWhite,
        borderBottom: token.border,
        height: "64px",
      }}
    >
      <Flex justify="space-around" style={{ width: "100%" }}>
        <Text
          strong
          style={{
            color: token.logoBlue,
            fontFamily: "Gilroy-Regular",
            fontSize: "14px",
          }}
        >
          Progress
        </Text>
        <Text
          style={{
            color: token.logoBlue,
            fontFamily: "Gilroy-Bold",
            fontSize: "16px",
          }}
        >{`${questionsAnswered}/${totalQuestions}`}</Text>
      </Flex>

      <Divider type="vertical" style={{ height: "40px" }} />

      <Flex justify="space-around" style={{ width: "100%" }}>
        <Text
          strong
          style={{
            color: token.logoBlue,
            fontFamily: "Gilroy-Regular",
            fontSize: "14px",
          }}
        >
          Time
        </Text>
        <Text
          style={{
            color: token.logoBlue,
            fontFamily: "Gilroy-Bold",
            fontSize: "16px",
          }}
        >
          {timeLeft}
        </Text>
      </Flex>

      <Divider type="vertical" style={{ height: "40px" }} />

      <Flex gap={8}>
        <QuizSettingsIcon
          icon={
            isPaused ? (
              <img src={ASSETS.pauseQuizIcon} alt="pause" />
            ) : (
              <PlayCircleOutlined />
            )
          }
          tooltipTitle={isPaused ? "Resume Quiz" : "Pause Quiz"}
          onClick={onPauseResume}
          border={token.border}
        />
        <QuizSettingsIcon
          icon={<img src={ASSETS.restartQuizIcon} alt="restart" />}
          tooltipTitle={"Restart Quiz"}
          onClick={onRestart}
          border={token.border}
        />
        <QuizSettingsIcon
          icon={<img src={ASSETS.cancelQuizIcon} alt="stop" />}
          tooltipTitle={"Stop Quiz"}
          onClick={onStop}
          border={token.border}
        />
      </Flex>
    </Flex>
  );
}

export default QuizInfoStickyBar;
