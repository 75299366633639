import React, { useState, useEffect } from "react";
import {
  Space,
  Card,
  Typography,
  Form,
  Row,
  Col,
  DatePicker,
  Button,
  Flex,
  Divider,
  message,
  Input,
} from "antd";
import { PlusOutlined, MinusOutlined, BookOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { AppAutoComplete, AddBookModal } from "../../../components";
import { getOnboardingData, getAcademicSettings } from "../../../services";
import { getAuth } from "../../../utils";
import { theme } from "antd";

const { Text } = Typography;
const { useToken } = theme;

const AcademicSettings = ({ form }) => {
  const { token } = useToken();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [grades, setGrades] = useState([]);
  const [schools, setSchools] = useState([]);
  const [boards, setBoards] = useState([]);
  // ebooks are the books from the onboarding data and books are user's added books
  const [ebooks, setEbooks] = useState([]);
  const [books, setBooks] = useState([]);
  const [publishers, setPublishers] = useState([]);
  const { authToken } = getAuth();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [onboardingData, academicData] = await Promise.all([
          getOnboardingData(authToken),
          getAcademicSettings(authToken),
        ]);

        setGrades(onboardingData.grades);
        setSchools(onboardingData.schools);
        setBoards(onboardingData.boards);
        setPublishers(onboardingData.publishers);
        setEbooks(onboardingData.ebooks);

        // Set form values for submission
        form.setFieldsValue({
          grade: academicData.grade?.id,
          school: academicData.school?.id,
          board: academicData.board?.id,
          examDate: academicData.exam_date
            ? dayjs(academicData.exam_date)
            : undefined,
          books: academicData.books.map((book) => book.id),
        });

        // Set books and update form
        const formattedBooks = academicData.books.map((book) => ({
          id: book.id,
          name: book.title,
          publisher: {
            id: "",
            display: book.subject,
          },
        }));

        setBooks(formattedBooks);
        form.setFieldsValue({
          books: formattedBooks.map((book) => book.id),
        });
      } catch (error) {
        console.error("Error fetching data:", error);
        message.error("Failed to load academic settings");
      }
    };

    fetchData();
  }, [authToken, form]);

  // Convert API data to options format
  const gradeOptions = grades.map((grade) => ({
    value: grade.id,
    label: `Grade ${grade.name}`,
  }));
  const schoolOptions = schools.map((school) => ({
    value: school.id,
    label: school.name,
  }));
  const boardOptions = boards.map((board) => ({
    value: board.id,
    label: board.name,
  }));
  // Convert API data to options format (add this after the useEffect)
  const ebookOptions = ebooks.map((book) => ({
    value: book.id,
    label: book.name,
  }));
  const publisherOptions = publishers.map((publisher) => ({
    value: publisher.id,
    label: publisher.name,
  }));

  const handleGradeChange = (value, option) => {
    form.setFieldsValue({ grade: option.value });
  };

  const handleSchoolChange = (value, option) => {
    form.setFieldsValue({ school: option.value });
  };

  const handleBoardChange = (value, option) => {
    form.setFieldsValue({ board: option.value });
  };

  const handleRemoveBook = (index) => {
    const newBooks = [...books];
    newBooks.splice(index, 1);
    setBooks(newBooks);
    form.setFieldsValue({
      books: newBooks.map((book) => book.id),
    });
  };

  const handleModalSave = (newBooks) => {
    setBooks(newBooks);
    form.setFieldsValue({
      books: newBooks.map((book) => book.id),
    });
    setIsModalVisible(false);
  };

  return (
    <div id="academic-settings">
      <Text
        style={{
          fontSize: "15px",
          fontWeight: "500",
          marginBottom: "16px",
          display: "block",
        }}
      >
        Academic Settings
      </Text>

      <Form form={form} layout="vertical" style={{ maxWidth: "100%" }}>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item name="books" hidden>
              <Input />
            </Form.Item>
            <Form.Item
              label="Grade"
              name="grade"
              rules={[{ required: true, message: "Please select your grade!" }]}
            >
              <AppAutoComplete
                options={gradeOptions}
                value={form.getFieldValue("grade")}
                onChange={handleGradeChange}
                placeholder="Select Grade"
                dropdownStyle={{ backgroundColor: "#fff" }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="School"
              name="school"
              rules={[
                { required: true, message: "Please select your school!" },
              ]}
            >
              <AppAutoComplete
                options={schoolOptions}
                value={form.getFieldValue("school")}
                onChange={handleSchoolChange}
                placeholder="Select School"
                dropdownStyle={{ backgroundColor: "#fff" }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              label="Board"
              name="board"
              rules={[{ required: true, message: "Please select your board!" }]}
            >
              <AppAutoComplete
                options={boardOptions}
                value={form.getFieldValue("board")}
                onChange={handleBoardChange}
                placeholder="Select Board"
                dropdownStyle={{ backgroundColor: "#fff" }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Exam Date" name="examDate">
              <DatePicker
                style={{ width: "100%", backgroundColor: "#F5F7FA" }}
                size="large"
                format="DD/MM/YYYY"
                value={form.getFieldValue("examDate")}
              />
            </Form.Item>
          </Col>
        </Row>

        {/* Books Section */}
        <div>
          {/* Books Added Section */}
          {books.length > 0 && (
            <div>
              <Space direction="vertical" style={{ width: "100%" }}>
                <Text
                  style={{
                    fontSize: "14px",
                    marginBottom: "12px",
                    display: "block",
                  }}
                >
                  Books Added
                </Text>
                <Row gutter={[16, 16]}>
                  {books.map((book, index) => (
                    <Col xs={24} sm={12} key={index}>
                      <Card
                        style={{ backgroundColor: "#F5F7FA", padding: "8px" }}
                        bodyStyle={{ padding: 0 }}
                      >
                        <Flex
                          align="center"
                          justify="space-between"
                          style={{ width: "100%" }}
                        >
                          <div
                            style={{
                              width: "50px",
                              height: "50px",
                              backgroundColor: "#FF8968",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "8px",
                              flexShrink: 0,
                            }}
                          >
                            <BookOutlined
                              style={{ fontSize: "24px", color: "#FFFFFF" }}
                            />
                          </div>

                          <div style={{ flex: 1, marginLeft: "16px" }}>
                            <Typography.Text
                              strong
                              style={{ display: "block", fontSize: "16px" }}
                            >
                              {book.name}
                            </Typography.Text>
                            <Typography.Text
                              type="secondary"
                              style={{ fontSize: "12px" }}
                            >
                              {book.publisher.display}
                            </Typography.Text>
                          </div>

                          <div
                            style={{
                              width: "16px",
                              height: "16px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              margin: "8px",
                              borderRadius: "50%",
                              backgroundColor: "#C34244",
                              color: "#ffffff",
                              cursor: "pointer",
                              fontSize: "8px",
                            }}
                            onClick={() => handleRemoveBook(index)}
                          >
                            <MinusOutlined />
                          </div>
                        </Flex>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Space>
            </div>
          )}
        </div>

        <Form.Item
          style={{
            marginTop: "24px",
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            type="text"
            onClick={() => setIsModalVisible(true)}
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              backgroundColor: "transparent",
              border: "none",
              padding: 0,
              cursor: "pointer",
            }}
          >
            <div
              style={{
                width: "20px",
                height: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "50%",
                backgroundColor: "#8BC34A",
                color: "#ffffff",
                fontSize: "10px",
              }}
            >
              <PlusOutlined />
            </div>
            <span style={{ fontSize: "14px", color: "#000000" }}>
              Add Another Book
            </span>
          </Button>
        </Form.Item>
      </Form>

      <Divider
        style={{ borderColor: token.colorPrimary, marginBottom: "24px" }}
      />

      <AddBookModal
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onSave={handleModalSave}
        books={books}
        publisherOptions={publisherOptions}
        ebookOptions={ebookOptions}
      />
    </div>
  );
};

export default AcademicSettings;
