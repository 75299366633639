import React, { createContext, useState, useContext, useEffect } from "react";
import { getBooksList } from "../services";

const BooksContext = createContext();

export const BooksProvider = ({ children }) => {
  const [bookList, setBookList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBooks = async () => {
      try {
        // First try to get from localStorage
        const storedBooks = JSON.parse(localStorage.getItem("booksList"));
        if (storedBooks) {
          setBookList(storedBooks);
          setLoading(false);
          return;
        }

        // If not in localStorage, fetch from API
        const booksData = await getBooksList();
        setBookList(booksData.ebooks);
        setLoading(false);
      } catch (error) {
        console.error("Error loading books:", error);
        setLoading(false);
      }
    };

    fetchBooks();
  }, []);

  return (
    <BooksContext.Provider value={{ bookList, setBookList, loading }}>
      {children}
    </BooksContext.Provider>
  );
};

export const useBooks = () => {
  const context = useContext(BooksContext);
  if (!context) {
    throw new Error("useBooks must be used within a BooksProvider");
  }
  return context;
};
