import React from "react";
import { Card, Flex, theme } from "antd";

const { useToken } = theme;

function QuizInfoCard({ children }) {
  const { token } = useToken();

  const cardContent = (
    <Card
      style={{
        borderRadius: "16px",
        border: token.border,
        borderColor: token.borderColor,
        height: "100%",
      }}
    >
      <Flex justify="space-between">{children}</Flex>
    </Card>
  );

  return cardContent;
}

export default QuizInfoCard;
