const LogoutDrawerIcon = ({ color }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.75 2.25L10.2469 2.42538C12.181 3.108 13.148 3.44931 13.699 4.22807C14.25 5.00682 14.25 6.03232 14.25 8.08335V9.91665C14.25 11.9677 14.25 12.9932 13.699 13.772C13.148 14.5507 12.181 14.892 10.2469 15.5746L9.75 15.75"
        stroke={color}
        strokeWidth="1.6"
        stroke-linecap="round"
      />
      <path
        d="M2.25 9H9.75M2.25 9C2.25 8.47485 3.74573 7.49365 4.125 7.125M2.25 9C2.25 9.52515 3.74573 10.5064 4.125 10.875"
        stroke={color}
        strokeWidth="1.6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default LogoutDrawerIcon;
