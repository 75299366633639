import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import { Typography, theme } from "antd";
import { AppLogo } from "../../../components";
import { useNavigate } from "react-router-dom";

const { Text } = Typography;
const { useToken } = theme;

const TitleText = styled(Text)`
  font-size: 30px;
  font-weight: 700;
  font-family: "DM-Sans", sans-serif;
  margin: 0;
`;

const SubText = styled(Text)`
  font-size: 14px;
  font-weight: 400;
  font-family: "DM-Sans", sans-serif;
  margin: 0;
  width: 80%;
  color: #757f8e;
  text-align: center;
`;

const TitleContainer = styled.div`
  text-align: center;
  gap: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CardContainer = styled.div`
  background-color: #fff;
  width: 100%;
  max-width: 567px;
  border-radius: 16px;
  padding: 32px;
  gap: 24px;
  box-shadow: 0px 25px 49.7px 0px #0000000d;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 20px;
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const OTPContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0;
`;

const OTPInput = styled.input`
  flex: 1;
  max-width: 70px;
  height: 50px;
  background-color: #f5f7fa;
  border: none;
  border-radius: 10px;
  font-family: "Gilroy-Semibold", sans-serif;
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  color: ${({ isActive, theme }) =>
    isActive ? theme.token.colorPrimary : theme.token.logoBlue};
  outline: none;
  transition: border-color 0.3s ease;

  &:focus {
    border: 1px solid ${({ theme }) => theme.token.colorPrimary};
  }
`;

const StyledButton = styled.button`
  background-color: ${(props) => props.theme.token.colorPrimary};
  color: ${(props) => props.theme.token.colorWhite};
  border: none;
  font-weight: 600;
  font-size: 14px;
  font-family: "DM-Sans", sans-serif;
  border-radius: 8px;
  padding: 16px 20px;
  width: 50%;
  height: 50px;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.token.colorPrimary};
    opacity: 0.9;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: #cccccc;
  }
`;

const StyledOutlineButton = styled(StyledButton)`
  background-color: ${(props) => props.theme.token.colorWhite};
  border: 1px solid ${(props) => props.theme.token.logoBlue};
  color: ${(props) => props.theme.token.logoBlue};

  &:hover {
    background-color: ${(props) => props.theme.token.colorWhite};
    opacity: 0.9;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: #f5f5f5; /* Subtle grey for the button background */
    border: 1px solid #e0e0e0; /* Lighter grey for the border */
    color: #a0a0a0; /* Grey for the text */
  }
`;

export default function VerifyPhone({ phone, onVerify }) {
  const { token } = useToken();
  const navigate = useNavigate();
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const inputsRef = useRef([]);
  const [timer, setTimer] = useState(600); // Timer starts at 10 minutes (600 seconds)
  const [resendEnabled, setResendEnabled] = useState(false);

  useEffect(() => {
    if (inputsRef.current[0]) {
      inputsRef.current[0].focus();
    }
  }, []);

  useEffect(() => {
    if (timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(countdown);
    } else {
      setResendEnabled(true);
    }
  }, [timer]);

  const handleChange = (e, index) => {
    const value = e.target.value;
    if (/\d/.test(value) || value === "") {
      const updatedOtp = [...otp];
      updatedOtp[index] = value;
      setOtp(updatedOtp);

      if (value && index < otp.length - 1) {
        inputsRef.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "" && index > 0) {
      inputsRef.current[index - 1].focus();
    }
  };

  const handleResend = () => {
    setTimer(600); // Restart timer to 10 minutes
    setResendEnabled(false);
  };

  const handleSubmit = () => {
    const otpValue = otp.join("");
    onVerify(otpValue); // Pass OTP to parent component
  };

  const formatTime = () => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };

  return (
    <SectionContainer>
      <LogoContainer
        style={{ cursor: "pointer" }}
        onClick={() => navigate("/")}
      >
        <AppLogo height="70px" />
      </LogoContainer>
      <CardContainer>
        <TitleContainer>
          <TitleText>Please verify your phone number</TitleText>
          <SubText>
            A verification code has been sent to
            <span style={{ color: token.colorPrimary }}> +{phone}. </span>
          </SubText>
        </TitleContainer>
        <SubText>
          Please check your messages and enter the verification code below to
          verify your phone number. The code will expire in
          <span style={{ color: token.colorPrimary }}> {formatTime()}.</span>
        </SubText>
        <OTPContainer>
          {otp.map((value, index) => (
            <OTPInput
              key={index}
              value={value}
              maxLength={1}
              isActive={document.activeElement === inputsRef.current[index]}
              ref={(el) => (inputsRef.current[index] = el)}
              onChange={(e) => handleChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
            />
          ))}
        </OTPContainer>
        <div
          style={{
            width: "100%",
            gap: "18px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <StyledOutlineButton onClick={handleResend} disabled={!resendEnabled}>
            Resend Code
          </StyledOutlineButton>
          <StyledButton onClick={handleSubmit}>
            Verify my Phone Number
          </StyledButton>
        </div>
      </CardContainer>
    </SectionContainer>
  );
}
