import React, { useState } from "react";
import {
  Button,
  Layout,
  Typography,
  Space,
  Select,
  InputNumber,
  Switch,
  Row,
  Col,
  Collapse,
  theme,
} from "antd";
import { quizTypes, bookData } from "../../constants";
import { useSelectedChapters } from "../../contexts";
import { QuizInfoDisplay, SwitchCard } from "../../components";
import { ASSETS } from "../../constants";

const { useToken } = theme;
const { Content } = Layout;
const { Option } = Select;
const { Panel } = Collapse;

function QuizSettingsDisplay({
  quizType,
  onQuizTypeChange,
  difficulty,
  onDifficultyChange,
  numQuestions,
  onNumQuestionsChange,
  allQuestions,
  onAllQuestionsToggle,
  containsRedemptionQuestions,
  onRedemptionQuestionsToggle,
  containsSkippedQuestions,
  onSkippedQuestionsToggle,
  onStartQuiz,
  book,
}) {
  const { token } = useToken();
  const { selectedChapters, setSelectedChapters } = useSelectedChapters();

  const [isStarting, setIsStarting] = useState(false);

  const handleQuizTypeChange = (selectedTypes) => {
    if (selectedTypes.includes("Mixed")) {
      // If "Mixed" is selected, only allow "Mixed" to remain selected
      const mixedType = quizTypes.find((type) => type.value === "Mixed");
      onQuizTypeChange([mixedType]);
    } else {
      // Map the selected values to their full objects from quizTypes
      const selectedTypeObjects = selectedTypes.map((selectedValue) =>
        quizTypes.find((type) => type.value === selectedValue)
      );
      onQuizTypeChange(selectedTypeObjects);
    }
  };

  const handleChaptersChange = (selectedChapters) => {
    setSelectedChapters(selectedChapters);
  };

  const handleStartQuiz = async () => {
    setIsStarting(true);
    try {
      await onStartQuiz();
    } catch (error) {
      console.error(error);
    } finally {
      setIsStarting(false);
    }
  };

  return (
    <Layout
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: token.colorWhite,
        borderRadius: "16px",
        paddingTop: "16px",
        padding: "24px",
        overflowY: "auto",
      }}
    >
      <Content>
        <Space direction="vertical" style={{ width: "100%" }} size="middle">
          <QuizInfoDisplay book={book} />
          {/* Quiz Type Dropdown */}
          <Row gutter={[16, 16]}>
            <Col
              xs={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
              xl={{ span: 12 }}
            >
              <Typography.Text
                style={{
                  fontFamily: "Gilroy-Medium",
                  color: token.logoBlue,
                }}
                strong
              >
                Type:
              </Typography.Text>
              <Select
                mode="multiple"
                value={quizType}
                className="quiz-type-select"
                onChange={handleQuizTypeChange}
                size="large"
                style={{
                  width: "100%",
                  marginTop: "6px",
                  color: token.logoBlue,
                  fontFamily: "Gilroy-Medium",
                }}
                dropdownStyle={{ backgroundColor: token.colorWhite }}
              >
                {quizTypes.map((type) => (
                  <Option
                    key={type.value}
                    value={type.value}
                    style={{
                      color: token.logoBlue,
                      fontFamily: "Gilroy-Regular",
                    }}
                  >
                    {type.label}
                  </Option>
                ))}
              </Select>
            </Col>
            {/* Quiz Difficulty Dropdown */}
            <Col
              xs={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
              xl={{ span: 12 }}
            >
              <Typography.Text
                style={{
                  fontFamily: "Gilroy-Medium",
                  color: token.logoBlue,
                }}
                strong
              >
                Difficulty:
              </Typography.Text>
              <Select
                value={difficulty}
                onChange={onDifficultyChange}
                className="difficulty-select"
                size="large"
                style={{
                  width: "100%",
                  marginTop: "6px",
                  color: token.logoBlue,
                  fontFamily: "Gilroy-Medium",
                }}
                dropdownStyle={{ backgroundColor: token.colorWhite }}
              >
                <Option
                  value="Easy"
                  style={{
                    color: token.logoBlue,
                    fontFamily: "Gilroy-Regular",
                  }}
                >
                  Easy
                </Option>
                <Option
                  value="Medium"
                  style={{
                    color: token.logoBlue,
                    fontFamily: "Gilroy-Regular",
                  }}
                >
                  Medium
                </Option>
                <Option
                  value="Hard"
                  style={{
                    color: token.logoBlue,
                    fontFamily: "Gilroy-Regular",
                  }}
                >
                  Hard
                </Option>
              </Select>
            </Col>
            {/* <Col
              xs={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
              xl={{ span: 12 }}
            >
              <Typography.Text strong>Chapters:</Typography.Text>
              <Select
                mode="multiple"
                value={selectedChapters}
                onChange={handleChaptersChange}
                size="large"
                style={{ width: "100%", marginTop: "6px" }}
                dropdownStyle={{ backgroundColor: token.colorWhite }}
              >
                {bookData.book.chapters.map((chapter) => (
                  <Option key={chapter.chapterNumber} value={chapter.title}>
                    {chapter.title}
                  </Option>
                ))}
              </Select>
            </Col> */}
          </Row>

          <Row gutter={[16, 16]}>
            {/* No. of Questions Input */}
            <Col
              xs={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
              xl={{ span: 12 }}
            >
              <Typography.Text
                style={{
                  fontFamily: "Gilroy-Medium",
                  color: token.logoBlue,
                }}
                strong
              >
                No. of Questions:
              </Typography.Text>
              <InputNumber
                min={1}
                value={numQuestions}
                onChange={onNumQuestionsChange}
                className="num-questions-input-number"
                size="large"
                style={{
                  width: "100%",
                  marginTop: "6px",
                  backgroundColor: token.colorFooterCard,
                  color: token.logoBlue,
                  fontFamily: "Gilroy-Medium",
                }}
              />
            </Col>
          </Row>

          {/* Display all questions on one page */}
          <Row>
            <Col>
              <div style={{ marginTop: "8px" }}>
                <Typography.Text
                  style={{
                    fontFamily: "Gilroy-Medium",
                    color: token.logoBlue,
                  }}
                  strong
                >
                  Display all questions on one page
                </Typography.Text>
                <Switch
                  checked={allQuestions}
                  onChange={onAllQuestionsToggle}
                  style={{ marginLeft: 24 }}
                />
              </div>
            </Col>
          </Row>

          {/* Additional Settings Collapsible */}
          <Collapse
            className="additional-settings-collapse"
            style={{
              marginTop: "16px",
              marginBottom: "16px",
              border: "none",
              backgroundColor: token.colorFooterCard,
            }}
          >
            <Panel
              header="Additional Settings"
              key="1"
              className="additional-settings-text"
            >
              {/* Questions Section */}
              <Typography.Title
                level={5}
                style={{
                  marginTop: 0,
                  color: token.logoBlue,
                  fontFamily: "Gilroy-Medium",
                }}
              >
                Questions
              </Typography.Title>
              <SwitchCard
                icon={
                  <img
                    src={ASSETS.shuffleAdditionalSettings}
                    alt="Shuffle Questions"
                  />
                }
                title="Shuffle Questions"
                description="Let the system randomly select the questions for you."
                defaultChecked={true}
                onChange={(checked) =>
                  console.log("Shuffle Questions:", checked)
                }
              />
              <SwitchCard
                icon={
                  <img
                    src={ASSETS.redemptionAdditionalSettings}
                    alt="Redemption Questions"
                  />
                }
                title="Redemption Questions"
                description="Allow learners to attempt few incorrect questions."
                defaultChecked={containsRedemptionQuestions}
                onChange={(checked) => {
                  console.log("Redemption Questions:", checked);
                  onRedemptionQuestionsToggle(checked);
                }}
              />
              <SwitchCard
                icon={
                  <img
                    src={ASSETS.skipAdditionalSettings}
                    alt="Skip Questions"
                  />
                }
                title="Skip Questions & Attempt Later"
                description="Allow learners to skip questions now and revisit them later after finishing other questions."
                defaultChecked={containsSkippedQuestions}
                onChange={(checked) => {
                  console.log("Skip Questions:", checked);
                  onSkippedQuestionsToggle(checked);
                }}
              />
              <SwitchCard
                icon={
                  <img
                    src={ASSETS.adaptiveAdditionalSettings}
                    alt="Adaptive Question Bank Mode"
                  />
                }
                title="Adaptive Question Bank Mode"
                description="Generate a new set of questions every time to prevent copying and rote learning."
                defaultChecked={false}
                onChange={(checked) => console.log("Adaptive Mode:", checked)}
              />

              {/* Answers Section */}
              <Typography.Title
                level={5}
                style={{
                  marginTop: "24px",
                  color: token.logoBlue,
                  fontFamily: "Gilroy-Medium",
                }}
              >
                Answers
              </Typography.Title>
              <SwitchCard
                icon={
                  <img
                    src={ASSETS.answerWeightageAdditionalSettings}
                    alt="Custom Answer Weightage"
                  />
                }
                title="Custom Answer Weightage"
                description="Set custom marks for different types of questions."
                defaultChecked={false}
                onChange={(checked) =>
                  console.log("Custom Weightage:", checked)
                }
                isWeightage={true}
                quizTypes={quizType}
              />
              <SwitchCard
                icon={
                  <img
                    src={ASSETS.passMarksAdditionalSettings}
                    alt="Pass Mark"
                  />
                }
                title="Pass mark"
                description="This will let you set minimum points for your learner to pass the quiz."
                defaultChecked={false}
                onChange={(checked) => console.log("Pass Mark:", checked)}
                isPassMark={true}
              />
            </Panel>
          </Collapse>

          {/* Start Quiz Button */}
          <Button
            type="primary"
            size="large"
            onClick={handleStartQuiz}
            loading={isStarting}
            style={{ marginBottom: 16, display: "block", marginLeft: "auto" }}
          >
            <span
              style={{
                fontFamily: "Gilroy-Medium",
                marginLeft: isStarting ? "12px" : "0px",
              }}
            >
              Start Quiz
            </span>
          </Button>
        </Space>
      </Content>
    </Layout>
  );
}

export default QuizSettingsDisplay;
