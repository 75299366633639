import React, { useState, useEffect } from "react";
import { Card, Typography, Progress, theme, Flex } from "antd";
import { AppDropdown, StatTextRow } from "../../components";
import { getCurrentProgress } from "../../services";
import { getAuth } from "../../utils";

const { Text } = Typography;
const { useToken } = theme;

const ProgressCard = () => {
  const { token } = useToken();
  const [progressData, setProgressData] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState("all");

  // Fetch progress data
  useEffect(() => {
    const fetchProgress = async () => {
      try {
        const { authToken } = getAuth();
        const data = await getCurrentProgress(authToken);
        setProgressData(data);
      } catch (error) {
        console.error("Error fetching progress:", error);
      }
    };
    fetchProgress();
  }, []);

  // Format subjects for dropdown
  const subjectOptions =
    progressData?.subjects.map((subject) => ({
      value: subject.name.toLowerCase(),
      label: subject.name,
    })) || [];
  // Add "All Subjects" option
  subjectOptions.unshift({ value: "all", label: "Subjects" });

  // Get current progress value
  const getCurrentProgressValue = () => {
    if (!progressData) return 0;
    if (selectedSubject === "all") return progressData.overall_progress;
    const subject = progressData.subjects.find(
      (s) => s.name.toLowerCase() === selectedSubject
    );
    return subject?.progress || 0;
  };

  return (
    <Card
      style={{
        backgroundColor: token.colorWhite,
        borderRadius: "20px",
        padding: "16px",
        marginBottom: "16px",
        borderColor: token.colorBgLayout,
      }}
      bodyStyle={{ padding: "0px" }}
    >
      {/* Header Row */}
      <Flex
        justify="space-between"
        align="center"
        style={{ marginBottom: "16px" }}
      >
        <Text style={{ fontSize: "16px", fontFamily: "Inter-Tight", fontWeight: 600 }}>
          Progress
        </Text>
        <AppDropdown
          defaultValue="all"
          options={subjectOptions}
          onChange={(value) => setSelectedSubject(value)}
        />
      </Flex>

      {/* Progress Circle */}
      <Flex justify="center" style={{ marginBottom: "16px" }}>
        <Progress
          type="circle"
          size={144}
          strokeWidth={10}
          percent={getCurrentProgressValue()}
          strokeColor={token.colorPrimary}
          trailColor={token.colorPrimaryBg}
          style={{
            fontFamily: "Inter-Tight",
            fontWeight: 600,
            fontSize: "24px",
          }}
        />
      </Flex>

      {/* Bottom Stats Row */}
      <StatTextRow
        value={`${getCurrentProgressValue()}`}
        description="Increase since last week"
        isIncrease={true}
      />
    </Card>
  );
};

export default ProgressCard;
