import React from "react";
import { Flex, Typography, theme } from "antd";
import { Message, RecommendationsCard } from "../../components";
import { InfoCircleOutlined } from "@ant-design/icons";

const { Text } = Typography;
const { useToken } = theme;

function MessageList({
  messages,
  showRecommendations,
  onCloseRecommendations,
  onSendRecommendation,
  onReferenceClicked,
  isLoading,
}) {
  const { token } = useToken();

  return (
    <div
      style={{
        width: "100%",
        padding: "12px",
        overflowY: "auto",
      }}
    >
      <Flex
        justify="center"
        align="center"
        style={{
          width: "100%",
          backgroundColor: token.colorFooterCard,
          padding: "8px 16px",
          borderRadius: "12px",
          marginBottom: "42px",
        }}
      >
        <InfoCircleOutlined
          style={{ color: token.colorBlack, marginRight: "16px" }}
        />
        <Text style={{ color: "#102A43" }}>
          This chat is Al-powered. Chats are recorded for quality. Learn more
          from our Privacy Policy.
        </Text>
      </Flex>
      {messages.map((message, index) => (
        <Message
          key={index}
          content={message.content}
          type={message.type}
          index={index}
          references={message.references}
          onReferenceClicked={onReferenceClicked}
          isLoading={message.isLoading}
        />
      ))}
      {showRecommendations && (
        <RecommendationsCard
          onClose={onCloseRecommendations}
          onSend={onSendRecommendation}
        />
      )}
    </div>
  );
}

export default MessageList;
