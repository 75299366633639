const SettingsDrawerIcon = ({ color }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.625 9C11.625 10.4497 10.4497 11.625 9 11.625C7.55025 11.625 6.375 10.4497 6.375 9C6.375 7.55025 7.55025 6.375 9 6.375C10.4497 6.375 11.625 7.55025 11.625 9Z" stroke={color}/>
<path d="M15.7582 10.5731C16.1497 10.4676 16.3454 10.4148 16.4227 10.3138C16.5 10.2129 16.5 10.0506 16.5 9.7259V8.27562C16.5 7.95095 16.5 7.78857 16.4227 7.6877C16.3454 7.58675 16.1497 7.53395 15.7582 7.42841C14.2954 7.03391 13.3799 5.5046 13.7575 4.05137C13.8613 3.65171 13.9132 3.45189 13.8636 3.33469C13.814 3.21749 13.6718 3.13673 13.3873 2.97519L12.0938 2.24077C11.8146 2.08226 11.675 2.00301 11.5498 2.01989C11.4245 2.03676 11.2832 2.17777 11.0004 2.45977C9.906 3.55158 8.0952 3.55154 7.00076 2.4597C6.71807 2.17769 6.57673 2.03669 6.45145 2.01981C6.32617 2.00294 6.18658 2.08219 5.9074 2.24069L4.61388 2.97512C4.3294 3.13664 4.18715 3.2174 4.13758 3.33458C4.08801 3.45177 4.1399 3.65162 4.24369 4.05132C4.62103 5.5046 3.70479 7.03394 2.24177 7.42842C1.85034 7.53395 1.65463 7.58675 1.57731 7.68762C1.5 7.78857 1.5 7.95095 1.5 8.27562V9.7259C1.5 10.0506 1.5 10.2129 1.57731 10.3138C1.65461 10.4148 1.85033 10.4676 2.24177 10.5731C3.70455 10.9676 4.62006 12.4969 4.24254 13.9501C4.13872 14.3498 4.0868 14.5496 4.13637 14.6668C4.18595 14.784 4.32819 14.8648 4.61269 15.0263L5.90621 15.7608C6.18541 15.9192 6.325 15.9985 6.4503 15.9816C6.57559 15.9648 6.71691 15.8237 6.99953 15.5417C8.09453 14.449 9.9066 14.4489 11.0017 15.5416C11.2843 15.8237 11.4256 15.9647 11.5509 15.9816C11.6762 15.9984 11.8158 15.9192 12.095 15.7607L13.3885 15.0262C13.673 14.8647 13.8153 14.784 13.8648 14.6667C13.9144 14.5495 13.8625 14.3497 13.7586 13.95C13.3809 12.4969 14.2957 10.9677 15.7582 10.5731Z" stroke={color} stroke-linecap="round"/>
</svg>

  );
};

export default SettingsDrawerIcon;
