import React from "react";
import { Layout, Typography, Space, Button, Flex, theme } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";
import { useNavigate, useLocation, Navigate } from "react-router-dom";

const { Content } = Layout;
const { Title, Text, Paragraph } = Typography;
const { useToken } = theme;

function QuizResultDisplay() {
  const { token } = useToken();
  const navigate = useNavigate();
  const location = useLocation();

  if (
    !location.state ||
    !location.state.quizData ||
    !location.state.evaluationResult
  ) {
    return <Navigate to="/test" replace />;
  }

  const { quizData, evaluationResult, timeElapsed, skippedQuestions } =
    location.state;
  const { attempt } = evaluationResult;

  // Function to get response for a question
  const getQuestionResponse = (questionId) => {
    return attempt.responses.find(
      (response) => response.question === questionId
    );
  };

  // Function to determine dynamic color based on score
  const getScoreColor = (passed) => {
    if (passed) return token.colorSuccess;
    return token.colorError;
  };

  return (
    <Layout
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: token.colorWhite,
        borderRadius: "16px",
        padding: "24px",
        overflowY: "auto",
      }}
    >
      <Content style={{ width: "90%", margin: "0 auto" }}>
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
          {/* Result Header */}
          <Flex vertical align="center">
            <CheckCircleFilled
              style={{
                fontSize: "42px",
                color: getScoreColor(attempt.passed),
              }}
            />
            <Title
              level={3}
              style={{ color: token.logoBlue, fontFamily: "Gilroy-Medium" }}
            >
              Quiz Completed!
            </Title>

            <Space direction="vertical" align="center">
              {/* Score Circle */}
              <div
                style={{
                  width: "120px",
                  height: "120px",
                  borderRadius: "50%",
                  border: `4px solid ${getScoreColor(attempt.passed)}`,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    margin: 0,
                    color: getScoreColor(attempt.passed),
                    fontSize: "14px",
                    fontFamily: "Gilroy-Medium",
                  }}
                >
                  Score
                </Text>
                <Text
                  style={{
                    margin: 0,
                    color: getScoreColor(attempt.passed),
                    fontSize: "32px",
                    fontFamily: "Gilroy-Bold",
                  }}
                >
                  {attempt.score}%
                </Text>
                <Text
                  style={{
                    margin: 0,
                    color: getScoreColor(attempt.passed),
                    fontSize: "16px",
                    fontFamily: "Gilroy-Medium",
                  }}
                >
                  {attempt.passed ? "Passed" : "Failed"}
                </Text>
              </div>
              <Title
                level={5}
                style={{
                  color: token.logoBlue,
                  fontFamily: "Gilroy-Regular",
                  marginTop: "8px",
                  marginBottom: 0,
                }}
              >
                Questions Skipped: {skippedQuestions.length}
              </Title>
              <Text
                strong
                style={{
                  color: token.logoBlue,
                  fontFamily: "Gilroy-Regular",
                  fontSize: "16px",
                }}
              >
                Time taken: {timeElapsed}
              </Text>
            </Space>
          </Flex>

          {/* Questions Review */}
          <Space direction="vertical" style={{ width: "100%" }}>
            <Title
              level={4}
              style={{ color: token.logoBlue, fontFamily: "Gilroy-Medium" }}
            >
              Questions Review
            </Title>
            {quizData.quiz.questions.map((question, idx) => {
              const response = getQuestionResponse(question.id);

              return (
                <Flex
                  key={idx}
                  vertical
                  style={{
                    marginBottom: 8,
                    border: token.border,
                    borderRadius: "16px",
                    padding: "16px 24px 16px 24px",
                    margin: 0,
                  }}
                >
                  <Flex
                    align="center"
                    justify="space-between"
                    style={{ marginBottom: 12 }}
                  >
                    <Text
                      strong
                      style={{
                        marginRight: 8,
                        color: token.logoBlue,
                        fontFamily: "Gilroy-Regular",
                      }}
                    >
                      {idx + 1}. {question.question_text}
                    </Text>
                    {response && (
                      <Text
                        type={response.is_correct ? "success" : "danger"}
                        style={{
                          fontFamily: "Gilroy-Medium",
                          fontSize: "14px",
                        }}
                      >
                        ({response.score} points)
                      </Text>
                    )}
                  </Flex>
                  {response && response.feedback && (
                    <Paragraph
                      type="secondary"
                      style={{
                        marginBottom: 12,
                        color: token.logoBlue,
                        fontFamily: "Gilroy-Regular",
                        fontSize: "14px",
                      }}
                    >
                      Feedback: {response.feedback}
                    </Paragraph>
                  )}
                  {(question.question_type === "subjective" ||
                    question.question_type === "fill_blanks") &&
                    response && (
                      <div>
                        <Text
                          strong
                          style={{
                            color: token.logoBlue,
                            fontFamily: "Gilroy-Medium",
                            fontSize: "14px",
                          }}
                        >
                          Your Answer:
                        </Text>
                        <Paragraph
                          style={{
                            color: token.logoBlue,
                            fontFamily: "Gilroy-Regular",
                            fontSize: "14px",
                          }}
                        >
                          {response.user_answer}
                        </Paragraph>
                        {response.points_covered && (
                          <>
                            <Text
                              strong
                              style={{
                                color: token.logoBlue,
                                fontFamily: "Gilroy-Medium",
                                fontSize: "14px",
                              }}
                            >
                              Points Covered:
                            </Text>
                            <ul>
                              {response.points_covered.map((point, i) => (
                                <li
                                  key={i}
                                  style={{
                                    color: token.logoBlue,
                                    fontFamily: "Gilroy-Regular",
                                    fontSize: "14px",
                                  }}
                                >
                                  {point}
                                </li>
                              ))}
                            </ul>
                          </>
                        )}
                      </div>
                    )}
                  {["mcq", "true_false"].includes(question.question_type) && (
                    <div>
                      <div>
                        {response?.is_correct ? (
                          <div>
                            <Text
                              style={{
                                color: token.logoBlue,
                                fontFamily: "Gilroy-Regular",
                                fontSize: "14px",
                              }}
                            >
                              Correct Answer:{" "}
                            </Text>
                            <Text
                              style={{
                                color: token.logoBlue,
                                fontFamily: "Gilroy-Medium",
                                fontSize: "14px",
                              }}
                            >
                              {
                                question.options[
                                  response?.correct_answer?.charCodeAt(0) - 97
                                ]
                              }
                            </Text>
                          </div>
                        ) : (
                          <>
                            <div>
                              <Text
                                style={{
                                  color: token.logoBlue,
                                  fontFamily: "Gilroy-Regular",
                                  fontSize: "14px",
                                }}
                              >
                                Your Answer:{" "}
                              </Text>
                              <Text
                                style={{
                                  color: token.logoBlue,
                                  fontFamily: "Gilroy-Medium",
                                  fontSize: "14px",
                                }}
                              >
                                {
                                  question.options[
                                    response?.user_answer?.charCodeAt(0) - 97
                                  ]
                                }
                              </Text>
                            </div>
                            <div style={{ marginTop: "12px" }}>
                              <Text
                                style={{
                                  color: token.logoBlue,
                                  fontFamily: "Gilroy-Regular",
                                  fontSize: "14px",
                                }}
                              >
                                Correct Answer:{" "}
                              </Text>
                              <Text
                                style={{
                                  color: token.logoBlue,
                                  fontFamily: "Gilroy-Medium",
                                  fontSize: "14px",
                                }}
                              >
                                {
                                  question.options[
                                    response?.correct_answer?.charCodeAt(0) - 97
                                  ]
                                }
                              </Text>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </Flex>
              );
            })}
          </Space>

          {/* Action Buttons */}
          <Flex gap={16} justify="center">
            <Button
              type="primary"
              size="large"
              style={{ marginBottom: 16, display: "block", marginLeft: "auto" }}
              onClick={() => navigate("/test")}
            >
              <span
                style={{
                  fontFamily: "Gilroy-Medium",
                }}
              >
                Take Another Quiz
              </span>
            </Button>
            <Button
              size="large"
              style={{
                marginBottom: 16,
                border: `1px solid ${token.logoBlue}`,
              }}
              onClick={() => navigate("/")}
            >
              <span
                style={{
                  color: token.logoBlue,
                  fontFamily: "Gilroy-Medium",
                }}
              >
                Go To Dashboard
              </span>
            </Button>
          </Flex>
        </Space>
      </Content>
    </Layout>
  );
}

export default QuizResultDisplay;
