import React, { useEffect, useRef, useState } from "react";
import { Layout, Typography, Button, theme } from "antd";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Player } from '@lottiefiles/react-lottie-player';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import { ASSETS } from "../constants";

const { Content } = Layout;
const { Text, Title } = Typography;
const { useToken } = theme;

const Container = styled.div`
  padding: 40px 120px;
  position: relative;
  overflow: hidden;

  @media (max-width: 768px) {
    padding: 20px;
    margin-top: 20px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 24px;
  position: relative;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const LeftColumn = styled.div`
  flex: 0 0 auto;
  max-width: 400px;
  margin-top: 20px;
  position: relative;

  @media (max-width: 768px) {
    margin-top: 0;
    text-align: center;
  }

  .ai-powered-wrapper {
    display: flex;
    justify-content: flex-start; /* Align to the left */

    @media (max-width: 768px) {
      justify-content: center; /* Align to the center */
  }

`;

const ShineLines = styled.img`
  position: absolute;
  left: 5%;
  width: 50px;
  height: 50px;
  z-index: 1;

  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
    bottom: 5%;
  }
`;

const CurvedLine = styled.img`
  position: absolute;
  bottom: 10%;
  right: 5%;
  width: 50px;
  height: 50px;
  z-index: 1;

  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
    bottom: 5%;
  }
`;

const BestText = styled.span`
  position: relative;
  color: ${(props) => props.theme.token.colorPrimary};

  &::after {
    content: "";
    position: absolute;
    bottom: -8px;
    left: -5%;
    width: 110%;
    height: 15px;
    background-image: url("images/best-underline.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
`;

const StyledButton = styled(Button)`
  width: auto;
  height: 43px;
  padding: 16px 20px;
  font-size: 16px;
  font-weight: 400;
  font-family: "Gilroy-SemiBold";
  border-radius: 8px;
  border: none;

  @media (max-width: 768px) {
    margin: 0 auto;
  }
`;

const RightColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  max-height: 800px;
  right: -10%;

  @media (max-width: 768px) {
    right: 0;
    margin-top: 30%;
  }
`;

const StudentAnimation = styled.div`
  width: 70%;
  max-height: 80%;
  z-index: 2;

  .background-image {
    position: relative;
    width: 100%;
    height: auto;
    max-height: 80%; /* Ensure the image does not exceed 80% of the container height */
    object-fit: contain;
    z-index: 1;
  }

  .landing-elements-animation {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: auto;
    z-index: 3;
  }

  .aplus-container {
    position: absolute;
    top: 0%;
    right: 15%;
    width: 25%;
    height: auto;
    z-index: 4;

    .aplus-lottie {
      width: 100%;
      height: auto;
    }

    .aplus-svg {
      width: 25%;
      height: auto;
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .ai-powered-lottie {
    position: absolute;
    bottom: 7%;
    right: 15%;
    width: 25%;
    height: auto;
    z-index: 3;
  }

  .plane-lottie {
    position: absolute;
    left: -20%;
    top: 20%;
    transform: translateY(-50%);
    width: 40%;
    height: auto;
    z-index: 3;

    @media (max-width: 768px) {
      left: 50%;
      top: -35%; /* Position above the student graphic */
      transform: translate(-50%, 0);
      width: 50%;
    }
  }
`;

const LandingContent = () => {
  const playerRef = useRef(null);
  const animationContainerRef = useRef(null);
  const [dotLottie, setDotLottie] = useState(null);
  const [elementsDotLottie, setElementsDotLottie] = useState(null);
  const aplusContainerRef = useRef(null);
  const { token } = useToken();
  const navigate = useNavigate();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          // Play the animation when the container is visible
          elementsDotLottie?.play();
        }
      },
      { threshold: 0.5 } // Adjust threshold as needed
    );

    if (animationContainerRef.current) {
      observer.observe(animationContainerRef.current);
    }

    return () => {
      if (animationContainerRef.current) {
        observer.unobserve(animationContainerRef.current);
      }
    };
  }, [elementsDotLottie]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          // Play the animation when the container is visible
          dotLottie?.play();
        }
      },
      { threshold: 0.5 } // Adjust threshold as needed
    );

    if (aplusContainerRef.current) {
      observer.observe(aplusContainerRef.current);
    }

    return () => {
      if (aplusContainerRef.current) {
        observer.unobserve(aplusContainerRef.current);
      }
    };
  }, [dotLottie]);

  return (
    <Layout style={{ background: "transparent" }}>
      <Content>
        <Container>
          <ContentWrapper>
            <LeftColumn>
              <div className="ai-powered-wrapper">
              <Player
                src={ASSETS.aiPoweredAnimation}
                autoplay
                loop
                style={{ width: '198px', height: '49.5px', boxShadow: '0px 19.57px 63.2px 0px #0000000D', borderRadius: '8px' }}
              />
              </div>
              <Title
                level={1}
                style={{
                  fontSize: "3.5rem",
                  fontWeight: 400,
                  fontFamily: "Gilroy-Bold",
                  lineHeight: "64px",
                  color: token.colorBlack,
                  marginBottom: "24px", // Increased spacing
                  marginTop: "24px",
                }}
              >
                Performance at its <BestText theme={{ token }}>Best!</BestText>
              </Title>
              <Text
                style={{
                  fontSize: "18px",
                  lineHeight: "24px",
                  fontWeight: 400,
                  fontFamily: "Gilroy-Regular",
                  color: token.colorDarkGrey,
                  display: "block",
                  marginBottom: "16px", // Increased spacing
                }}
              >
                Smart AI tools to help you prepare, practice, and perform your
                best in exams.
              </Text>
              <Text
                style={{
                  fontSize: "18px",
                  fontWeight: 400,
                  color: token.colorDarkGrey,
                  display: "block",
                  marginBottom: "32px", // Increased spacing
                }}
              >
                GradeMakers make preparation and practice experience unbelievably easy & seriously fun using the power of Artificial Intelligence.
              </Text>
              <StyledButton 
                type="primary"
                onClick={() => navigate("/signup")}
              >
                Signup for Free
              </StyledButton>
            </LeftColumn>

            <RightColumn>
  <StudentAnimation>
    <img
      src={ASSETS.studentGraphic}
      alt="Student Graphic"
      className="background-image"
    />
    <div ref={animationContainerRef}>
    <DotLottieReact
      src={ASSETS.landingElementsAnimation}
      autoplay
      loop={false}
      keepLastFrame={true}
      className="landing-elements-animation"
      dotLottieRefCallback={setElementsDotLottie}
    />
    </div>

    <div ref={aplusContainerRef} className="aplus-container">
      <DotLottieReact
        src={ASSETS.aplusAnimation}
        autoplay
        loop={false}
        className="aplus-lottie"
        keepLastFrame={true}
        dotLottieRefCallback={setDotLottie}
      />
      <img
        src={ASSETS.aplusText}
        alt="A+ Text"
        className="aplus-svg"
      />
    </div>

    <Player
      src={ASSETS.aiPoweredAnimation}
      autoplay
      loop
      className="ai-powered-lottie"
    />

    <Player
      src={ASSETS.paperPlaneAnimation}
      autoplay
      loop
      className="plane-lottie"
    />
  </StudentAnimation>
</RightColumn>;
          </ContentWrapper>

          <ShineLines
            src="images/shine-three-lines.svg"
            alt="Decorative lines"
          />
          <CurvedLine
            src="images/curved-line-border.svg"
            alt="Decorative curve"
          />
        </Container>
      </Content>
    </Layout>
  );
};

export default LandingContent;
