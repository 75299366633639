const quizTypes = [
  { label: "MCQs", value: "MCQs", backend: "mcq" },
  { label: "True or False", value: "True or False", backend: "true_false" },
  {
    label: "Fill in the Blanks",
    value: "Fill in the Blanks",
    backend: "fill_blanks",
  },
  { label: "Subjective", value: "Subjective", backend: "subjective" },
  { label: "Mixed", value: "Mixed", backend: "combined" },
];

export default quizTypes;
