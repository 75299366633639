import React from "react";
import { Button, Tooltip } from "antd";

function QuizSettingsIcon({ icon, tooltipTitle, onClick, border }) {
  return (
    <Tooltip title={tooltipTitle}>
      <Button
        variant="outlined"
        size="large"
        style={{
          border,
          margin: "6px 10px",
          padding: "16px 8px",
        }}
        onClick={onClick}
      >
        {icon}
      </Button>
    </Tooltip>
  );
}

export default QuizSettingsIcon;
