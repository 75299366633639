import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Input,
  Button,
  Typography,
  Checkbox,
  message,
  Layout,
  Row,
  Col,
  theme,
} from "antd";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { loginUser } from "../../../services";
import { setAuth, getAuth } from "../../../utils";
import styled from "styled-components";
import { ASSETS } from "../../../constants";
import { AppLogo } from "../../../components";
import { WelcomeScreen } from "../../../pages";
import lottie from "lottie-web";

const { Content } = Layout;
const { useToken } = theme;

const CustomCheckbox = styled(Checkbox)`

  font-size: 14px;
  font-weight: 400;
  font-family: "Gilroy-Medium";

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: white !important;
    border-color: #757f8e !important;
  }

  .ant-checkbox-checked:hover .ant-checkbox-inner {
    background-color: white !important;
    border-color: #757f8e !important;
  }

  .ant-checkbox-inner {
    border-color: #d9d9d9 !important; /* Keep the default border color when unchecked */
  }

  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: #757f8e !important; /* Keep the default border color on hover when unchecked */
  }

  /* Change tick color */
  .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: #757f8e !important; /* Set tick color */
  }

  /* Change text color */
  .ant-checkbox + span {
    color: #757f8e !important; /* Change the text color */
  }
`;

const CustomLayout = styled(Layout)`
  height: calc(100vh - 72px); /* Full height minus top and bottom margins */
  margin: 36px 0px 36px 36px; /* Margin around the layout */
  background-color: #ffffff;
  overflow: hidden; /* Prevent page scroll */

  /* Responsive styles */
  @media (max-width: 768px) {
    margin: 0px; /* Remove margin on small screens */
  }
`;

export default function Signin() {
  const animationContainer = useRef(null);
  const animationInstance = useRef(null);
  const { token } = useToken();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showWelcome, setShowWelcome] = useState(false);

  const handleComplete = () => {
    const { userData } = getAuth();
    // Navigate based on onboarding status
    if (userData.is_onboarded) {
      navigate("/home");
    } else {
      navigate("/onboarding");
    }
  };

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const credentials = { email: values.email, password: values.password };
      const response = await loginUser(credentials);

      if (response.token) {
        setAuth(response.token, response.user);
        message.success("Login successful!");
        setShowWelcome(true); // Always show welcome screen
      } else {
        throw new Error(response.message || "Invalid credentials");
      }
    } catch (error) {
      message.error("Login failed: " + (error.message || "Unknown error"));
    } finally {
      setLoading(false);
    }
  };

  const validateForm = (values) => {
    if (!values.email || !values.password) {
      message.error("Please fill in all fields!");
      return false;
    }
    return true;
  };

  const leftSectionRef = useRef(null);
  const [containerHeight, setContainerHeight] = useState(0);

  // Calculate container height on mount and resize
  useEffect(() => {
    const updateHeight = () => {
      if (leftSectionRef.current) {
        setContainerHeight(leftSectionRef.current.offsetHeight);
      }
    };
    updateHeight();

    window.addEventListener("resize", updateHeight);
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  useEffect(() => {
    // Load the animation
    animationInstance.current = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg", // Choose 'svg', 'canvas', or 'html'
      loop: false, // Play the full animation once
      autoplay: true, // Start playing immediately
      path: ASSETS.loginPageAnimation, // Animation JSON file path
    });

    // Add event listener for when the animation completes
    animationInstance.current.addEventListener("complete", () => {
      const totalFrames = animationInstance.current.getDuration(true); // Total number of frames
      const startFrame = totalFrames - 133; // Start frame (last 30 frames)
      const endFrame = totalFrames; // End frame (last frame)

      // Play and loop the last few frames
      animationInstance.current.loop = true; // Enable looping for the segment
      animationInstance.current.playSegments([startFrame, endFrame], true);
    });

    return () => {
      animationInstance.current.destroy(); // Cleanup on unmount
    };
  }, []);

  if (showWelcome) {
    return <WelcomeScreen onComplete={handleComplete} />;
  }

  return (
    <CustomLayout>
      <Row gutter={0} style={{ height: "100%" }}>
        {/* Left Section */}
        <Col xs={0} sm={0} md={13} lg={13} xl={13}>
          <div
            style={{
              backgroundColor: token.colorPrimary,
              borderRadius: "36px",
              position: "sticky",
              top: "36px",
              height: "calc(100vh - 72px)",
              overflow: "hidden",
            }}
          >
            <img
              src={ASSETS.authScreenBackground}
              alt="Auth Screen Background"
              style={{
                position: "absolute",
                bottom: 0,
                right: 0,
                height: "auto",
                width: "100%",
                opacity: 0.1,
              }}
            />
            <div
              ref={animationContainer}
              style={{
                position: "absolute",
                bottom: 0,
                right: 0,
                height: "100%",
                width: "100%",
              }}
            />
          </div>
        </Col>

        {/* Right Section */}
        <Col xs={24} sm={24} md={11} lg={11} xl={11}>
          <Content
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start", // Align content to the top
              alignItems: "center",
              padding: "24px",
              height: "calc(100vh - 72px)", // Adjusted for the page margin
              overflowY: "auto", // Make scrollable if content exceeds height
            }}
          >
            {/* Logo Section */}
            <div
              style={{
                textAlign: "center",
                marginBottom: "30px",
                cursor: "pointer",
              }}
              onClick={() => navigate("/")}
            >
              <AppLogo height="70px" />
            </div>

            {/* Card Container */}
            <div
              style={{
                backgroundColor: "#fff",
                padding: "20px",
                borderRadius: "20px",
                boxShadow: "0px 0px 100px 0px #0000000F",
                maxWidth: "400px",
                width: "100%",
              }}
            >
              {/* Middle Section */}
              <div style={{ textAlign: "center", marginBottom: "30px" }}>
              <div style={{ textAlign: "center", gap: "4px", marginBottom: "20px" }}>
                <div
                  style={{
                    fontSize: '30px',
                    fontWeight: "400",
                    fontFamily: "Gilroy-Bold",
                    lineHeight: '37.14px',
                  }}
                >
                  Welcome Back
                </div>
                <div
                  style={{
                    fontSize: '14px',
                    fontWeight: "400",
                    fontFamily: "Gilroy-Medium",
                    color: "#757F8E",
                    lineHeight: '16.98px',
                  }}
                >
                  Enter your email and password to sign in!
                </div>
                </div>

                <Form
                  form={form}
                  name="signin"
                  onFinish={onFinish}
                  layout="vertical"
                  style={{ maxWidth: 350, margin: "0 auto" }}
                  colon={false} // Removes asterisks
                  onFinishFailed={() => validateForm(form.getFieldsValue())} // Handle validation failure
                >
                  {/* Email Field */}
                  <Form.Item
                    label={
                      <span style={{ fontSize: 14, fontWeight: 400 }}>
                        Email
                      </span>
                    }
                    style={{ marginBottom: 12 }}
                    name="email"
                  >
                    <Input
                      placeholder="eg: divya.agarwal@gmail.com"
                      style={{
                        backgroundColor: "#F5F7FA",
                        borderRadius: 10,
                        height: 45,
                        fontSize: 14,
                        border: "none",
                        fontWeight: 400,
                        fontFamily: "Gilroy-Regular",
                      }}
                    />
                  </Form.Item>

                  {/* Password Field */}
                  <Form.Item
                    label={
                      <span style={{ fontSize: 14, fontWeight: 400 }}>
                        Password
                      </span>
                    }
                    style={{ marginBottom: 8 }}
                    name="password"
                    rules={[{ message: "Please input your password!" }]}
                  >
                    <Input.Password
                      placeholder="Type your Password here"
                      style={{
                        backgroundColor: "#F5F7FA",
                        borderRadius: 10,
                        height: 45,
                        fontSize: 14,
                        border: "none",
                        fontWeight: 400,
                        fontFamily: "Gilroy-Regular",
                      }}
                      iconRender={(visible) =>
                        visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                      }
                    />
                  </Form.Item>

                  {/* Remember Me and Forgot Password */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 20,
                    }}
                  >
                    <CustomCheckbox>Keep me logged in</CustomCheckbox>
                    <Link
                      to="/forgot-password"
                      style={{
                        fontSize: 14,
                        color: "#000", // Black color
                        fontWeight: "400",
                        textDecoration: "underline",
                        fontFamily: "Gilroy-SemiBold",
                      }}
                    >
                      Forgot Password?
                    </Link>
                  </div>

                  {/* Log-In Button */}
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{
                      width: "100%",
                      backgroundColor: "#FF6C42",
                      borderColor: "#FF6C42",
                      height: 52,
                      borderRadius: 10,
                      fontSize: 16,
                      fontWeight: 400,
                      fontFamily: "Gilroy-SemiBold",
                      marginBottom: "20px",
                    }}
                    loading={loading}
                  >
                    Log In
                  </Button>

                  <Button
                                        style={{
                                          width: "100%",
                                          backgroundColor: 'transparent',
                                          borderColor: token.homeButtonBorderColor,
                                          color: token.logoBlue,
                                          height: 52,
                                          borderRadius: 10,
                                          fontSize: 16,
                                          fontFamily: "Gilroy-SemiBold",
                                          fontWeight: 400,
                                          padding: "11px 23px",
                  
                                        }}
                                        loading={loading}
                                        onClick={() => navigate("/")}
                                      >
                                        Back to Home
                                      </Button>

                  {/* <div
                    style={{
                      textAlign: "center",
                      marginTop: "20px",
                      marginBottom: "10px",
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#6B7B82",
                      fontFamily: "Gilroy-Regular",
                    }}
                  >
                    Or Continue With
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "10px",
                    }}
                  >
                    <Button
                      style={{
                        backgroundColor: "#fff",
                        color: "#000",
                        border: "1px solid #ccc",
                        height: 52,
                        borderRadius: 10,
                        fontSize: 12,
                        fontWeight: 400,
                        flex: 1,
                      }}
                    >
                      <img
                        src={ASSETS.googleLogo}
                        alt="Google"
                        style={{ marginRight: 8, height: 20 }}
                      />
                    </Button>
                    <Button
                      style={{
                        backgroundColor: "#fff",
                        color: "#000",
                        border: "1px solid #ccc",
                        height: 52,
                        borderRadius: 10,
                        fontSize: 12,
                        fontWeight: 400,
                        flex: 1,
                      }}
                    >
                      <img
                        src={ASSETS.facebookLogo}
                        alt="Facebook"
                        style={{ marginRight: 8, height: 20 }}
                      />
                    </Button>
                  </div> */}
                </Form>
              </div>

              {/* Sign-up Link */}
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <Typography.Text
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "#757F8E",
                  fontFamily: "Gilroy-Regular",
                }}>
                  Don't have an account yet?{" "}
                  <Link to="/signup">
                    <Typography.Text
                      strong
                      style={{ color: token.colorPrimary,
                        fontFamily: "Gilroy-Regular",
                        fontWeight: 400,
                       }}
                    >
                      Sign Up
                    </Typography.Text>
                  </Link>
                </Typography.Text>
              </div>
            </div>
          </Content>
        </Col>
      </Row>
    </CustomLayout>
  );
}
